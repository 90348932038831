import React from "react"
import { Link } from "gatsby"

import { withAnnouncement } from "./withAnnouncement"
import { Outer, Inner, Item, Content } from "./AnnouncementStyles"

export const Announcement = withAnnouncement(({ items, active, className, show }) => (
  <Outer active={show ? "true" : "false"} className={className}>
    <Inner>
      {items?.map(({ link, title }, index) => (
        <Item key={title} active={active === index ? "true" : undefined}>
          {link?.link ? (
            <Link
              as={link?.external ? `a` : null}
              href={link?.external ? link?.url : null}
              target={link?.external ? "_blank" : null}
              title={title}
              to={!link?.external ? link?.url || "" : null}
            >
              {title}
            </Link>
          ) : (
            <Content>{title}</Content>
          )}
        </Item>
      ))}
    </Inner>
  </Outer>
))
