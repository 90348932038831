import tw, { styled } from "twin.macro"

export const Wrapper = styled.div`
  ${tw`max-w-24-5 md:max-w-40 mx-auto flex flex-col justify-center py-5`}
  div {
    ${tw`flex justify-center`}
  }
  a {
    ${tw`inline-block px-2-8`}
  }
`

export const Title = tw.p`
  text-25 font-medium mb-1-9 text-center
`

export const Message = tw.p`
  text-center text-20 leading-1.25 mb-3-2
`

type MessageProps = {
  layout?: "drawer"
}
