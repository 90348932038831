import tw, { styled } from "twin.macro"

import { StyledContainer } from "../../Styled/Container"

export const Bg = tw.div`
  bg-beige-medium
`

export const Wrapper = styled(StyledContainer)`
  ${tw`px-0 md:px-2-4 lg:px-18 flex flex-row justify-between pt-0-6 pb-1-2 md:pt-3-2 md:pb-2-4`}
`

export const Point = styled.div`
  ${tw`text-center mx-0-8 md:mx-0 flex flex-col md:flex-row w-full items-center justify-start md:text-left md:max-w-27 md:justify-center md:px-4 py-1-2`}
  ${({ shorter }) => (shorter === "true" ? tw`md:max-w-8` : null)}
  ${({ hiddenOnMobile }) => (hiddenOnMobile ? tw`hidden md:flex` : "flex")}
`

export const Title = styled.p<TitleProps>`
  ${tw`text-12 md:text-14 font-medium uppercase md:mt-0-4 md:mt-0 flex items-center leading-1.2 tracking-5`}
  ${({ shorter }) => (shorter === "true" ? tw`md:max-w-8` : null)}
`

export const IconWrapper = tw.div`
  md:mr-2 w-4 h-4 flex items-center justify-center transform scale-60 md:scale-100
`
export const SpecialLink = tw.a`
  cursor-pointer
`

type TitleProps = {
  shorter: "true" | "false"
}
