import React from "react"

import { withCheckout } from "./withCheckout"
import { Outer, Inner, FreeShippingCallToAction } from "./CheckoutDrawerStyles"
import { StyledButton } from "../../../Styled/Button"

export const CheckoutDrawer = withCheckout(
  ({
    loading,
    handleCheckout,
    freeShippingAmount,
    freeShippingCallToAction,
    additionalCheckoutButton,
    additionalCheckoutButtonLoading,
    additionalKeepShoppingButton,
    handleClose,
  }): JSX.Element => (
    <Outer>
      <Inner>
        <StyledButton onClick={handleClose} colour={"black-reverse"} wide={"true"}>
          {additionalKeepShoppingButton}
        </StyledButton>
        <StyledButton onClick={handleCheckout} colour={"red"} wide={"true"}>
          {loading ? additionalCheckoutButtonLoading : additionalCheckoutButton}
        </StyledButton>
      </Inner>
      {freeShippingAmount > 0 ? <FreeShippingCallToAction>{freeShippingCallToAction}</FreeShippingCallToAction> : null}
    </Outer>
  )
)
