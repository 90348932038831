import React, { createContext, useEffect, useState } from "react"
import TagManager from "react-gtm-module"
import VisibilitySensor from "react-visibility-sensor"
import { useSettings } from "../hooks/useSettings"

export const TrackingContext: any = createContext({})

const originalGTM = TagManager.gtm

TagManager.gtm = props => {
  const { id } = props

  const { dataScript } = originalGTM.bind(TagManager)(props)

  // const iframeUrl = `https://ss.rossiboots.com.au/ns.html`

  const iframe = `
    <iframe src="https://www.googletagmanager.com/ns.html?id=${id}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>
  `

  const scriptLiteral = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${id}');
  `

  const noScript = () => {
    const noscript = document.createElement("noscript")
    noscript.innerHTML = iframe
    return noscript
  }

  const script = () => {
    const script = document.createElement("script")
    script.innerHTML = scriptLiteral
    return script
  }

  return {
    dataScript,
    noScript,
    script,
  }
}

export const TrackingProvider = ({ children }): JSX.Element => {
  const { tracking } = useSettings()
  const [tracked, setTracked] = useState(false)

  const values = {
    gtm: TagManager,
    tracked,
    setTracked,
    VisibilitySensor: VisibilitySensor,
  }

  useEffect(() => {
    if (!tracked && tracking?.googleTagManagerId) {
      TagManager.initialize({
        gtmId: tracking?.googleTagManagerId,
      })
      setTracked(true)
    }
  }, [tracking?.googleTagManagerId])

  return <TrackingContext.Provider value={{ ...values }}>{children}</TrackingContext.Provider>
}

export const withTracking = Component => (props): JSX.Element => (
  <TrackingContext.Consumer>{values => <Component {...props} layout={values} />}</TrackingContext.Consumer>
)
