import React from "react"

import { withInstant } from "./withInstant"
import { Inner, Outer } from "./InstantStyles"
import { Popup } from "../../Popup/Popup"
import { ReactifySearchProvider, Sensors } from "@usereactify/search"
import { InstantInner } from "./InstantInner"

import config from "../../../../config.default.js"

export const Instant = withInstant(
  ({ activeSearch, handleClose }): JSX.Element => {
    return (
      <Popup active={activeSearch} width={"full"} onClose={handleClose} attachTop>
        <Outer>
          <Inner>
            <ReactifySearchProvider
              mode="instant-search"
              shopifyPermanentDomain={config?.stores?.[process.env.GATSBY_SHOPIFY_DEFAULT_SHOP]?.searchDomain}
            >
              <Sensors />
              <InstantInner />
            </ReactifySearchProvider>
          </Inner>
        </Outer>
      </Popup>
    )
  }
)
