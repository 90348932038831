import tw, { styled } from "twin.macro"

export const Wrapper = styled.div<ActiveProps>`
  ${tw`pt-1 pb-0-8 text-center w-full md:w-auto`}
`

export const IconWrapper = styled.div`
  ${tw`inline-block`}
  .brand {
    ${tw`inline-block`}
  }
  svg {
    ${tw`m-auto`}
  }
`

type ActiveProps = {
  active: "true" | "false"
}
