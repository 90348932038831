import tw, { styled } from "twin.macro"

export const Outer = styled.div`
  height: 504px;
  ${tw`max-w-xl mx-auto px-3 pt-6 pb-3-2`}
`

export const ColumnsWrapper = styled.div`
  ${tw`flex`}
`

export const Column = styled.div`
  ${tw`ml-4 flex-1`}
  ${({ first }) => (first ? tw`ml-0` : null)}
`

export const ImageWrapper = styled.div`
  height: 324px;
  ${tw`relative`}
`

export const ImageContent = styled.div`
  ${tw`text-center absolute inset-x-0 bottom-4 pointer-events-none`}
`

export const ImageText = styled.div`
  ${tw`text-13 text-white`}
`

export const ImageLink = styled.div`
  ${tw`text-13 font-normal text-white underline`}
`

export const ButtonWrapper = styled.div`
  ${tw`text-center mt-4-8`}
`
