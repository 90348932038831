import { graphql, useStaticQuery } from "gatsby"
export const useSale = () => {
  const { settings, promotions } = useStaticQuery(graphql`
    query StaticPromotions {
      settings: sanitySettingPromotions {
        active
      }
      promotions: allSanityPromotion {
        __typename
        nodes {
          slug {
            current
          }
        }
      }
    }
  `)

  //  const { settings } = useStaticQuery(graphql`
  //   query StaticPromotions {
  //     settings: sanitySettingPromotions {
  //       active
  //     }
  //   }
  // `)

  const getSaleVisibility = (promotion = [], promotionVisibility: "hide" | "show"): "hide" | "show" => {
    const sale = settings?.active ? settings : null
    const sectionPromotions = promotion?.reduce((result, { slug }) => (slug?.current ? [...result, slug.current] : result), []) || []
    const isPromotionActive = sale?.enabled && typeof sale.name === "string" && sectionPromotions.includes(sale.name.toLowerCase())

    if (promotionVisibility === "show" && !isPromotionActive) return "hide"
    return isPromotionActive && promotionVisibility ? promotionVisibility : "show"
  }

  const getOnSale = () => {
    // find the active promotion
    const activePromotion = promotions.nodes.find(promotion => promotion?.slug?.current === settings?.active)

    if (activePromotion?.slug?.current) {
      return activePromotion.slug.current
    }
    return "default"
  }

  return { getSaleVisibility, getOnSale }
}
