import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useCart } from "../../hooks/useCart"
import { useShopify } from "../../hooks/useShopify"
import { useCheckoutContext } from "../../hooks/useCheckout"

export const withFreeGiftPicker = Component => ({ name = "SizeGuide" }) => {
  const { availableFreeGiftProducts } = useCart()
  const { getHandle, getProducts } = useShopify()
  const { checkout } = useCheckoutContext()

  const [items, setItems] = useState([])

  useEffect(() => {
    const fetchItems = async () => {
      const items =
        availableFreeGiftProducts?.length > 0
          ? await getProducts({
              handles: availableFreeGiftProducts?.map(product => getHandle(product)),
              firstImages: 2,
              firstVariants: 50,
            })
          : []

      const lineItems = checkout?.lineItems
      const filteredItems = items?.filter(item => {
        const itemVariantIds = item?.variants?.map(variant => variant?.id)
        const ret = lineItems?.find(lineItem => {
          const isFreeGift = lineItem?.customAttributes?.find(attribute => attribute?.key === "_promotionalFreeGift")

          return isFreeGift && itemVariantIds?.includes(lineItem?.variant?.id)
        })
        return !ret
      })
      const dict = filteredItems?.reduce((dict, item) => {
        dict[item.id] = item
        return dict
      }, {})
      setItems(Object.values(dict))
    }
    fetchItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableFreeGiftProducts])

  const { productTemplateData } = useStaticQuery(graphql`
    query PRODUCT_TEMPLATE_FREE_GIFT_PICKER {
      productTemplateData: sanityTemplateProduct {
        description: _rawAdditionalFreeGiftDescription(resolveReferences: { maxDepth: 8 })
      }
    }
  `)

  const { description } = productTemplateData || {}

  Component.displayName = name
  return <Component items={items} description={description} />
}
