import React, { useState, useCallback, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useCore } from "./useCore"
import { useApp } from "./useApp"
import { useLocation } from "./useLocation"

export const useAnnouncement = () => {
  const {
    helpers: { storage },
  } = useCore()

  const { location } = useLocation()

  const {
    config: {
      settings: { keys },
    },
    globalStateReducer,
  } = useApp()

  const { announcementsData } = useStaticQuery(graphql`
    query SANITY_SETTINGS_ANNOUNCEMENTS {
      announcementsData: sanitySettingAnnouncement {
        duration
        expiry
        announcements {
          title
          region
          link {
            link
            external
          }
        }
        footerCookies {
          cookiesContent: _rawCookiesContent(resolveReferences: { maxDepth: 4 })
        }
      }
    }
  `)
  const { announcements: announcementsRaw, expiry = 7, duration = 10, footerCookies } = announcementsData || {}

  const announcements = React.useMemo(() => {
    if (!location) return null
    const ret = announcementsRaw?.filter(item => {
      const region = item?.region?.toLowerCase() || "global"

      return region === location?.toLowerCase() || region === "global"
    })

    return ret
  }, [announcementsRaw, location])

  const [active, setActive] = useState(0)

  const [, dispatch] = globalStateReducer

  const items = announcements || []
  const length = items?.length - 1
  const cookies = footerCookies?.cookiesContent

  const [manualMode, setManualMode] = useState(false)

  const nextItem = useCallback(
    (manual = true) => {
      if (length) {
        if (manual) {
          setManualMode(true)
        }
        setActive(active => (active === length ? 0 : active + 1))
      }
    },
    [length]
  )

  const prevItem = useCallback(
    (manual = true) => {
      if (length) {
        if (manual) {
          setManualMode(true)
        }
        setActive(active => (active === 0 ? length : active - 1))
      }
    },
    [length]
  )

  useEffect(() => {
    const interval = setInterval(() => {
      if (!manualMode) {
        nextItem(false)
      }
    }, duration * 1000)
    return () => clearInterval(interval)
  }, [duration, nextItem, manualMode])

  const hide = useCallback(() => {
    storage.set(keys?.announcement, "hidden", expiry)
    dispatch({
      type: "HIDE_ANNOUNCEMENT",
    })
  }, [dispatch, storage, keys?.announcement, expiry])

  const hideCookies = useCallback(() => {
    storage.set(keys?.cookies, "hidden", expiry)
    dispatch({
      type: "HIDE_COOKIES",
    })
  }, [dispatch, storage, keys?.cookies, expiry])

  return {
    active,
    items,
    hide,
    nextItem,
    prevItem,
    cookies,
    hideCookies,
  }
}
