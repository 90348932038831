import React from "react"

import { Body } from "../Styled/Text"
import { Label, Marker, StyledCheckbox } from "./CheckboxStyles"

export const CheckboxInput = ({ checked, children, name, onChange, required = false, title }) => (
  <Label as={`label`} title={title}>
    <StyledCheckbox checked={checked} name={name} onChange={onChange} required={required} type={`checkbox`} />
    <Marker active={checked} />
    <Body>{children}</Body>
  </Label>
)

export const CheckboxButton = ({ active, handleChange, title, children, halfWidth, label }) => (
  <Label as={`label`} title={title} onClick={handleChange} halfWidth={halfWidth}>
    <Marker active={active} />
    <Body>{children || title || label}</Body>
  </Label>
)
