import React from "react"

import { withNewsletter } from "./withNewsletter"
import { Popup } from "../Popup/Popup"
import { Image } from "../Image/Image"
import { Content, ImageWrapper } from "./NewsletterStyles"
import { NewsletterForm } from "./NewsletterForm"

export const Newsletter = withNewsletter(({ activeSubscribe, setActiveSubscribe, featuredImageFluid, featuredImageAlt }) => (
  <Popup active={activeSubscribe} setActive={setActiveSubscribe} width={"lg"} mobileAttachTop>
    <Content>
      <ImageWrapper>
        <Image image={featuredImageFluid} alt={featuredImageAlt} fullHeight />
      </ImageWrapper>
      <NewsletterForm setActiveSubscribe={setActiveSubscribe} />
    </Content>
  </Popup>
))
