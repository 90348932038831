import React from "react"
import { useApp } from "../../../hooks/useApp"
import { useCheckoutContext } from "../../../hooks/useCheckout"

export const withCard = Component => ({ name = "Card", item, layout = "", stackPrice = false }) => {
  const { images, url, title, tags, defaultVariant, id } = item
  const { giftcardHandle } = useCheckoutContext()

  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const message = tags?.find(tag => tag.includes(`message`))?.split(":")[2]
  const messageColour = tags?.find(tag => tag.includes(`message`))?.split(":")[1]

  const isGiftCard = item.handle === giftcardHandle

  Component.displayName = name
  return (
    <Component
      item={item}
      isGiftCard={isGiftCard}
      images={images}
      url={isGiftCard ? `${routes.GIFTCARD}` : url}
      title={title}
      tags={tags}
      message={message}
      messageColour={messageColour}
      defaultVariant={defaultVariant}
      layout={layout}
      stackPrice={stackPrice}
      productId={id}
    />
  )
}
