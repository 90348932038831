import tw, { styled } from "twin.macro"
import { SubTitle } from "../../Styled/Text"

export const Wrapper = tw.nav`
  flex flex-row flex-auto
`

export const List = styled.div`
  ${tw`hidden md:block mx-1-6 list-none flex-1`}
`

export const Title = tw(SubTitle)`leading-1.1 h-4`

export const ListItem = tw.div`
  text-14 leading-1.78 font-normal
`
