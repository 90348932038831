import React, { useRef, useEffect } from "react"
import { useCore } from "../../../hooks/useCore"

export const withStarRating = Component => ({ name = "StarRating", productId }) => {
  const widgetContainer = useRef(null)

  const initialiseReviewsWidget = () => {
    window.okeWidgetApi.initWidget(widgetContainer.current)
  }

  let interval

  const {
    helpers: { decodeShopifyId },
  } = useCore()

  const decodeAlternate = string => {
    let decoded
    try {
      decoded = decodeShopifyId(string, "Product")
    } catch {
      // nothing
    }
    if (!decoded && typeof Buffer !== "undefined") {
      return Buffer.from(string, "base64").toString().split("Product/")[1]
    } else {
      return decoded || string
    }
  }

  let tmpId = productId

  if (typeof productId === "number") {
    tmpId = String(productId)
  }

  const id =
    (typeof tmpId === "string" && !tmpId?.includes?.("gid://") && tmpId?.length !== 13
      ? decodeAlternate(tmpId)
      : tmpId?.replace?.("gid://shopify/Product/", "")) || tmpId

  const intervalRef = React.useRef()

  useEffect(() => {
    let attempts = 0
    clearInterval(intervalRef.current)
    intervalRef.current = setInterval(() => {
      attempts++
      if (window.okeWidgetApi && widgetContainer.current && widgetContainer.current.dataset.okeReviewsProductId !== "shopify-undefined") {
        initialiseReviewsWidget()
        clearInterval(intervalRef.current)
      } else if (attempts > 30) {
        clearInterval(intervalRef.current)
      }
    }, 500)
  }, [])

  Component.displayName = name
  return <Component widgetContainer={widgetContainer} productId={id} />
}
