import tw, { styled } from "twin.macro"

import { Icon } from "../../Icon/Icon"

export const SearchButton = tw.button`
  flex flex-row items-center
`

export const Text = tw.p`
  text-12 font-bold tracking-10 leading-2 hidden md:block mr-0-3 uppercase
`

export const StyledIcon = styled(Icon)`
  ${tw`md:mr-0-7`}
`
