import React from "react"
import { Link } from "gatsby"

import { Icon } from "../../Icon/Icon"
import { withBrand } from "./withBrand"
import { Wrapper, IconWrapper } from "./BrandStyles"

export const Brand = withBrand(({ url, active, width, height }) => (
  <Wrapper active={active}>
    <IconWrapper as={Link} to={url}>
      <Icon className="brand" name={"logo"} width={width} height={height} title={"Rossi brand logo"} />
    </IconWrapper>
  </Wrapper>
))
