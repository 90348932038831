import React from "react"
import { Link } from "gatsby"

import { withWishlistWidget } from "./withWishlistWidget"
import { Wrapper, Count, StyledIcon } from "./WishlistWidgetStyles"
import { HeaderIconInner, HeaderIconWrapper } from "../../Header/HeaderStyles"

export const WishlistWidget = withWishlistWidget(
  ({ wishlistUrl, count }): JSX.Element => {
    const desktopWidth = 21
    const desktopHeight = 18
    const widthHeightRatio = desktopHeight / desktopWidth

    const mobileWidth = 20

    return (
      <Wrapper as={Link} to={wishlistUrl}>
        <HeaderIconWrapper>
          <HeaderIconInner>
            <StyledIcon name={"wishlist"} screen="md" width={desktopWidth} height={desktopHeight} />
            <StyledIcon screen="sm" name={"wishlist"} width={mobileWidth} height={mobileWidth * widthHeightRatio} />
            {count > 0 ? <Count>{count}</Count> : null}
          </HeaderIconInner>
        </HeaderIconWrapper>
      </Wrapper>
    )
  }
)
