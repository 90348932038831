import React from "react"
import { Link } from "gatsby"

import { withNavigationSearch } from "./withNavigationSearch"
import { Wrapper, List, SubList, ListItem, Title } from "./NavigationSearchStyles"

export const NavigationSearch = withNavigationSearch(({ items, additionalSearchQuickLinkText }) => (
  <Wrapper>
    <Title>{additionalSearchQuickLinkText}</Title>
    <List>
      {items.map(item => (
        <SubList key={item?.title}>
          {item.items.map(item => (
            <ListItem key={item?.title}>
              <Link to={item?.url}>{item?.title}</Link>
            </ListItem>
          ))}
        </SubList>
      ))}
    </List>
  </Wrapper>
))
