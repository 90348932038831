import tw, { styled } from "twin.macro"

export const Scroll = tw.div`w-full overflow-y-hidden overflow-x-auto border-t border-l border-black`
export const Table = tw.div`table w-full`
export const Row = styled.div`
  ${tw`table-row -mx-1-2 items-center justify-between`}
  :first-of-type {
    ${tw`bg-black text-white border-white`}
  }
`
export const Column = tw.div`table-cell p-1-2 text-14 text-center border-r border-b whitespace-nowrap`
