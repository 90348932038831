import React, { useState, useLayoutEffect, useRef, useCallback, useEffect, useMemo } from "react"
import { useLocation } from "@reach/router"
import { useApp } from "../../hooks/useApp"
import { useCore } from "../../hooks/useCore"
import { useScrollPosition } from "../../hooks/useScroll"
import { graphql, useStaticQuery } from "gatsby"
import { useNavColour } from "../../hooks/useNavColor"
import { useNav } from "../../hooks/useNav"

const offsetThreshold = 20

export const withHeader = Component => ({ name = "Header" }: any) => {
  const {
    helpers: { storage },
  } = useCore()

  const {
    config: {
      settings: { keys },
    },
    globalStateReducer,
  } = useApp()

  const { globalStateReducer: navGlobalStateReducer } = useNav()

  const currentRoute = useLocation()
  const [announcementHeight, setAnnouncementHeight] = useState(0)
  const [offsetHeight, setOffsetHeight] = useState(0)
  const [isScrolled, setIsScrolled] = useState(currentRoute?.pathname !== "/" || false)
  const [isShowTop, setIsShowTop] = useState(true)

  const refAnnouncementItems = useRef([])

  const { defaultLinkColor, defaultBackgroundColor, defaultHoverLinkColor } = useNavColour()

  const [{ activeAnnouncement: show, activeMenu: isMenuOpen }, dispatch] = globalStateReducer

  const navDispatch = navGlobalStateReducer?.[1]

  const isAnnouncementHidden = storage.get(keys?.announcement) === "hidden"

  const alwaysScrolled = React.useMemo(() => {
    const alwaysScrolledPathnameStarts = ["/collections", "/products", "/pages", "/search", "/account", "/wishlist", "/stockist"]
    const alwaysScrolledPathnames = ["/articles"]

    const pathnameStartsWith = alwaysScrolledPathnameStarts.some(pathname => currentRoute?.pathname?.startsWith(pathname))
    const pathnameIs = alwaysScrolledPathnames.some(pathname => currentRoute?.pathname === pathname)

    const pathname = currentRoute?.pathname

    // transparent on homepage
    if (pathname === "/" || pathname === "/home") {
      return false
    }

    // transparent on /articles/article but not /articles
    if (pathname?.startsWith("/articles/") && pathname !== "/articles/") {
      return false
    }

    // TODO: un-cook
    if (pathnameStartsWith || pathnameIs) {
      return true
    }

    return true
  }, [currentRoute])

  const headerShouldOverlap = useMemo(() => {
    if (currentRoute?.pathname === "/") {
      return true
    }
    // if current router match /articles/* but not /articles/
    if (currentRoute?.pathname?.match(/\/articles\/.+/) && currentRoute?.pathname !== "/articles/") {
      return true
    }
    return false
  }, [currentRoute])

  const updateAnnouncementHeight = React.useCallback(() => {
    let maxHeight = 0

    if (!isAnnouncementHidden) {
      for (const item of refAnnouncementItems.current) {
        if (item) {
          maxHeight = Math.max(item.clientHeight, maxHeight)
        }
      }
      maxHeight += 12 // Padding
    }

    if (maxHeight !== announcementHeight) {
      setAnnouncementHeight(maxHeight)
    }
  }, [announcementHeight, isAnnouncementHidden])

  useLayoutEffect(() => {
    updateAnnouncementHeight()
    window.addEventListener("resize", updateAnnouncementHeight)
    return () => window.removeEventListener("resize", updateAnnouncementHeight)
  }, [updateAnnouncementHeight])

  useScrollPosition(
    ({ currPos, prevPos }) => {
      const shown = currPos.y > -offsetThreshold ? true : currPos.y > prevPos.y
      if (shown !== isShowTop) {
        setIsShowTop(shown)
      }
    },
    [isShowTop, offsetHeight],
    500
  )

  // when isShowTop change update the redux state
  useEffect(() => {
    navDispatch({
      type: "TOGGLE_SHOW_TOP",
      payload: isShowTop,
    })
  }, [isShowTop, navDispatch])

  useScrollPosition(
    ({ currPos }) => {
      if (!alwaysScrolled) {
        const scrolled = currPos.y !== 0
        if (scrolled !== isScrolled) {
          setIsScrolled(scrolled)
        }
      } else if (!isScrolled) {
        setIsScrolled(true)
      }
    },
    [isScrolled, alwaysScrolled],
    500
  )

  const handleToggleMenu = () => {
    dispatch({
      type: "TOGGLE_MENU",
      payload: !isMenuOpen,
    })
    // focus body so header not hovered anymore
    if (isMenuOpen) {
      document.body.focus()
    }
  }

  // dispatch TOGGLE_SHOW_TOP if the current route is not home
  useEffect(() => {
    if (headerShouldOverlap) {
      setIsScrolled(false)
    }
  }, [headerShouldOverlap])

  Component.displayName = name
  return (
    <Component
      announcementItemRefs={refAnnouncementItems}
      handleToggleMenu={handleToggleMenu}
      announcementHeight={announcementHeight}
      isMenuOpen={isMenuOpen}
      isScrolled={isScrolled}
      isShowTop={isShowTop && show}
      isAnnouncementActive={show && !isAnnouncementHidden}
      currentRoute={currentRoute}
      activeBackgroundColor={defaultBackgroundColor}
      defaultLinkColor={defaultLinkColor}
      defaultHoverLinkColor={defaultHoverLinkColor}
      headerShouldOverlap={headerShouldOverlap}
    />
  )
}
