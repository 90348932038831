import tw, { styled } from "twin.macro"

import background from "../../../static/images/background.png"
import { PaymentIcons } from "./PaymentIcons/PaymentIcons"
import { Brand } from "./Brand/Brand"

export const Bg = styled.div`
  background-image: url(${background});
  background-color: #2b2822;
  ${tw`pt-3 md:pt-4 md:pb-4 text-beige-light relative`}
`

export const InnerWrapper = tw.div`flex w-full`

export const Top = tw.div`
  flex flex-col md:flex-row justify-between w-full px-2-4 md:px-3 max-w-xl mx-auto
`

export const Left = tw.div`
  flex flex-col w-full md:w-1/3
`

export const Right = tw.div`
  flex flex-row w-full md:w-2/3 md:justify-end flex-auto md:pl-6 mlg:pl-10
`

export const Bottom = tw.div`
  flex-wrap flex flex-row items-center lg:justify-between md:px-3 max-w-xl mx-auto
`

export const Title = tw.h2`
  text-50 leading-1.2 md:leading-1.1 mb-4 md:mb-5-6 font-bold uppercase
`

export const DesktopPaymentIcons = styled(PaymentIcons)`
  ${tw`hidden md:flex`}
`

export const DesktopBrand = styled(Brand)`
  ${tw`hidden md:block`}
`

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (min-width: 768px) {
    .skidman_logo {
      width: 130px !important;
      height: 80px;
    }
  }
`
