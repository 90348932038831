import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useRoutes } from "../../../hooks/useRoutes"

export const withSellingPoints = Component => ({ name = "SellingPoints" }) => {
  const { footer } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_GLOBAL_FOOTER_SELLING_POINTS {
      footer: sanityTemplateGlobal {
        footerSellingPoints: _rawFooterSellingPoints(resolveReferences: { maxDepth: 3 })
      }
    }
  `)

  const { footerSellingPoints } = footer || {}
  const { linkResolver } = useRoutes()

  const getSpecialAction = point => {
    if (point.icon === "liveChat") {
      return "liveChat"
    }

    return null
  }

  const items = footerSellingPoints?.map((point, index) => ({
    icon: point.icon,
    title: linkResolver(point.link)?.title,
    url: linkResolver(point.link)?.url,
    hiddenOnMobile: index >= 3,
    specialAction: getSpecialAction(point),
  }))

  Component.displayName = name
  return <Component sellingPoints={items} />
}
