import React from "react"

import Account from "../../../static/icons/account.svg"
import Arrow from "../../../static/icons/arrow.svg"
import ArrowLeft from "../../../static/icons/arrow-left.svg"
import ArrowLeftSmall from "../../../static/icons/arrow-left-small.svg"
import BadgeAbrasionResistant from "../../../static/icons/badge-abrasion-resistant.svg"
import BadgeAntiPerforation from "../../../static/icons/badge-anti-perforation.svg"
import BadgeBreathable from "../../../static/icons/badge-breathable.svg"
import BadgeHeritage from "../../../static/icons/badge-heritage.svg"
import BadgeQuickReleaseZip from "../../../static/icons/badge-quick-release-zip.svg"
import Cart from "../../../static/icons/cart.svg"
import Chevron from "../../../static/icons/chevron.svg"
import ChevronLeft from "../../../static/icons/chevron-left.svg"
import ChevronRight from "../../../static/icons/chevron-right.svg"
import Cross from "../../../static/icons/cross.svg"
import Delivery from "../../../static/icons/delivery.svg"
import Email from "../../../static/icons/email.svg"
import FeatureAbrasionResistant from "../../../static/icons/feature-abrasion-resistant.svg"
import FeatureAntiPerforation from "../../../static/icons/feature-anti-perforation.svg"
import FeatureAntiSlip from "../../../static/icons/feature-anti-slip.svg"
import FeatureBreathable from "../../../static/icons/feature-breathable.svg"
import FeatureLeather from "../../../static/icons/feature-leather.svg"
import FeatureLight from "../../../static/icons/feature-light.svg"
import FeatureQuickReleaseZip from "../../../static/icons/feature-quick-release-zip.svg"
import GiftCard from "../../../static/icons/gift-card.svg"
import LiveChat from "../../../static/icons/live-chat.svg"
import Marker from "../../../static/icons/marker.svg"
import MarkerBlack from "../../../static/icons/marker-black.svg"
import Plus from "../../../static/icons/plus.svg"
import Returns from "../../../static/icons/returns.svg"
import Search from "../../../static/icons/search.svg"
import SearchStore from "../../../static/icons/search-store.svg"
import Star from "../../../static/icons/star.svg"
import Tick from "../../../static/icons/tick.svg"
import Video from "../../../static/icons/video.svg"
import Wishlist from "../../../static/icons/wishlist.svg"
import WishlistFilled from "../../../static/icons/wishlist-filled.svg"
import Facebook from "../../../static/icons/facebook.svg"
import Pinterest from "../../../static/icons/pinterest.svg"
import Instagram from "../../../static/icons/instagram.svg"
import Logo from "../../../static/icons/logo.svg"
import Menu from "../../../static/icons/menu.svg"
import Minus from "../../../static/icons/minus.svg"
import SocialGoogle from "../../../static/icons/social-google.svg"
import SocialFacebook from "../../../static/icons/social-facebook.svg"

import AfterPay from "../../../static/icons/payment-afterpay.svg"
import American from "../../../static/icons/payment-american.svg"
import ApplePay from "../../../static/icons/payment-applepay.svg"
import MasterCard from "../../../static/icons/payment-mastercard.svg"
import Paypal from "../../../static/icons/payment-paypal.svg"
import Visa from "../../../static/icons/payment-visa.svg"
import Zip from "../../../static/icons/payment-zip.svg"
import SKidmanLogo from "../../../static/icons/skidman-logo.svg"

export const withIcon = Component => ({ componentName = "Icon", width, height, name, title, className, screen }: any) => {
  const icons = {
    account: Account,
    arrow: Arrow,
    arrowLeft: ArrowLeft,
    arrowLeftSmall: ArrowLeftSmall,
    badgeAbrasionResistant: BadgeAbrasionResistant,
    badgeAntiPerforation: BadgeAntiPerforation,
    badgeBreathable: BadgeBreathable,
    badgeHeritage: BadgeHeritage,
    badgeQuickReleaseZip: BadgeQuickReleaseZip,
    cart: Cart,
    chevron: Chevron,
    chevronLeft: ChevronLeft,
    chevronRight: ChevronRight,
    cross: Cross,
    delivery: Delivery,
    email: Email,
    featureAbrasionResistant: FeatureAbrasionResistant,
    featureAntiPerforation: FeatureAntiPerforation,
    featureAntiSlip: FeatureAntiSlip,
    featureBreathable: FeatureBreathable,
    featureLeather: FeatureLeather,
    featureLight: FeatureLight,
    featureQuickReleaseZip: FeatureQuickReleaseZip,
    giftCard: GiftCard,
    liveChat: LiveChat,
    marker: Marker,
    markerBlack: MarkerBlack,
    plus: Plus,
    returns: Returns,
    search: Search,
    searchStore: SearchStore,
    star: Star,
    tick: Tick,
    video: Video,
    wishlist: Wishlist,
    wishlistFilled: WishlistFilled,
    facebook: Facebook,
    pinterest: Pinterest,
    instagram: Instagram,
    logo: Logo,
    menu: Menu,
    minus: Minus,
    afterPay: AfterPay,
    american: American,
    applePay: ApplePay,
    masterCard: MasterCard,
    paypal: Paypal,
    visa: Visa,
    zip: Zip,
    socialGoogle: SocialGoogle,
    socialFacebook: SocialFacebook,
    sKidmanLogo: SKidmanLogo,
  }

  const icon = icons[name]

  Component.displayName = componentName
  return icon ? (
    <Component
      screen={screen}
      icon={icon}
      title={title || name}
      width={width || icon.viewBox.split(" ")[2]}
      height={height || icon.viewBox.split(" ")[3]}
      className={className}
    />
  ) : (
    <p>{name}</p>
  )
}
