import tw, { styled } from "twin.macro"

const styles = {
  default: tw`block max-w-full select-none`,
  mobile: tw`block md:hidden max-w-full select-none`,
  desktop: tw`hidden md:block max-w-full select-none`,
}

export const StyledIcon = styled.span`
  ${props => {
    if (!props?.screen) {
      return styles.default
    }
    if (props?.screen === "sm") {
      return styles.mobile
    } else if (props?.screen === "md") {
      return styles.desktop
    }
  }}
  > svg {
    fill: transparent;
  }
`
