import React from "react"
import { Link } from "gatsby"

import { List, ListItem } from "../NavigationHeaderDesktopStyles"
import { withNavigationHeaderDesktopMain } from "./withNavigationHeaderDesktopMain"
import { NavigationHeaderDesktopDropdown } from "./Dropdown/NavigationHeaderDesktopDropdown"

export const NavigationHeaderDesktopMain = withNavigationHeaderDesktopMain(
  ({ items, handleHover, active, defaultHoverLinkColor, onDropdown, setOnDropdown, closeMenuIfDropdownIsNotOpen, isShowTop, isNavFlipped }) => {
    return (
      <>
        <List>
          {items.map(item => {
            return (
              <ListItem
                key={item?.title}
                onMouseEnter={() => handleHover(item.title)}
                onClick={() => handleHover("")}
                // onMouseLeave={() => (item.items.length > 0 ? closeMenuIfDropdownIsNotOpen() : handleHover(""))}
                active={active === item.title}
                defaultLinkColor={isNavFlipped ? item?.colour : !isShowTop && item?.colour}
                defaultHoverLinkColor={item?.hoverColour || defaultHoverLinkColor}
              >
                <Link onMouseEnter={() => handleHover(item.title)} to={item?.url}>
                  {item?.title}
                </Link>
                {item.items.length > 0 ? (
                  <NavigationHeaderDesktopDropdown
                    item={item}
                    active={active === item.title}
                    onMouseEnter={() => setOnDropdown(true)}
                    onMouseLeave={() => setOnDropdown(true)}
                  />
                ) : null}
              </ListItem>
            )
          })}
        </List>
        <div
          onMouseEnter={() => setOnDropdown(false)}
          onMouseLeave={() => handleHover("")}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: -1,
            pointerEvents: onDropdown ? "all" : "none",
            background: "transparent",
            content: "",
          }}
        ></div>
      </>
    )
  }
)
