import React from "react"

import { useWindowSize } from "../../../hooks/useWindowSize"
import { useApp } from "../../../hooks/useApp"

export const withBrand = Component => ({ name = "Brand", active }) => {
  const { windowSize } = useWindowSize()

  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const iconWidth = windowSize.width > 1025 ? "112" : windowSize.width > 768 ? "80" : "80"
  const iconHeight = windowSize.width > 1025 ? "64" : windowSize.width > 768 ? "60" : "60"

  const url = routes.HOMEPAGE

  Component.displayName = name
  return <Component url={url} active={active} width={iconWidth} height={iconHeight} />
}
