import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useWindowSize } from "../../hooks/useWindowSize"

export const withFooter = Component => ({ name = "Footer" }: any) => {
  const { footer } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_GLOBAL_FOOTER_TITLE {
      footer: sanityTemplateGlobal {
        additionalFooterTitle
      }
    }
  `)
  const { windowSize } = useWindowSize()
  const { additionalFooterTitle } = footer || {}
  const isMobile =  windowSize.width >= 768
  Component.displayName = name
  return <Component title={additionalFooterTitle} isMobile={isMobile} />
}
