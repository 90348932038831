import React from "react"
import { Link } from "gatsby"

import { withNavigationTerms } from "./withNavigationTerms"
import { Wrapper, List, ListItem, ListItemMd, Bottom } from "./NavigationTermsStyles"

export const NavigationTerms = withNavigationTerms(({ items, copyright }) => (
  <Wrapper>
    <List>
      {items.map(item => (
        <ListItem key={item?.title}>
          <Link to={item?.url}>{item?.title}</Link>
        </ListItem>
      ))}
      <ListItemMd tw="hidden">{copyright}</ListItemMd>
      <Bottom>{copyright}</Bottom>
    </List>
  </Wrapper>
))
