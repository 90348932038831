import React from "react"
import { InstantSearchMessage, ButtonWrapper } from "./InstantStyles"
import { NavigationSearch } from "../../Navigation/Search/NavigationSearch"
import { SearchForm } from "../Form/SearchForm"
import { Results } from "@usereactify/search"
import { Card } from "../../Product/Card/Card"
import { useWindowSize } from "../../../hooks/useWindowSize"
import { StyledButton } from "../../Styled/Button"
import { Link } from "gatsby"
import { withInstantInner } from "./withInstantInner"

export const InstantInner = withInstantInner(
  ({ additionalViewAllResultsText, numberOfResults, searchTerm, handleClose }): JSX.Element => {
    const { windowSize } = useWindowSize()
    const isMobile = windowSize.width <= 768

    return (
      <>
        <SearchForm layout={"instant"} />
        <Results
          pageSize={3}
          listClassName="instant-search-results"
          renderResultCardProduct={({ product, pagePosition }) =>
            searchTerm?.length ? <Card item={product} layout={"suggestion"} stackPrice={isMobile} /> : null
          }
          renderResultCardCallout={props => <></>}
          renderLoading={() => <InstantSearchMessage>Loading</InstantSearchMessage>}
          renderNoResults={() => <InstantSearchMessage>No Results</InstantSearchMessage>}
          renderPagination={props => <></>}
        />
        {numberOfResults > 0 ? (
          <>
            {searchTerm?.length ? (
              <ButtonWrapper>
                <StyledButton as={Link} to={`/search?q=${searchTerm}`} size={"tertiary"} colour={"red-reverse"} onClick={handleClose}>
                  {additionalViewAllResultsText}
                </StyledButton>
              </ButtonWrapper>
            ) : (
              <NavigationSearch />
            )}
          </>
        ) : null}
      </>
    )
  }
)
