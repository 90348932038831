import tw from "twin.macro"

export const Outer = tw.div`
  relative
`

const InnerBase = `absolute inset-0 h-full w-full pointer-events-none md:group-hover:pointer-events-auto`

// display secondary image on hover
export const Inner = tw.div`${InnerBase} opacity-0 md:group-hover:opacity-100`
