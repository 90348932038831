import React, { useState, useEffect } from "react"
import { useDotdigital } from "../hooks/useDotdigital"


export const CustomerContext: any = React.createContext({})

export const CustomerProvider = ({ children }): JSX.Element => {
  const { identifyUser } = useDotdigital()

  const [customer, setCustomer] = useState(false)
  const [socialLoginErrors, setSocialLoginErrors] = useState(null)

  const values = {
    customer,
    setCustomer,
    socialLoginErrors,
    setSocialLoginErrors,
  }

  useEffect(() => {
    if (customer?.email) {
      identifyUser(customer)
    }
  }, [customer, identifyUser])

  return <CustomerContext.Provider value={{ ...values }}>{children}</CustomerContext.Provider>
}
