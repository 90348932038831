import React from "react"

import { useAnnouncement } from "../../hooks/useAnnouncement"
import { useApp } from "../../hooks/useApp"
import { useCore } from "../../hooks/useCore"
import { useWindowSize } from "../../hooks/useWindowSize"

export const withAnnouncement = Component => ({ name = "Announcement", itemRefs, scrolled, height, show }) => {
  const { windowSize } = useWindowSize()
  const { items, hide, active, nextItem, prevItem } = useAnnouncement()

  const {
    helpers: { storage },
  } = useCore()

  const {
    config: {
      settings: { keys },
    },
  } = useApp()

  const handleHide = () => hide()

  const finalHeight = windowSize.width > 768 && scrolled ? 0 : height

  const isAnnouncementHidden = storage.get(keys?.announcement) === "hidden"

  Component.displayName = name
  return (
    <Component
      nextItem={nextItem}
      prevItem={prevItem}
      itemRefs={itemRefs}
      active={active}
      items={items}
      handleHide={handleHide}
      height={finalHeight}
      show={show}
      isAnnouncementHidden={isAnnouncementHidden}
    />
  )
}
