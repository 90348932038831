import tw, { styled } from "twin.macro"
import { css } from "@emotion/react"

import { Body } from "./Text"

export const StyledForm = tw.form`block w-full`

export const StyledFormErrors = tw(Body)`my-0-8 text-red`

export const StyledInput = styled.input<InputProps>`
  ${tw`block appearance-none outline-none w-full border-b bg-transparent border-black font-normal py-1-6 transition-all duration-200 placeholder-brown placeholder-opacity-100 text-16 md:text-12 leading-1.28 md:leading-1.75`}
  ${({ spacing }) => (spacing === "true" ? tw`mb-0-8` : tw`mb-0`)}
  ${({ first }) => (first ? tw`md:mr-1-2` : tw`md:mr-0`)}
  ${({ last }) => (last ? tw`md:ml-1-2` : tw`md:ml-0`)}
  ${({ disabled }) => disabled && tw`pointer-events-none opacity-50`}
  ${({ errors }) => errors && tw`border-red text-red`}
  ${css`
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      ${tw`m-0`}
      -webkit-appearance: none;
    }
    &[type="number"] {
      -moz-appearance: textfield;
    }
    &::placeholder {
      ${tw`text-brown opacity-100 uppercase md:text-13 text-16 leading-none md:leading-1.38 tracking-10`}
    }
  `}
`

export const StyledTextArea = styled.textarea<TextareaProps>`
  ${tw`min-h-12 block appearance-none outline-none w-full border-b border-black py-1-6 resize-none text-16 leading-1.28 transition-all duration-200 bg-beige-light`}
  ${({ disabled }) => disabled && tw`pointer-events-none opacity-50`}
  ${({ spacing }) => (spacing === "true" ? tw`mb-2-4 md:mb-3-2` : tw`mb-0`)}
  ${css`
    &::placeholder {
      ${tw`text-brown opacity-100 uppercase  text-16 md:text-12 leading-1.28 md:leading-1.75 tracking-10`}
    }
  `}
`

export const StyledSelect = styled.select<SelectProps>`
  ${({ layout }) => {
    if (layout === "productVariant") return tw`py-1 px-1-6 text-14 leading-1.28 rounded-5`
    if (layout === "cardVariant") return tw`py-0-2 px-0-8 md:text-13 text-16 leading-none md:leading-1.38 rounded-2`
    else return tw`px-0-8 py-0-5 rounded-2 leading-none text-16`
  }}
  ${tw`block appearance-none outline-none w-full bg-transparent border text-black cursor-pointer`}
`

export const StyledDropdownSelect = styled.select<DropdownSelectProps>`
  ${({ layout }) => {
    if (layout === "form") return tw`py-1-6 text-16 md:text-12 leading-1.28 md:leading-1.75`
    if (layout === "sortBy") return tw`text-16 md:text-12 pb-0-6 md:pb-1-2 leading-1.28 md:leading-2.08 text-black`
    if (layout === "address") return tw`pb-1-6 pt-2-6 text-18 leading-1.56 border-black border-opacity-50`
  }}
  ${tw`block appearance-none outline-none w-full bg-transparent rounded-none border-b cursor-pointer uppercase tracking-10`}
  -webkit-appearance: none;
  &:invalid {
    ${({ layout }) => {
      if (layout === "form") return tw`text-brown uppercase text-16 tracking-10`
    }}
  }
  [disabled] {
    ${({ layout }) => {
      if (layout === "form") return tw`text-brown uppercase text-16 tracking-10`
    }}
  }
`

export const StyledSelectOption = tw.option``

export const StyledSelectWrapper = styled.div<SelectWrapperProps>`
  ${({ spacing }) => (spacing === "true" ? tw`mb-0-8` : tw`mb-0`)}
  ${tw`relative w-full`}
`

export const StyledInputWrapper = styled.div<InputWrapperProps>`
  ${tw`w-full relative transition-all duration-200`}
  ${({ loading }) => loading && tw`opacity-50 pointer-events-none`}
  ${({ marginTop }) => marginTop == "small" && tw`mt-2`}
`

export const StyledInputIconWrapper = styled.button`
  ${tw`absolute bottom-0 top-0 right-0 h-full flex items-center justify-center hover:translate-x-0-4 transform duration-300`}
  ${({ onClick, type }) => !onClick && type !== `submit` && tw`pointer-events-none`}
`

export const StyledLabel = styled.label`
  ${tw` text-14 leading-1.71 block mb-0-8`}
`

export const StyledLabelPlaceholder = styled.label`
  ${tw`block text-brown opacity-100 uppercase text-16 md:text-12 tracking-10 h-2`}
`
export const StyledLabelPlaceholderSpaced = styled(StyledLabelPlaceholder)`
  ${tw`my-1`}
`

export const StyledLabelTight = styled.label`
  ${tw` text-14 leading-1.71 block mb-0`}
`

type InputProps = {
  spacing?: "true" | "false"
  first?: boolean
  last?: boolean
  disabled?: boolean
  errors?: boolean
}

type TextareaProps = {
  spacing?: "true" | "false"
  disabled?: boolean
  errors?: boolean
}

type InputWrapperProps = {
  loading?: boolean
}

type SelectProps = {
  layout?: "productVariant" | "cardVariant"
}

type DropdownSelectProps = {
  layout: "sortBy" | "form" | "address"
}

type SelectWrapperProps = {
  spacing?: "true" | "false"
}
