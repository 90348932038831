import React, { useState, useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useShopify } from "../../../../../hooks/useShopify"
import { useSale } from "../../../../../hooks/useSale"
import { useNavigation } from "../../../../../hooks/useNavigation"
import { useApp } from "../../../../../hooks/useApp"
import { useNav } from "../../../../../hooks/useNav"

export const withNavigationHeaderDesktopMain = Component => ({ name = "NavigationHeaderDesktopMain", defaultLinkColor, defaultHoverLinkColor }) => {
  const { mapItems } = useNavigation()
  const { getOnSale } = useSale()
  const { edgeNormaliser } = useShopify()

  const {
    config: {
      settings: { keys },
    },
    globalStateReducer,
  } = useApp()
  const { globalStateReducer: navGlobalStateReducer } = useNav()

  const [{ activeAnnouncement: show }] = globalStateReducer
  const [{ showTop, isNavFlipped }] = navGlobalStateReducer

  const { menus, promotions: rawPromotions } = useStaticQuery(graphql`
    query SANITY_SETTINGS_DESKTOP_MAIN_MENU {
      menus: sanitySettingMenus {
        desktopHeaderMain {
          items: _rawItems(resolveReferences: { maxDepth: 10 })
        }
      }
      promotions: allSanityPromotion {
        edges {
          node {
            title
            slug {
              current
            }
            promotion {
              desktopHeader: _rawDesktopHeader(resolveReferences: { maxDepth: 10 })
              mobileHeader: _rawMobileHeader(resolveReferences: { maxDepth: 10 })
              homepage: _rawHomepage(resolveReferences: { maxDepth: 10 })
            }
          }
        }
      }
    }
  `)

  const { desktopHeaderMain } = menus || {}

  const promotions = rawPromotions
    ? edgeNormaliser(rawPromotions).reduce(
        (result, item) =>
          item?.slug?.current
            ? {
                ...result,
                [item.slug.current]: { items: item?.promotion?.desktopHeader?.items },
              }
            : result,
        {}
      )
    : {}
  const saleName = getOnSale()

  const rawItems = saleName && promotions[saleName]?.items ? promotions[saleName]?.items : desktopHeaderMain?.items
  const items = useMemo(() => mapItems(rawItems), [rawItems, mapItems])

  const [active, setActive] = useState("")

  const [onDropdown, setOnDropdown] = useState(false)

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null)

  // on onDropdown change, clear timeout
  React.useEffect(() => {
    if (!onDropdown) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      clearTimeout(timeoutId!)
      setActive("")
    }
  }, [onDropdown, timeoutId])

  const handleHover = value => {
    if (value && timeoutId) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      clearTimeout(timeoutId!)
    }
    setActive(value)
  }

  const closeMenuIfDropdownIsNotOpen = () => {
    const newTimeoutId = setTimeout(() => {
      if (active) {
        setActive("")
      }
    }, 500)

    setTimeoutId(newTimeoutId)
  }

  Component.displayName = name
  return (
    <Component
      items={items}
      active={active}
      handleHover={handleHover}
      defaultLinkColor={defaultLinkColor}
      onDropdown={onDropdown}
      setOnDropdown={setOnDropdown}
      defaultHoverLinkColor={defaultHoverLinkColor}
      closeMenuIfDropdownIsNotOpen={closeMenuIfDropdownIsNotOpen}
      isShowTop={showTop && show}
      isNavFlipped={isNavFlipped}
    />
  )
}
