import React from "react"
import { withNavigationHeaderDesktopDropdown } from "./withNavigationHeaderDesktopDropdown"
import { Outer } from "./NavigationHeaderDesktopDropdownStyles"
import { NavigationHeaderDesktopDropdownFeatured } from "./NavigationHeaderDesktopDropdownFeatured"
import { NavigationHeaderDesktopDropdownColumns } from "./NavigationHeaderDesktopDropdownColumns"
import { NavigationHeaderDesktopDropdownMegaHeroImages } from "./NavigationHeaderDesktopDropdownMegaHeroImages"

export const NavigationHeaderDesktopDropdown = withNavigationHeaderDesktopDropdown(({ item, active, onMouseEnter, onMouseLeave }) => (
  <Outer
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    active={active}
    backgroundColor={item?.submenuBackgroundColour?.hex || "#FFF"}
    textColour={item?.submenuTextColour?.hex || "#000"}
    hoverColour={item?.submenuHoverColour?.hex || "#000"}
  >
    {item.type === "feat" && <NavigationHeaderDesktopDropdownFeatured item={item} />}
    {item.type === "columns" && <NavigationHeaderDesktopDropdownColumns item={item} nested={false} />}
    {item.type === "megaheroimages" && <NavigationHeaderDesktopDropdownMegaHeroImages item={item} />}
  </Outer>
))
