import tw, { css, styled } from "twin.macro"

export const Input = styled.div`
  ${tw`flex-1 mb-1-6`}
  input {
    ${css`
      padding: 8px 12px;
    `}
    ${tw`border-b border-black border-t-0 border-l-0 border-r-0 bg-beige-light text-20 w-full`}
    :focus {
      ${tw`bg-beige-light`}
    }
    ::placeholder {
      ${tw`text-grey opacity-100`}
    }
  }
  input + div > div {
    ${tw`top-1-1 pl-0`}
  }
  svg.search-icon {
    ${tw`w-2 h-2`}
    fill: #BE1724 !important
  }
  svg.cancel-icon {
    ${tw`w-1-2 h-1-2 mt-0-5`}
  }
  .input-group {
    box-shadow: none !important;
  }
`

export const InputWrapper = styled.div<LayoutProps>`
  ${tw`flex flex-col md:flex-row`}
  ${({ layout }) => {
    if (layout === "search") return tw`max-w-47 w-full`
    if (layout === "instant") return tw`mb-3-2 px-2-4 md:px-0`
  }}
`

export const Search = styled.div<LayoutProps>`
  ${tw`md:ml-2-4 w-full mb-1-6`}
  ${({ layout }) => {
    if (layout === "search") return tw`md:max-w-11-5`
    if (layout === "instant") return tw`md:max-w-18 `
  }}
`

type LayoutProps = {
  layout: "search" | "instant"
}
