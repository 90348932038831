import React, { memo, useMemo } from "react"

export const withNavigationHeaderDesktop = Component =>
  memo(({ name = "NavigationHeaderDesktop", active, defaultLinkColor, defaultHoverLinkColor }: any) => {
    Component.displayName = name
    return useMemo(() => <Component active={active} defaultLinkColor={defaultLinkColor} defaultHoverLinkColor={defaultHoverLinkColor} />, [
      active,
      defaultHoverLinkColor,
      defaultLinkColor,
    ])
  })
