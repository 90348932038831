import tw, { styled } from "twin.macro"
import { Link } from "gatsby"

import { Icon } from "../../../../../Icon/Icon"

export const Outer = styled.section`
  box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.08);
  ${tw`absolute inset-x-0 w-full bg-beige-light hidden md:block transition-all duration-100 ease`}
  ${({ active }) => (active ? tw`pointer-events-auto opacity-100 transition-all duration-500 ease` : tw`pointer-events-none opacity-0`)}
  ${({ backgroundColor }) => "background-color: " + backgroundColor + ";"}
  ${({ textColour }) => "color: " + textColour + ";"}
  top: 100%;
  a {
    transition: all 0.1s ease
  }
  ${({ hoverColour }) => "a:hover { color: " + hoverColour + "; }"}
`
