import { css } from "@emotion/react"
import tw, { styled } from "twin.macro"

export const StyledNavigationHeaderDesktop = styled.nav`
  ${tw`hidden mlg:flex transition-all duration-100 mx-auto flex-1 flex-row h-5-7 px-2-4 md:px-3 overflow-visible`}
  ${css`
    // flex-grow: 1;
  `}
`

export const List = styled.ul`
  ${tw`w-full list-none flex flex-row items-center h-full`}
  ${css`
    justify-content: space-evenly;
  `}
`

export const ListItem = styled.li<ActiveProps>`
  ${tw`text-14 font-bold tracking-10 transition-all duration-100 leading-1.71 h-full flex items-center px-1-2 uppercase`}
  :first-of-type {
    ${tw`pl-0 ml-0`}
  }
  :last-of-type {
    ${tw`pr-0 mr-0`}
  }
  ${({ active, defaultLinkColor, defaultHoverLinkColor }) =>
    active
      ? css`
          color: ${defaultLinkColor};
          transition: all 0.1s ease;
          &:hover {
            color: ${defaultHoverLinkColor};
            transition: all 0.1s ease;
          }
          a {
            transition: all 0.1s ease;
          }
        `
      : defaultLinkColor &&
        css`
          color: ${defaultLinkColor};
        `}
`

type ActiveProps = {
  active: boolean
}
