import tw, { styled } from "twin.macro"

export const Outer = styled.section<ActiveProps>`
  ${tw`fixed left-0 bottom-0 w-full bg-beige-DEFAULT h-0 md:overflow-hidden transition-all duration-300 z-50`}
  ${({ active }) => (active === "true" ? tw`h-auto md:min-h-3` : null)}
`

export const Inner = tw.div`
  h-full relative flex items-center justify-center py-1 px-1
`

export const Item = styled.div<ActiveProps>`
  ${tw` inset-0 h-full flex items-center justify-center text-left transform transition-all duration-300 text-10 md:text-11 font-bold text-black uppercase tracking-15`}
  ${({ active }) => (active === "true" ? tw`opacity-100 visible translate-y-0` : tw`opacity-0 invisible -translate-y-6`)}
  h5 {
    font-size: 0.625rem !important;
    @media only screen and (min-width: 768px) {
      font-size: 0.6875rem !important;
    }
  }
`

export const Close = tw.button`
  lg:absolute right-2-4 md:right-7-5 top-0 bottom-0 px-0-4 flex items-center text-black
`

export const Content = tw.p`
  block
`

type ActiveProps = {
  active: "true" | "false"
}
