import React from "react"
import { Helmet } from "react-helmet"

import { withMeta } from "./withMeta"
import { Script } from "gatsby"

export const Meta = withMeta(
  ({ languages, metadata, metatags, schemas, facebookDomainVerify }): JSX.Element => (
    <>
      <Helmet htmlAttributes={{ lang: metadata?.lang || "en" }} title={metadata?.title} meta={metatags}>
        <link href={metadata?.siteURL} rel="home" />
        <link href={metadata?.canonical} rel="canonical" />
        {languages?.map(({ key, href, hrefLang, rel }, index) => (
          <link key={key?.toString() + index} href={href} hrefLang={hrefLang} rel={rel} />
        ))}
        {schemas.map((schema, index) => (
          <script type="application/ld+json" key={index}>
            {JSON.stringify(schema)}
          </script>
        ))}
        {facebookDomainVerify?.length > 0 && <meta name="facebook-domain-verification" content={facebookDomainVerify} />}
        <meta name="oke:subscriber_id" content="2204c2d4-ae8c-4efb-b560-abf4a5af9311" />
        <script defer src="https://www.gstatic.com/firebasejs/8.2.3/firebase-app.js"></script>
        <script defer src="https://www.gstatic.com/firebasejs/8.2.3/firebase-auth.js"></script>
        <script defer src="https://www.gstatic.com/firebasejs/8.2.3/firebase-firestore.js"></script>
        {/* <script async src="https://api.seeda.io/js/js?id=rossiboots"></script> */}

        {metatags.map(meta => {
          if (meta.name) {
            return <meta key={meta?.name} name={meta.name} content={meta.content} />
          }

          if (meta.property) {
            return <meta key={meta?.property} property={meta.property} content={meta.content} />
          }
        })}
      </Helmet>
      <Script
        async
        src={`https://d3hw6dc1ow8pp2.cloudfront.net/reviews-widget-plus/js/okendo-reviews.js?nocache=${Math.floor(Math.random() * 100)}`}
      />
      <Script defer src={`https://d3hw6dc1ow8pp2.cloudfront.net/referrals/js/referrals-api.js?nocache=${Math.floor(Math.random() * 100)}`} />
      <Script id="okeReferralSettings" type="application/json">{`{"subscriberId":"2204c2d4-ae8c-4efb-b560-abf4a5af9311"}`}</Script>
    </>
  )
)
