import tw, { styled } from "twin.macro"

export const Container = styled.div<ActiveProps>`
  ${tw`fixed z-50 inset-0 transition-all`}
  ${({ active }) => (active === "true" ? tw`visible` : tw`invisible`)}
  ${({ active }) => (active === "true" ? tw`duration-500` : ``)}
`

export const Bg = styled.div<ActiveProps>`
  ${tw`fixed bg-black/50 inset-0 cursor-pointer transition-all duration-500`}
  ${({ active }) => (active === "true" ? tw`opacity-100` : tw`opacity-0`)}
`

export const ContentWrapper = styled.div<ActiveProps>`
  ${tw`w-full transition-all absolute transform z-30 right-0 sm:max-w-37-5 md:h-full max-h-full-vh md:max-h-full md:min-h-full-vh`}
  ${({ active }) => (active === "true" ? tw`duration-500 translate-x-0` : tw`translate-x-full`)}
`
export const Content = styled.div`
  ${tw`overflow-x-hidden overflow-y-auto bg-beige-light h-full max-h-full-vh`}
`

type ActiveProps = {
  active: "true" | "false"
}
