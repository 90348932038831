import tw, { css, styled } from "twin.macro"
import { SubTitle } from "../../Styled/Text"

export const Wrapper = styled.nav`
  ${tw`flex flex-col md:mx-1-6 mb-3 md:mb-4 md:w-auto md:flex-1`}
  ${css`
    width: 133px;
  `}
`

export const Address = tw.div`
leading-1.78 mt-1 md:mt-0
`

export const Number = tw.a`leading-1.78`

export const Text = tw.p`
  text-14 leading-1.78
`

export const Contact = tw.div`
  text-14 leading-1.78 hidden md:block
`
