import React from "react"

import { withNavigationHeaderDesktopDropdownMegaHeroImages } from "./withNavigationHeaderDesktopDropdownMegaHeroImages"
import {
  Outer,
  ColumnsWrapper,
  Column,
  ImageWrapper,
  ImageContent,
  ImageLink,
  ImageText,
  ButtonWrapper,
} from "./NavigationHeaderDesktopDropdownMegaHeroImagesStyles"
import { Image } from "../../../../../Image/Image"
import { StyledButton } from "../../../../../Styled/Button"
import { Link } from "gatsby"

export const NavigationHeaderDesktopDropdownMegaHeroImages = withNavigationHeaderDesktopDropdownMegaHeroImages(({ item }) => (
  <Outer>
    <ColumnsWrapper>
      {item.items.map((column, index) => (
        <Column key={index} first={index === 0}>
          <ImageWrapper>
            <Image alt={column.image?.alt} image={column.image} link={{ url: column.linkUrl }} fullHeight style={{ height: "100%" }} />
            <ImageContent>
              {column.title && <ImageText>{column.title}</ImageText>}
              <ImageLink>{column.linkTitle ? column.linkTitle : " "}</ImageLink>
            </ImageContent>
          </ImageWrapper>
        </Column>
      ))}
    </ColumnsWrapper>

    <ButtonWrapper>
      {item.bottomLinkUrl && (
        <StyledButton as={"a"} href={item.bottomLinkUrl} colour={"red"} size={"secondary"}>
          {item.bottomLinkTitle}
        </StyledButton>
      )}
    </ButtonWrapper>
  </Outer>
))
