import React, { useState, useEffect, useCallback, useContext, useMemo } from "react"
import { navigate } from "gatsby"

import { useApp } from "./useApp"
import { useCart } from "./useCart"
import { useCore } from "./useCore"
import { useRoutes } from "./useRoutes"
import { useShopify } from "./useShopify"
import { WishlistContext } from "../providers/wishlist"
import { useQueries } from "./useQueries"

export const useWishlistContext = () => {
  const wishlistData: any = useContext(WishlistContext)
  return wishlistData
}

export const useWishlist = () => {
  const {
    helpers: { encodeBase64, decodeBase64, storage },
  } = useCore()

  const {
    queries: { GET_PRODUCTS_BY_HANDLE },
  } = useQueries()

  const {
    config: {
      app,
      settings: {
        routes,
        params,
        constraints: { productMetafields },
        keys,
      },
    },
  } = useApp()
  const { getUrlParameter } = useRoutes()
  const { wishlist, setWishlist, savedWishlist, getWishlist } = useWishlistContext()
  const { client, productNormaliser } = useShopify()
  const { addToCart, removeFromCart } = useCart()
  const [loading, setLoading] = useState(false)
  const [shareUrl, setShareUrl] = useState(null)
  const [sharedWishlist, setSharedWishlist] = useState([])

  const allowed = useMemo(() => ["id", "selectedSku", "selectedTitle", "handle", "title", "vendor", "image", "images", "tags", "metafields"], [])

  const filterData = useCallback(
    (allowed, data) =>
      Object.keys(data)
        .filter(key => allowed.includes(key))
        .reduce((obj, key) => {
          obj[key] = data[key]
          return obj
        }, {}),
    []
  )

  const formatData = useCallback(
    data => ({
      ...filterData(allowed, data),
    }),
    [filterData, allowed]
  )

  const getSharedWishlist = useCallback(async () => {
    const shared = getUrlParameter(params?.wishlist)

    const items = decodeBase64(shared)
      .split("|")
      .map(item => ({
        handle: item.split(":")[0],
        selectedSku: item.split(":")[1],
      }))

    const { data: wishlistLiveData } = await client.query({
      query: GET_PRODUCTS_BY_HANDLE(items?.map(product => product?.handle)),
      variables: {
        countryCode: storage.get(keys?.country),
        firstImages: 15,
        firstMedia: 15,
        firstVariants: 30,
        firstCollections: 1,
        metafieldIdentifiers: productMetafields,
      },
    })

    setSharedWishlist(
      items?.map(item => ({
        ...item,
        ...productNormaliser(wishlistLiveData[`product${item?.handle?.replace(/-/g, "")}`]),
      }))
    )
  }, [getUrlParameter, params?.wishlist, decodeBase64, client, GET_PRODUCTS_BY_HANDLE, storage, keys?.country, productMetafields, productNormaliser])

  const addToWishlist = useCallback(
    async data => {
      setLoading(true)
      const mappedData = formatData(data)
      setWishlist(prevState => (wishlist?.length ? [...prevState, mappedData] : [mappedData]))
      setLoading(false)
    },
    [setLoading, setWishlist, wishlist, formatData]
  )

  const deleteFromWishlist = useCallback(
    async id => {
      setLoading(true)
      setWishlist(prevState => prevState.filter(item => item?.id !== id))
      setLoading(false)
    },
    [setLoading, setWishlist]
  )

  const moveToCart = useCallback(
    async (id, variantId) => {
      setLoading(true)
      await addToCart(variantId, 1)
      await setWishlist(prevState => prevState.filter(item => item?.id !== id))
      if (wishlist?.length > 1) await getWishlist(wishlist.filter(item => item?.id !== id))
      setLoading(false)
    },
    [setLoading, setWishlist, getWishlist, addToCart, wishlist]
  )

  const moveToWishlist = useCallback(
    async (data, variantId) => {
      setLoading(true)
      const mappedData = formatData(data)
      await removeFromCart(variantId)
      await setWishlist(prevState => (wishlist?.length ? [...prevState, mappedData] : [mappedData]))
      setLoading(false)
    },
    [setLoading, setWishlist, wishlist, removeFromCart, formatData]
  )

  const existsInWishlist = useCallback(handle => wishlist?.filter(item => item?.handle === handle).length, [wishlist])

  const updateWishlist = useCallback(
    async data => {
      setLoading(true)
      const mappedData = formatData(data)
      await setWishlist(prevState => [...prevState.filter(item => item?.id !== data?.id), mappedData])
      setLoading(false)
    },
    [setLoading, setWishlist, formatData]
  )

  const shareWishlist = useCallback(() => {
    setLoading(true)
    const string = wishlist?.map(item => `${item.handle}:${item.selectedSku}`).join("|")
    const url = `${app?.url}${routes.SAVED}?id=${encodeBase64(string)}`
    setShareUrl(url)
    setLoading(false)
    return url
  }, [encodeBase64, setLoading, setShareUrl, wishlist, app?.url, routes.SAVED])

  const saveWishlist = useCallback(() => {
    setLoading(true)
    setWishlist(sharedWishlist)
    setSharedWishlist([])
    navigate(`${routes.SAVED}`, { replace: true })
    setLoading(false)
  }, [setLoading, setWishlist, setSharedWishlist, sharedWishlist, routes])

  return {
    wishlist,
    savedWishlist,
    sharedWishlist,
    getSharedWishlist,
    addToWishlist,
    getWishlist,
    deleteFromWishlist,
    updateWishlist,
    shareWishlist,
    saveWishlist,
    existsInWishlist,
    moveToCart,
    moveToWishlist,
    shareUrl,
    loading,
  }
}
