import React from "react"

import { withNewsletterForm } from "./withNewsletterForm"
import { StyledFormErrors } from "../Styled/Form"
import { CheckboxButton } from "../Checkbox/Checkbox"
import { Wrapper, Form, Success, Subtitle, Title, Description, Options, Email, Submit, Terms, Close } from "./NewsletterStyles"

export const NewsletterForm = withNewsletterForm(
  ({
    success,
    email,
    handleChange,
    handleSubmit,
    handleToggleTag,
    handleClose,
    errors,
    enabledCustomerTags,
    customerTags,
    tags,
    title,
    subtitle,
    description,
    placeholder,
    submitButtonText,
    termsText,
    terms,
    privacy,
    closeButtonText,
    successTitle,
    successSubtitle,
    successDescription,
    successCloseButtonText,
    warning,
  }) => (
    <Wrapper>
      {!success ? (
        <Form onSubmit={handleSubmit}>
          <Subtitle>{subtitle}</Subtitle>
          <Title>{title}</Title>
          <Description>{description}</Description>
          {enabledCustomerTags ? (
            <Options>
              {customerTags.map(option => (
                <CheckboxButton
                  key={option?.title}
                  active={tags.includes(option?.tag)}
                  handleChange={() => handleToggleTag(option?.tag)}
                  halfWidth
                  title={option?.title}
                >
                  {option?.title}
                </CheckboxButton>
              ))}
            </Options>
          ) : null}
          {warning ? <StyledFormErrors>{warning}</StyledFormErrors> : null}
          <Email value={email} onChange={handleChange} placeholder={placeholder} errors={errors.length > 0} spacing={"true"} />
          {errors ? <StyledFormErrors>{errors}</StyledFormErrors> : null}
          <Submit size={"tertiary"} wide disabled={warning.length > 0}>
            {submitButtonText}
          </Submit>
          <Terms>
            {termsText?.split("{privacy}")[0]}
            <a href={terms?.url} className={`underline`} target={`_blank`}>
              {privacy?.title}
            </a>
            {termsText?.split("{privacy}")[1]?.split("{terms}")[0]}
            <a href={privacy?.url} className={`underline`} target={`_blank`}>
              {terms?.title}
            </a>
            {termsText?.split("{privacy}")[1]?.split("{terms}")[1]}
          </Terms>
          <Close onClick={handleClose}>
            <span>{closeButtonText}</span>
          </Close>
        </Form>
      ) : (
        <Success>
          <Subtitle>{successSubtitle}</Subtitle>
          <Title>{successTitle}</Title>
          <Description>{successDescription}</Description>
          <Close onClick={handleClose}>
            <span>{successCloseButtonText}</span>
          </Close>
        </Success>
      )}
    </Wrapper>
  )
)
