import { graphql, useStaticQuery } from "gatsby"
import React, { createContext, useEffect } from "react"

export const PopupsContext: any = createContext({})

export const PopupsProvider = ({ children }): JSX.Element => {
  const [state, setState] = React.useState({})

  // get all sanity popups documents that are active
  // popup looks like
  // {
  //   title: "string",
  //   active: "boolean"
  //   content: "_rawContent"
  // }
  const { popups } = useStaticQuery(graphql`
    query SANITY_POPUPS {
      popups: allSanityPopup(filter: { active: { eq: true } }) {
        nodes {
          title
          active
          region
          content: _rawContent(resolveReferences: { maxDepth: 10 })
          delay
          expiration
        }
      }
    }
  `)

  return (
    <PopupsContext.Provider
      value={{
        popups: popups?.nodes || [],
        ...state,
      }}
    >
      {children}
    </PopupsContext.Provider>
  )
}

export const withPopups = Component => (props): JSX.Element => (
  <PopupsContext.Consumer>{values => <Component {...props} layout={values} />}</PopupsContext.Consumer>
)
