import React from "react"
import { useRoutes } from "../../../../hooks/useRoutes"

export const withFreeGiftCard = Component => ({ name = "FreeGiftCard", product }) => {
  const { linkResolver } = useRoutes()
  const url = linkResolver(product)?.url || ""

  Component.displayName = name

  return <Component product={product} url={url} />
}
