import tw, { styled } from "twin.macro"
import { Link } from "gatsby"

import { Icon } from "../../../../../Icon/Icon"

export const Inner = tw.div`
  max-w-xl mx-auto px-3 flex flex-row
`

export const NavigationWrapper = tw.div`
  w-1/2 flex flex-row pb-7-5 pr-6
`

export const ImageWrapper = tw.div`
  w-1/2
`

export const NavigationList = tw.div`
  w-1/2 flex flex-col justify-start
`

export const FeaturedLink = styled(Link)`
  ${tw`text-14 leading-1.78 block mt-1-2 normal-case font-normal`}
  span {
    ${tw`relative mt-1-4`}
    &:after {
      content: "";
      ${tw`absolute bg-black -bottom-0-4 left-0 w-full h-0-1`}
    }
  }
`

export const GiftCards = tw.div`
  border-beige bg-beige rounded-6 -ml-2 pl-2 pr-2-8 pt-1-6 pb-3-2 mt-2-4 mb-4-6 relative
`

export const GiftCardIcon = styled(Icon)`
  ${tw`absolute right-2-8 top-1-6`}
`

export const List = tw.div`pt-1-2`

export const ListItem = tw.div`
  font-bold text-14 leading-1.71 mb-1-2 tracking-10 uppercase
`

export const SubList = tw.div`

`

export const SubListItem = tw.div`
  font-normal text-14 leading-2 normal-case
`
