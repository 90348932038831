import React, { useEffect, createContext, useState, useContext, useCallback } from "react"

import { useCore } from "../hooks/useCore"
import { useWindowLoad } from "../hooks/useWindowLoad"

export const FirebaseContext = createContext({
  firebase: null,
  app: null,
  auth: null,
  user: null,
  providers: null,
  isInitialized: false,
  sale: { enabled: false, name: null },
})

export const useFirebaseContext = () => {
  const data: any = useContext(FirebaseContext)
  return { ...data }
}

export const FirebaseProvider = ({ children, config }) => {
  const {
    helpers: { isBrowser },
  } = useCore()

  const { waitForGlobal } = useWindowLoad()
  const [firebase, setFirebase] = useState(null)
  const [firebaseApp, setFirebaseApp] = useState(null)
  const [firebaseDB, setFirebaseDB] = useState(null)
  const [firebaseAuth, setFirebaseAuth] = useState(null)
  const [firebaseAuthProviders, setFirebaseAuthProviders] = useState({ google: undefined, facebook: undefined })
  const [firebaseAuthUser, setFirebaseAuthUser] = useState(null)
  const [isFirebaseInitialized, setIsFirebaseInitialized] = useState(false)
  const [sale, setSale] = useState({ enabled: false, name: null })

  const initFirebase = useCallback(() => {
    const firebase: any = window.firebase
    const development = process.env.NODE_ENV === "development"

    if (firebase) {
      const { apiKey, authDomain, databaseURL, projectId } = config
      const app =
        firebase.apps?.[0] ||
        firebase.initializeApp({
          apiKey,
          authDomain,
          databaseURL,
          projectId,
        })
      setFirebase(firebase)
      setFirebaseApp(app)
      setIsFirebaseInitialized(true)
    }

    if (firebase.auth) {
      const auth = firebase.auth()
      setFirebaseAuth(auth)

      const providers = {
        google: new firebase.auth.GoogleAuthProvider(),
        facebook: new firebase.auth.FacebookAuthProvider(),
      }
      setFirebaseAuthProviders(providers)

      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          setFirebaseAuthUser(user)
        } else {
          setFirebaseAuthUser(null)
        }
      })
    }

    if (firebase.firestore) {
      const db = firebase.firestore()
      setFirebaseDB(db)
      if (development) firebase.firestore().useEmulator("localhost", 8080)
    }
  }, [config])

  useEffect(() => {
    waitForGlobal("firebase", initFirebase)
  }, [initFirebase, waitForGlobal])

  useEffect(() => {
    if (firebaseDB)
      firebaseDB
        .collection("settings")
        .doc("promotion")
        .onSnapshot(doc => setSale(doc.data()))
  }, [firebaseDB])

  return isBrowser ? (
    <FirebaseContext.Provider
      value={{
        firebase,
        app: firebaseApp,
        auth: firebaseAuth,
        user: firebaseAuthUser,
        providers: firebaseAuthProviders,
        isInitialized: isFirebaseInitialized,
        sale,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  ) : (
    <>{children}</>
  )
}
