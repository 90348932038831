import React from "react"
import { useWindowSize } from "../../../hooks/useWindowSize"

export const withBrand = Component => ({ name = "Brand", layout }) => {
  const { windowSize } = useWindowSize()
  const width = {
    lg: "98",
    sm: "90",
  }

  const height = {
    lg: "55",
    sm: "51",
  }

  const breakpoint = windowSize.width <= 768 ? "sm" : "lg"

  Component.displayName = name
  return <Component layout={layout} width={width[breakpoint]} height={height[breakpoint]} />
}
