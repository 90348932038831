import React from "react"
import { Link } from "gatsby"

import { Image } from "../../../../../Image/Image"
import { withNavigationHeaderDesktopDropdownFeatured } from "./withNavigationHeaderDesktopDropdownFeatured"
import {
  Inner,
  NavigationWrapper,
  ImageWrapper,
  NavigationList,
  List,
  ListItem,
  GiftCards,
  GiftCardIcon,
  FeaturedLink,
  SubList,
  SubListItem,
} from "./NavigationHeaderDesktopDropdownFeaturedStyles"

export const NavigationHeaderDesktopDropdownFeatured = withNavigationHeaderDesktopDropdownFeatured(({ items, image, featuredLink, giftCards }) => (
  <Inner>
    <NavigationWrapper>
      <NavigationList>
        {items?.map(item => (
          <List key={item?.title}>
            <ListItem>{item?.url ? <Link to={item?.url}>{item?.title}</Link> : item?.title}</ListItem>
            {item?.items?.length > 0 ? (
              <SubList>
                {item.items.map(item => (
                  <SubListItem key={item?.title}>{item?.url ? <Link to={item?.url}>{item?.title}</Link> : item?.title}</SubListItem>
                ))}
              </SubList>
            ) : null}
          </List>
        ))}
      </NavigationList>
      <NavigationList>
        {giftCards.title ? (
          <GiftCards>
            <ListItem>{giftCards.title}</ListItem>
            <GiftCardIcon name={"giftCard"} />
            <SubListItem>{giftCards?.description}</SubListItem>
            {giftCards?.link?.url ? (
              <FeaturedLink to={giftCards?.link?.url}>
                <span>{giftCards?.link?.title}</span>
              </FeaturedLink>
            ) : null}
          </GiftCards>
        ) : null}
        {featuredLink?.url ? (
          <FeaturedLink to={featuredLink?.url}>
            <span>{featuredLink?.title}</span>
          </FeaturedLink>
        ) : null}
      </NavigationList>
    </NavigationWrapper>
    <ImageWrapper>
      <Image alt={image.alt} image={image} link={{ url: image?.url }} ratio={"17/10"} fullHeight />
    </ImageWrapper>
  </Inner>
))
