import React, { memo, useMemo } from "react"

import { useApp } from "../../../hooks/useApp"
import { useCustomerAccessToken, useCustomerContext } from "../../../hooks/useCustomer"
import { graphql, useStaticQuery } from "gatsby"

export const withAccountWidget = Component =>
  memo(({ name = "AccountWidget" }: any) => {
    const {
      config: {
        settings: { routes },
      },
    } = useApp()

    const { global } = useStaticQuery(graphql`
      query SANITY_TEMPLATE_GLOBAL_ACCOUNT_WIDGET {
        global: sanityTemplateGlobal {
          additionalJoinUsTextNotLoggedIn
          additionalJoinUsTextLoggedIn
        }
      }
    `)

    const { additionalJoinUsTextNotLoggedIn, additionalJoinUsTextLoggedIn } = global || {}

    const { customer } = useCustomerContext()

    const { getCustomer } = useCustomerAccessToken()

    if (!customer) getCustomer()

    const accountLink = routes?.DASHBOARD
    const loginLink = routes?.LOGIN

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          customer={customer}
          accountLink={accountLink}
          loginLink={loginLink}
          text={customer ? additionalJoinUsTextLoggedIn : additionalJoinUsTextNotLoggedIn}
        />
      ),
      [customer, accountLink, loginLink]
    )
  })
