import tw, { styled, css } from "twin.macro"

export const Wrapper = styled.div<Props>`
  ${tw`font-medium text-14 leading-1.2 tracking-5 uppercase`}
  ${({ layout }) => {
    if (layout === "card") return tw`-mt-1-2`
    if (layout === "product") return tw`flex-1 mb-2-4`
  }}
  ${({ colour }) => {
    if (colour === "red") {
      return tw`text-red`
    } else if (colour === "black") {
      return tw`text-black`
    } else {
      return css`
        color: ${colour};
      `
    }
  }}
`

type Props = {
  layout: "card" | "product"
  colour: string
}
