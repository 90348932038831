import React from "react"

import { Icon } from "../../Icon/Icon"
import { withBrand } from "./withBrand"
import { Wrapper } from "./BrandStyles"

export const Brand = withBrand(({ layout, width, height }) => (
  <Wrapper layout={layout}>
    <Icon name={"logo"} width={width} height={height} title={"Rossi brand logo"} />
  </Wrapper>
))
