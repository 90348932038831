import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useNewsletter } from "../../hooks/useNewsletter"
import { useImage } from "../../hooks/useImage"

export const withNewsletter = Component => ({ name = "Newsletter", path }) => {
  const { activeSubscribe, hideSubscribe } = useNewsletter(path)

  const { newsletter } = useStaticQuery(graphql`
    query SANITY_SETTINGS_NEWSLETTER_IMAGE {
      newsletter: sanitySettingNewsletter {
        featuredImage: _rawFeaturedImage(resolveReferences: { maxDepth: 3 })
      }
    }
  `)
  const { featuredImage } = newsletter || {}

  const { getFluidImage } = useImage()

  const featuredImageFluid = getFluidImage(featuredImage, { maxWidth: 450 })
  const featuredImageAlt = featuredImage?.alt

  Component.displayName = name
  return (
    <Component
      activeSubscribe={activeSubscribe}
      setActiveSubscribe={hideSubscribe}
      featuredImageFluid={featuredImageFluid}
      featuredImageAlt={featuredImageAlt}
    />
  )
}
