import React, { useCallback, useEffect } from "react"

import { useApp } from "../../../hooks/useApp"
import { useCore } from "../../../hooks/useCore"

export const withInstant = Component => ({ name = "Instant" }: any) => {
  const { globalStateReducer } = useApp()
  const {
    helpers: { isDomReady },
  } = useCore()

  const [{ activeSearch }, dispatch] = globalStateReducer

  useEffect(() => {
    if (activeSearch) {
      // defer 500ms to wait actual DOM updates.
      setTimeout(function () {
        document.querySelector(`input[aria-label="q"]`).focus()
      }, 500)
    }
  }, [activeSearch])

  const handleClose = useCallback(() => {
    dispatch({
      type: "HIDE_SEARCH",
    })
  }, [dispatch])

  Component.displayName = name
  return isDomReady ? <Component activeSearch={activeSearch} handleClose={handleClose} /> : null
}
