import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useApp } from "../../../hooks/useApp"

export const withSearchWidget = Component => ({ name = "SearchWidget" }) => {
  const { globalStateReducer } = useApp()

  const { global } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_GLOBAL_SEARCH {
      global: sanityTemplateGlobal {
        additionalSearchText
      }
    }
  `)

  const { additionalSearchText } = global || {}

  const [, dispatch] = globalStateReducer

  const handleClick = () => {
    dispatch({
      type: "SHOW_SEARCH",
    })
  }
  Component.displayName = name
  return <Component handleClick={handleClick} text={additionalSearchText} />
}
