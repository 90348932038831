import React from "react"

import { withAccountWidget } from "./withAccountWidget"
import { StyledIcon, Text, StyledLink } from "./AccountWidgetStyles"

export const AccountWidget = withAccountWidget(
  ({ customer, accountLink, loginLink, text }): JSX.Element => {
    const desktopWidth = 17
    const desktopHeight = 20
    const widthHeightRatio = desktopHeight / desktopWidth

    const mobileWidth = 20

    return (
      <StyledLink to={customer ? accountLink : loginLink} title={"My account"}>
        <StyledIcon screen="md" name={"account"} width={desktopWidth} height={desktopHeight} />
        <StyledIcon screen="sm" name={"account"} width={mobileWidth} height={mobileWidth * widthHeightRatio} />
        {text && <Text>{text}</Text>}
      </StyledLink>
    )
  }
)
