import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useSearch } from "@usereactify/search"

export const withSearchForm = Component => ({ name = "SearchForm", layout }) => {
  const { searchTerm, setSearchTerm } = useSearch()
  const { searchForm } = useStaticQuery(graphql`
    query SANITY_PAGE_SEARCH_FORM {
      searchForm: sanityPageSearch {
        additionalSearchButtonText
        additionalSearchPlaceholderText
      }
    }
  `)

  const { additionalSearchButtonText, additionalSearchPlaceholderText } = searchForm || {}

  Component.displayName = name
  return (
    <Component
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      additionalSearchButtonText={additionalSearchButtonText}
      additionalSearchPlaceholderText={additionalSearchPlaceholderText}
      layout={layout}
    />
  )
}
