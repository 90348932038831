import React from "react"
import { GlobalStyles } from "twin.macro"

import { withLayout } from "./withLayout"
import { LayoutStyles } from "./LayoutStyles"
import { Meta } from "../Meta/Meta"
import { Header } from "../Header/Header"
import { Main } from "../Main/Main"
import { Footer } from "../Footer/Footer"
import { Newsletter } from "../Newsletter/Newsletter"
import { Cookies } from "../Cookies/Cookies"
import { Instant } from "../Search/Instant/Instant"
import { Popups } from "../Popups/Popups"

export const Layout = withLayout(
  ({ data, location, children, title, routes }): JSX.Element => (
    <>
      <Meta data={data} url={location?.pathname} routes={routes} />
      <LayoutStyles />
      <GlobalStyles />
      <Header title={title} />
      <Main>{children}</Main>
      <Footer />
      <Popups />
      <Newsletter path={location?.pathname} />
      <Cookies />
      <Instant />
    </>
  )
)
