import tw, { styled } from "twin.macro"

export const Outer = styled.div`
  min-height: 520px;
  ${tw`max-w-xl mx-auto px-3 flex`}
  ${({ nested }) => (nested ? tw`px-0 flex-1` : null)}
`

export const Column = styled.div`
  ${tw`py-4-8 flex flex-col gap-2`}
  ${({ ratio }) => (ratio ? "flex: " + ratio + " 1 0;" : "flex: 1 1 0;")}
`

export const ColumnDivider = styled.div`
  ${tw`mx-2 opacity-30`}
  ${({ visible }) => (visible ? tw`border-r` : null)}
`

export const SubNavigationTitle = styled.div`
  ${tw`font-bold text-14 pb-2-4`}
`

export const Wrapper = styled.div`
  ${tw`relative overflow-hidden h-full`}
`

export const SubNavigationItem = styled.div`
  ${tw`text-14 pb-0-8 normal-case font-normal`}
  ${({ emphasis }) => (emphasis ? tw`text-red` : null)}
`

export const ImageWrapper = styled.div`
  ${tw`relative mb-2`}
  :last-child {
    ${tw`mb-0`}
  }
`

export const ColumnGrid = styled.div`
  ${({ columns }) => (columns > 1 ? tw`grid gap-2 flex-1` : tw`flex flex-col gap-2 flex-1 justify-start`)}
  ${({ columns }) => {
    if (columns === "2") {
      return tw`grid-cols-2`
    } else if (columns === "3") {
      return tw`grid-cols-3`
    } else if (columns === "4") {
      return tw`grid-cols-4`
    } else if (columns === "5") {
      return tw`grid-cols-5`
    } else if (columns === "6") {
      return tw`grid-cols-6`
    } else {
      return null
    }
  }}
`

export const BackgroundImage = styled.div`
  ${tw`bg-center bg-cover bg-no-repeat relative mb-2 h-full w-full block`}
  :last-child {
    ${tw`mb-0`}
  }
`

export const ImageText = styled.div`
  ${tw`text-13 text-white absolute top-1/2 left-1/2  transform -translate-x-1/2 -translate-y-1/2 pointer-events-none`}
`

export const CardImageTitle = styled.div`
  ${tw`text-13 text-black`}
`
export const CardImageDescription = styled.div`
  ${tw`text-11 text-black normal-case font-normal line-clamp-3 h-6`}
`
export const StackedColumnImage = styled.div`
  ${tw`h-full flex flex-col`}
`
