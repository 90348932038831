import React from "react"

import { Column, Row, Scroll, Table as Wrapper } from "./TableStyles"

export const Table = ({ chart }) =>
  chart ? (
    <Scroll>
      <Wrapper>
        {chart?.rows?.map((row, index) => (
          <Row key={`${row?.key}_${index}`}>
            {row?.cells?.map((column, index) => (
              <Column key={`${column}_${index}`}>{column || <div dangerouslySetInnerHTML={{ __html: `&nbsp;` }} />}</Column>
            ))}
          </Row>
        ))}
      </Wrapper>
    </Scroll>
  ) : null
