import React from "react"
import { Link } from "gatsby"

import { withNavigationHeaderMobileUtils } from "./withNavigationHeaderMobileUtils"
import { Outer, Inner, Title, List, ListItem } from "./NavigationHeaderMobileUtilsStyles"

export const NavigationHeaderMobileUtils = withNavigationHeaderMobileUtils(
  ({ items, accountUrl, carUrl, additionalMobileUtilsMenuText, additionalMobileUtilsAccountText, additionalMobileUtilsCartText }) => {
    const distributedItems = React.useMemo(() => {
      const half = Math.ceil(items.length / 2)
      try {
        const firstHalf = items.slice(0, half + 1)
        const secondHalf = items.slice(-(half - 1))
        return [firstHalf, secondHalf]
      } catch {
        return [items]
      }
    }, [items])

    return (
      <Outer>
        <Title>{additionalMobileUtilsMenuText}</Title>
        <Inner>
          <List>
            {distributedItems?.[0].map(item => (
              <ListItem key={item.title}>
                <Link style={{ fontSize: "16px", paddingTop: "8px", paddingBottom: "8px", display: "block", width: "100%" }} to={item.url}>
                  {item.title}
                </Link>
              </ListItem>
            ))}
          </List>
          <List>
            <ListItem>
              <Link style={{ fontSize: "16px", paddingTop: "8px", paddingBottom: "8px", display: "block", width: "100%" }} to={accountUrl}>
                {additionalMobileUtilsAccountText}
              </Link>
            </ListItem>
            <ListItem>
              <Link style={{ fontSize: "16px", paddingTop: "8px", paddingBottom: "8px", display: "block", width: "100%" }} to={carUrl}>
                {additionalMobileUtilsCartText}
              </Link>
            </ListItem>
            {distributedItems?.[1].map(item => (
              <ListItem key={item.title}>
                <Link style={{ fontSize: "16px", paddingTop: "8px", paddingBottom: "8px", display: "block", width: "100%" }} to={item.url}>
                  {item.title}
                </Link>
              </ListItem>
            ))}
          </List>
        </Inner>
      </Outer>
    )
  }
)
