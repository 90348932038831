import React from "react"

export const withNavigationHeaderDesktopDropdown = Component => ({
  name = "NavigationHeaderDesktopDropdown",
  item,
  active,
  onMouseEnter,
  onMouseLeave,
}) => {
  Component.displayName = name
  return <Component item={item} active={active} onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter} />
}
