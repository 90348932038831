import tw, { css, styled } from "twin.macro"

export const Outer = tw.div`
  w-full bg-beige-light min-h-64
`

export const Inner = styled.div`
  ${tw`max-w-67-8 mx-auto pb-4-8 md:pb-7 pt-8 md:pt-11-5 flex flex-col h-full`}
  ${css`
    .instant-search-results {
      margin: 0 1.5rem;
      grid-template-columns: repeat(1, 1fr) !important;
      gap: 1.5rem !important;

      @media only screen and (min-width: 1282px) {
        margin: 0;
        grid-template-columns: repeat(3, 1fr) !important;
        // display: flex !important;
        gap: 3rem !important;

        .group {
          flex: 1;
          // max-width: 221px;
        }
      }
    }
  `}
`

export const InstantSearchMessage = tw.div`
  px-2-4 md:px-0
`

export const ButtonWrapper = tw.div`
  flex justify-center mt-4
`
