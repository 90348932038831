import { getGatsbyImageData } from "gatsby-source-sanity"
import { useCallback } from "react"

import { useApp } from "./useApp"
import { useCore } from "./useCore"

export const useImage = () => {
  const {
    config: {
      services: { sanity },
    },
  } = useApp()
  const {
    helpers: { handleize },
  } = useCore()

  const filename = ({ alt, asset }) => `${handleize(asset?.originalFilename?.split(`.`)?.[0] || alt)}.${asset?.extension}`

  const withFilename = (fluidImage = {}, name = `image.jpg`, properties = [`src`, `srcWebp`, `srcSet`, `srcSetWebp`]) => ({
    ...fluidImage,
    ...properties?.reduce((o, key) => ({ ...o, [key]: fluidImage[key]?.split(`?`)?.join(`/${name}?`) }), {}),
  })

  const getGatsbyImage = useCallback(
    (image: any, options: any = null) =>
      image?.asset?.id
        ? {
            alt: image?.alt,
            dimensions: image?.asset?.metadata?.dimensions,
            ...withFilename(getGatsbyImageData(image, options || {}, sanity)?.images?.fallback, filename(image)),
          }
        : image,
    [filename, sanity, withFilename]
  )

  const getFluidImage = (image, options = {}) =>
    image?.asset?.id || image?.asset?._id
      ? {
          dimensions: image?.asset?.metadata?.dimensions,
          alt: image?.alt ?? "",
          ...withFilename(getGatsbyImageData(image, options, sanity), filename(image)),
        }
      : null

  const getResponsiveImage = (image, options = null) => {
    return {
      ...image,
      desktop: getGatsbyImage(image?.desktop, options?.desktop || options || {}),
      desktopAlt: image?.desktop?.alt,
      mobile: getGatsbyImage(image?.mobile ?? image?.desktop, options?.mobile || options?.desktop || options || {}),
      mobileVideo: image?.mobileVideo ?? image?.desktopVideo,
      mobileAlt: image?.mobile?.alt ?? image?.desktop?.alt,
    }
  }

  return { getFluidImage, getGatsbyImage, getResponsiveImage }
}
