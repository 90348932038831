import tw, { styled, css } from "twin.macro"

const styles = {
  colour: {
    red: tw`bg-red text-white md:hover:bg-transparent md:hover:text-red border-red`,
    "red-reverse": tw`bg-transparent text-red md:hover:bg-red md:hover:text-white border-red`,
    black: tw`bg-black text-white md:hover:bg-transparent md:hover:text-black border-black`,
    "black-no-hover": tw`bg-black text-white border-black`,
    "black-reverse": tw`bg-transparent text-black md:hover:bg-black md:hover:text-white border-black`,
    beige: tw`bg-beige text-black md:hover:bg-transparent md:hover:text-beige border-beige`,
    "beige-reverse": tw`bg-transparent text-beige md:hover:bg-beige md:hover:text-black border-beige`,
  },
  size: {
    primary: tw`py-1-6 px-5-3 `,
    primaryExtendedText: tw`py-1-6 px-1-6 `,
    secondary: tw`py-1 px-2-8`,
    account: tw`flex items-center justify-center h-48px w-full`,
    tertiary: tw`py-1-1 px-1-8`,
    filter: tw`py-1-4`,
  },
  stretch: tw`w-full h-full`,
  wide: tw`w-full`,
  state: {
    disabled: tw`pointer-events-none opacity-50`,
  },
}

export const StyledButton = styled.button<ButtonProps>`
  ${tw`select-none outline-none leading-none inline-block font-body text-center text-14 font-medium tracking-5 uppercase border-2 border-solid rounded focus:outline-none transition-all max-w-full duration-200`}
  ${({ size }) => (size && styles.size[size]) || styles.size[`primary`]}
  ${({ stretch }) => stretch && styles.stretch}
  ${({ wide }) => wide === "true" && styles.wide}
  ${({ colour }) => (colour && styles.colour[colour]) || styles.colour[`black`]}
  ${({ disabled }) => disabled && styles.state.disabled}
  ${({ buttonMargin }) => {
    return typeof buttonMargin === "string" || typeof buttonMargin === "number"
      ? css`
          margin-left: ${buttonMargin}px;
          margin-right: ${buttonMargin}px;
        `
      : css`
          margin-left: 8px;
          margin-right: 8px;
        `
  }}
  span {
    ${tw`block`}
  }
`

export const UnderlinedButton = styled.button<UnderlinedButtonProps>`
  span {
    ${tw`relative`}

    ${({ buttonMargin, noMargin }) => {
      return noMargin
        ? ``
        : buttonMargin
        ? css`
            margin-left: ${buttonMargin}px;
            margin-right: ${buttonMargin}px;
          `
        : css`
            margin-left: 8px;
            margin-right: 8px;
          `
    }}
    
    ${({ size }) => {
      switch (size) {
        case "primary":
          return tw`text-16 leading-1.44 tracking-14 uppercase font-bold`
        default:
          return tw`text-14 leading-1.71`
      }
    }}

    ${({ colour }) => {
      switch (colour) {
        case "underlinedLinkRed":
          return tw`text-red`
        case "underlinedLinkBlack":
        default:
          return tw`text-black`
      }
    }}

    &:after {
      content: "";
      ${({ colour }) => {
        switch (colour) {
          case "underlinedLinkRed":
            return tw`bg-red`
          case "underlinedLinkBlack":
          default:
            return tw`bg-black`
        }
      }}
      ${tw`absolute -bottom-0-4 left-0 w-full h-0-1`}
    }
  }
`

type ButtonProps = {
  colour?: "red" | "red-reverse" | "black" | "black-no-hover" | "black-reverse" | "beige" | "beige-reverse"
  size?: "primary" | "secondary" | "tertiary" | "filter"
  disabled?: boolean
  stretch?: boolean
  wide?: "true" | "false"
  buttonMargin?: string
}

type UnderlinedButtonProps = {
  important?: "true"
  buttonMargin?: string
}
