import React, { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useNavigation } from "../../../hooks/useNavigation"

export const withNavigationSearch = Component => ({ name = "NavigationSearch" }) => {
  const { mapItems } = useNavigation()

  const { search, menus } = useStaticQuery(graphql`
    query SANITY_PAGE_SEARCH_NAVIGATION {
      search: sanityPageSearch {
        additionalSearchQuickLinkText
      }
      menus: sanitySettingMenus {
        searchMenu: search {
          items: _rawItems(resolveReferences: { maxDepth: 8 })
        }
      }
    }
  `)

  const { additionalSearchQuickLinkText } = search || {}

  const { searchMenu } = menus || {}

  const items = useMemo(() => mapItems(searchMenu?.items), [searchMenu?.items, mapItems])

  Component.displayName = name
  return <Component items={items} additionalSearchQuickLinkText={additionalSearchQuickLinkText} />
}
