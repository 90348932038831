import React, { useContext } from "react"

import { PopupsContext } from "../../providers/popups"
import { Popup } from "../Popup/Popup"
import { PopupTimer } from "../Popup/PopupTimer"

export const Popups = () => {
  const { popups } = useContext(PopupsContext)

  return (
    <>
      {popups?.map(popup => {
        return (
          <>
            <PopupTimer popup={popup} />
          </>
        )
      })}
    </>
  )
}
