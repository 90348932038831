import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import { useSale } from "../hooks/useSale"
import { useShopify } from "../hooks/useShopify"

export const CheckoutContext: any = React.createContext({})

export const CheckoutProvider = ({ children }): JSX.Element => {
  const [freeGiftSets, setFreeGiftSets] = useState([])
  const [checkout, setCheckout] = useState(false)
  const { edgeNormaliser } = useShopify()
  const { getOnSale } = useSale()

  const [initializedLoading, setInitializedLoading] = useState(true)

  const { pageGiftcard, rawPromotions } = useStaticQuery(graphql`
    query SANITY_CHECKOUT {
      pageGiftcard: sanityPageGiftcard {
        settingGiftCardProduct {
          shopify {
            handle: shopifyHandle
          }
        }
      }
      rawPromotions: allSanityPromotion(filter: { active: { eq: true } }) {
        edges {
          node {
            title
            slug {
              current
            }
            promotionRules {
              isTheLast
              promotionTags
              rule: _rawRule(resolveReferences: { maxDepth: 10 })
              exclusions
            }
          }
        }
      }
    }
  `)

  const promotions = rawPromotions
    ? edgeNormaliser(rawPromotions).reduce(
        (result, item) =>
          item?.slug?.current
            ? {
                ...result,
                [item.slug.current]: {
                  rules: item?.promotionRules,
                },
              }
            : result,
        {}
      )
    : {}

  const saleName = getOnSale()

  console.log("saleName", saleName)
  const livePromotionRules = promotions?.[saleName]?.rules || []

  const { settingGiftCardProduct } = pageGiftcard || {}

  const values = {
    checkout,
    setCheckout,
    initializedLoading,
    setInitializedLoading,
    freeGiftSets,
    setFreeGiftSets,
    livePromotionRules,
    count: checkout?.lineItems?.reduce((acc, cur) => acc + cur.quantity, 0),
    giftcardHandle: settingGiftCardProduct?.shopify?.handle,
  }

  return <CheckoutContext.Provider value={{ ...values }}>{children}</CheckoutContext.Provider>
}
