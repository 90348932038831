import tw, { styled } from "twin.macro"

import { Icon } from "../../Icon/Icon"

export const Wrapper = tw.div`
  relative
`

export const StyledIcon = styled(Icon)`
  ${tw`ml-0 md:ml-1-6`}
`

export const Count = tw.p`
  absolute -top-0-4 -right-0-4 border rounded-full w-1-4 h-1-4 bg-coffee border-coffee text-12 font-medium flex items-center justify-center
`
