const ENCRYPTION_BASE = "abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ"
const ENCRYPTION_MAP = "QIXJ2l3RpGt56AO8TwLSEv9ynMFqWHYmja7fheDcuBdPN4k1KxZb0iVogUrCzs"
const ENCRYPTION_KEY = "P1al90AkDdE5GjtYmRuSybOB6M3UCN"
const CHECK_DIGITS_SEED = 7
const CHECK_DIGITS_LENGTH = 3

const encrypt = text => {
  let encrypted = ""
  let keyIndex = 0
  for (const char of text) {
    const keyChar = ENCRYPTION_KEY[keyIndex]
    const charBaseIndex = ENCRYPTION_BASE.indexOf(char)
    const keyBaseIndex = ENCRYPTION_BASE.indexOf(keyChar)
    encrypted += ENCRYPTION_MAP[(charBaseIndex + keyBaseIndex) % ENCRYPTION_MAP.length]
    keyIndex = (keyIndex + 1) % ENCRYPTION_KEY.length
  }
  return encrypted
}

const getCheckDigits = number => {
  let digitsSum = CHECK_DIGITS_SEED
  while (number > 0) {
    digitsSum += number % 10
    number = Math.floor(number / 10)
  }
  let keySum = 0
  for (const char of ENCRYPTION_KEY) {
    keySum += ENCRYPTION_BASE.indexOf(char)
  }
  return `${(digitsSum * keySum) % Math.pow(10, CHECK_DIGITS_LENGTH)}`.padStart(CHECK_DIGITS_LENGTH, "0")
}

const padNumber = (number, paddedLength = 30) => {
  const paddingChars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
  const checkDigits = getCheckDigits(number)
  const strInt = `${checkDigits}${number}`
  const charIndices = []
  for (let i = 0; i < strInt.length; i += 1) {
    let index = -1
    do {
      index = Math.floor(Math.random() * paddedLength)
    } while (charIndices.indexOf(index) >= 0)
    charIndices.push(index)
  }
  charIndices.sort((a, b) => a - b)
  let paddedString = ""
  while (paddedString.length < paddedLength) {
    paddedString += paddingChars[Math.floor(Math.random() * paddingChars.length)]
  }
  for (let i = 0; i < charIndices.length; i += 1) {
    paddedString = paddedString.substring(0, charIndices[i]) + strInt[i] + paddedString.substring(charIndices[i] + 1)
  }
  return paddedString
}

export const encryptNumber = number => encrypt(padNumber(number))
