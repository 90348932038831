import React from "react"

import { withWishlist } from "./withWishlist"
import { Icon } from "../../Icon/Icon"

export const Wishlist = withWishlist(
  ({ addToWishlist, deleteFromWishlist, existsInWishlist, product, selectedVariant, wishlistLoading }): JSX.Element => (
    <>
      {existsInWishlist ? (
        <button onClick={() => deleteFromWishlist(product?.storefrontId)} disabled={wishlistLoading}>
          <Icon name={"wishlistFilled"} />
        </button>
      ) : (
        <button
          onClick={() =>
            addToWishlist({
              ...product,
              selectedSku: selectedVariant?.sku,
              selectedTitle: selectedVariant?.title,
            })
          }
          disabled={wishlistLoading}
        >
          <Icon name={"wishlist"} />
        </button>
      )}
    </>
  )
)
