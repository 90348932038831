import React from "react"

import { useApp } from "../../../hooks/useApp"
import { useCheckout, useCheckoutContext } from "../../../hooks/useCheckout"

export const withCartWidget = Component => ({ name = "CartTotal" }: any) => {
  const { globalStateReducer } = useApp()

  const { createCheckout } = useCheckout()

  const { checkout } = useCheckoutContext()

  const [{ activeCart }, dispatch] = globalStateReducer

  const handleActiveCart = () => {
    dispatch({
      type: "TOGGLE_CART",
      payload: !activeCart,
    })
  }

  if (!checkout) createCheckout()

  let count = 0
  checkout?.lineItems?.map((item: any) => (item?.title.toLowerCase().includes("gift card") ? (count += 1) : (count += item?.quantity)))

  Component.displayName = name
  return <Component handleActiveCart={handleActiveCart} count={count} />
}
