import tw, { styled } from "twin.macro"

export const Clickable = styled.div<ClickAbleProps>`
  ${({ onClick }) => (onClick ? tw`cursor-pointer` : null)}
  ${({ stretch }) => (stretch === "true" ? tw`h-full` : null)}
  ${({ spacing }) => {
    if (spacing?.paddingDesktop) {
      return `padding-top: ${spacing.paddingDesktop}em; padding-bottom: ${spacing.paddingDesktop}em;`
    }
    if (spacing?.paddingMobile) {
      return `@media (max-width: 768px) {
        padding-top: ${spacing.paddingMobile}em;
        padding-bottom: ${spacing.paddingMobile}em;
      }`
    }
    return null
  }}
`

type ClickAbleProps = {
  stretch: "true" | "false"
  // spacing can be an empty object or an obejct with keys of type string and values of type number
  spacing?: { [key: string]: number }
}
