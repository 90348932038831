import React from "react"
import { Link } from "gatsby"

import { Icon } from "../../Icon/Icon"
import { withSellingPoints } from "./withSellingPoints"
import { Wrapper, Point, Title, IconWrapper, Bg, SpecialLink } from "./SellingPointsStyles"

export const SellingPoints = withSellingPoints(({ sellingPoints }) =>
  sellingPoints ? (
    <Bg>
      <Wrapper>
        {sellingPoints?.map(point => (
          <Point key={point.title} hiddenOnMobile={point?.hiddenOnMobile}>
            <IconWrapper>
              <Icon name={point?.icon} title={point?.title} />
            </IconWrapper>
            <Title shorter={point.title.includes("day") ? "true" : "false"}>
              {point?.specialAction === "liveChat" ? (
                <SpecialLink onClick={() => GorgiasChat.open()}>{point?.title}</SpecialLink>
              ) : point?.url ? (
                <Link to={point.url}>{point?.title}</Link>
              ) : (
                point?.title
              )}
            </Title>
          </Point>
        ))}
      </Wrapper>
    </Bg>
  ) : null
)
