import tw, { styled } from "twin.macro"

export const Wrapper = tw.nav`order-3 lg:order-none mt-2 lg:mt-0 w-full lg:w-auto flex justify-center lg:justify-start py-1-4 border-t border-opacity-10 border-beige-light md:border-0`

export const List = tw.ul`
  flex flex-row flex-wrap justify-center
`

export const ListItem = styled.li`
  ${tw`text-13 md:text-14 md:border-r-2 px-0-8 
  md:px-1-2 md:border-beige-light`}
  :first-of-type {
    ${tw`pl-0`}
  }
  :last-of-type {
    ${tw`border-0 pr-0`}
  }
`
export const ListItemMd = tw(ListItem)`hidden md:block`

export const Bottom = tw.div`w-full text-center pt-1 text-13 md:hidden`
