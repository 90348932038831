import tw, { styled } from "twin.macro"

export const Wrapper = styled.div<WrapperProps>`
  ${({ layout }) => {
    if (layout === "desktop") return tw`block`
    if (layout === "mobile") return tw`w-full flex justify-center absolute -top-2-8 md:hidden`
  }}
`

type WrapperProps = {
  layout: "desktop" | "mobile"
}
