import tw, { styled } from "twin.macro"
import { Link } from "gatsby"
import { Icon } from "../../Icon/Icon"

export const StyledIcon = styled(Icon)`
  ${tw`ml-1-6 md:mx-1-6`}
`
export const Text = tw.p`
  text-12 tracking-10 leading-2 hidden md:block mr-0-3
`

export const StyledLink = styled(Link)`
  ${tw`flex items-center`}
`
