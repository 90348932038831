import React, { Fragment } from "react"
import { Link } from "gatsby"

import { withNavigationHeaderMobileMain } from "./withNavigationHeaderMobileMain"
import { List, ItemWrapper, Item, SubList, SubSubList, SubListDivider, SubTitle, SubItem } from "./NavigationHeaderMobileMainStyles"
import { Accordion } from "../../../../Accordion/Accordion"

export const NavigationHeaderMobileMain = withNavigationHeaderMobileMain(({ items }) => {
  return (
    <>
      <List>
        {items.map((item, index) => (
          <ItemWrapper key={index}>
            {item.type === "sub" ? (
              <Accordion layout={"mobileMenu"} title={item.title}>
                <SubList>
                  {/* <SubListDivider /> */}
                  {item.items.map((subitem, subitemIndex) => (
                    <>
                      {subitem.type === "sub" ? (
                        <SubSubList key={subitemIndex}>
                          <SubTitle>{subitem.title}</SubTitle>
                          {subitem.items.map((subSubitem, subSubitemIndex) => (
                            <SubItem key={subSubitemIndex} emphasis={subitem.emphasizeLastItem && subSubitemIndex === subitem.items.length - 1}>
                              {subSubitem.url ? (
                                <Link style={{ display: "flex" }} to={subSubitem.url}>
                                  <span style={{ paddingLeft: "24px", paddingTop: "16px", paddingBottom: "16px" }}>{subitem.title}</span>
                                </Link>
                              ) : (
                                <span style={{ paddingLeft: "24px", paddingTop: "16px", paddingBottom: "16px" }}>{subSubitem.title}</span>
                              )}
                            </SubItem>
                          ))}
                        </SubSubList>
                      ) : (
                        <SubItem key={subitemIndex}>
                          {item.url ? (
                            <Link style={{ display: "flex" }} to={subitem.url}>
                              <span style={{ paddingLeft: "24px", paddingTop: "16px", paddingBottom: "16px" }}>{subitem.title}</span>
                            </Link>
                          ) : (
                            <span style={{ paddingLeft: "24px", paddingTop: "16px", paddingBottom: "16px" }}>{subitem.title}</span>
                          )}
                        </SubItem>
                      )}
                    </>
                  ))}
                </SubList>
              </Accordion>
            ) : (
              <Item
                style={{
                  paddingLeft: !item.url ? "24px" : "0px",
                }}
                emphasis={item.emphasizeLastItem && index === items.length - 1}
              >
                {item.url ? (
                  <Link to={item.url}>
                    <span
                      style={{
                        paddingLeft: "24px",
                      }}
                    >
                      {item.title}
                    </span>
                  </Link>
                ) : (
                  item.title
                )}
              </Item>
            )}
          </ItemWrapper>
        ))}
      </List>
    </>
  )
})
