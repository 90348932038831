import tw, { styled } from "twin.macro"

import { Icon } from "../../Icon/Icon"

export const MenuButton = styled.button<MenuButtonProps>`
  ${tw`flex flex-row items-center`}
`

export const Text = tw.p`
  text-12 font-bold tracking-10 leading-2 hidden mlg:block mr-3-2 uppercase
`

export const StyledIcon = styled(Icon)`
  ${tw`mr-0 md:mr-1-6`}
`

type MenuButtonProps = {
  active: "true" | "false"
}
