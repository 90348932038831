import React from "react"

import { withNavigationHeaderDesktopDropdownColumns } from "./withNavigationHeaderDesktopDropdownColumns"
import {
  Outer,
  Column,
  ColumnDivider,
  SubNavigationTitle,
  SubNavigationItem,
  BackgroundImage,
  ImageWrapper,
  ImageText,
  Wrapper,
  ColumnGrid,
  CardImageTitle,
  CardImageDescription,
  StackedColumnImage,
} from "./NavigationHeaderDesktopDropdownColumnsStyles"
import { Image } from "../../../../../Image/Image"
import { Link } from "gatsby"
import { Clickable } from "../../../../../Image/ImageStyles"

export const NavigationHeaderDesktopDropdownColumns = withNavigationHeaderDesktopDropdownColumns(({ item, nested }) => (
  <Outer nested={nested}>
    {item.items.map((column, index) => (
      <>
        {!nested && column.type === "columns" && <NavigationHeaderDesktopDropdownColumns item={column} nested={true} />}
        {column.type === "columnscolumn" && (
          <Column key={index} ratio={column?.columnRatio}>
            <ColumnGrid columns={column?.gridLayout || 1}>
              {column.items.map((columnItem, columnItemIndex) => {
                const imageLink = columnItem.image?.url
                return (
                  <Wrapper key={columnItemIndex}>
                    {columnItem.type === "columnscolumnlist" && (
                      <>
                        <SubNavigationTitle>
                          {columnItem?.url ? <Link to={columnItem?.url}>{columnItem?.title}</Link> : columnItem?.title}
                        </SubNavigationTitle>
                        {columnItem.items.map((listItem, listItemIndex) => (
                          <SubNavigationItem
                            key={listItemIndex}
                            emphasis={columnItem.emphasizeLastItem && listItemIndex === columnItem.items.length - 1}
                          >
                            {listItem?.url ? <Link to={listItem?.url}>{listItem?.title}</Link> : listItem?.title}
                          </SubNavigationItem>
                        ))}
                      </>
                    )}
                    {columnItem.type === "columnscolumnimage" && !columnItem.stackText && (
                      <>
                        {columnItem.aspectRatio !== "auto" ? (
                          <ImageWrapper>
                            <Image
                              alt={columnItem.image?.alt}
                              image={columnItem.image}
                              link={{ url: columnItem.image?.url }}
                              ratio={columnItem.aspectRatio}
                            />
                            {columnItem.title && <ImageText>{columnItem.title}</ImageText>}
                            {columnItem.description && <ImageText>{columnItem.description}</ImageText>}
                          </ImageWrapper>
                        ) : (
                          <BackgroundImage
                            as={columnItem.image.url ? Link : null}
                            to={columnItem.image.url}
                            style={{ backgroundImage: `url(${columnItem.image.src})`, flex: "1 1 0" }}
                          >
                            {columnItem.title && <ImageText>{columnItem.title}</ImageText>}
                          </BackgroundImage>
                        )}
                      </>
                    )}

                    {columnItem.type === "columnscolumnimage" && columnItem.stackText && (
                      <StackedColumnImage>
                        {columnItem.aspectRatio !== "auto" ? (
                          <ImageWrapper>
                            <Image
                              alt={columnItem.image?.alt}
                              image={columnItem.image}
                              link={{ url: columnItem.image?.url }}
                              ratio={columnItem.aspectRatio}
                            />
                          </ImageWrapper>
                        ) : (
                          <BackgroundImage
                            as={columnItem.image.url ? Link : null}
                            to={columnItem.image.url}
                            style={{ backgroundImage: `url(${columnItem.image.src})`, flex: "1 1 0" }}
                          ></BackgroundImage>
                        )}
                        <Clickable
                          as={imageLink?.url && (imageLink?.external ? `a` : Link)}
                          href={imageLink?.external && imageLink?.url}
                          target={imageLink?.external && `_blank`}
                          title={imageLink?.title}
                          // eslint-disable-next-line @typescript-eslint/no-empty-function
                          onClick={imageLink ? () => {} : false}
                          to={!imageLink?.external && imageLink?.url}
                        >
                          {columnItem.title && <CardImageTitle>{columnItem.title}</CardImageTitle>}
                          {columnItem.description && <CardImageDescription>{columnItem.description}</CardImageDescription>}
                        </Clickable>
                      </StackedColumnImage>
                    )}
                  </Wrapper>
                )
              })}
            </ColumnGrid>
          </Column>
        )}
        {index !== item.items.length - 1 && <ColumnDivider visible={item.separatorLine} />}
      </>
    ))}
  </Outer>
))
