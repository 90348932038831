import tw, { styled } from "twin.macro"
import { css } from "@emotion/react"

export const Outer = styled.section`
  ${tw`w-full flex flex-row items-center justify-center px-1-3 bg-black overflow-hidden transition-all duration-300 z-50 min-h-3 w-full`}
`

export const Inner = tw.div`
  h-full relative flex items-center w-full w-9/12 md:w-full
`

// add css
export const Item = styled.div<ActiveProps>`
  ${tw`absolute inset-x-1 inset-y-0 h-full flex items-center justify-center text-center transform transition-all duration-300 text-12 md:text-11 text-white md:uppercase`}
  ${({ active }) => (active === "true" ? tw`opacity-100 visible translate-y-0` : tw`opacity-0 invisible -translate-y-6`)}
  
  p, a {
    line-height: 16px;
    overflow: hidden;
  }
`

export const Close = tw.button`
  absolute right-2-4 md:right-7-5 top-0 bottom-0 px-0-4 flex items-center text-white hidden
`

export const Content = tw.p`
  block
`

type ActiveProps = {
  active: "true" | "false"
}
