import React from "react"

import { Outer, Inner } from "./ImageWithHoverStyles"
import { Image } from "../Image/Image"

export const ImageWithHover = ({ image, hoverImage, ratio, fullWidth }) => {
  return (
    <Outer
      className={"group"}
      style={{
        width: fullWidth ? "100%" : "auto",
      }}
    >
      <Image fullWidth={fullWidth} image={image} ratio={ratio} />
      {hoverImage?.src ? (
        <Inner>
          <Image image={hoverImage} ratio={ratio} />
        </Inner>
      ) : null}
    </Outer>
  )
}
