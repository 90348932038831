import React, { useState } from "react"
import { useCore } from "../hooks/useCore"
import { useApp } from "../hooks/useApp"
import { useQueries } from "../hooks/useQueries"
import { useShopify } from "../hooks/useShopify"

export const WishlistContext: any = React.createContext({})

export const WishlistProvider = ({ children }): JSX.Element => {
  const {
    helpers: { storage },
  } = useCore()

  const {
    config: {
      settings: {
        keys,
        constraints: { productMetafields },
      },
    },
  } = useApp()

  const {
    queries: { GET_PRODUCTS_BY_HANDLE },
  } = useQueries()

  const { client, productNormaliser } = useShopify()

  const [wishlist, setWishlist] = useState([])

  const timeRef = React.useRef(null)

  const getWishlist = React.useCallback(
    async items => {
      const time = Date.now()
      timeRef.current = time
      const { data: wishlistLiveData } = await client.query({
        query: GET_PRODUCTS_BY_HANDLE(items?.map(product => product?.handle)),
        variables: {
          countryCode: storage.get(keys?.country),
          firstImages: 15,
          firstMedia: 15,
          firstVariants: 30,
          firstCollections: 1,
          metafieldIdentifiers: productMetafields,
        },
      })
      if (items?.length && timeRef.current === time) {
        setWishlist(
          items
            ?.filter(item => item?.handle === wishlistLiveData[`product${item?.handle?.replace(/-/g, "")}`]?.handle)
            ?.map(item => ({
              ...item,
              ...productNormaliser(wishlistLiveData[`product${item?.handle?.replace(/-/g, "")}`]),
            }))
        )
      }
    },
    [client, GET_PRODUCTS_BY_HANDLE, storage, keys?.country, productMetafields, productNormaliser]
  )

  const savedWishlist = React.useMemo(() => {
    return storage.get(keys?.wishlist)
  }, [storage, keys?.wishlist])

  React.useEffect(() => {
    if (wishlist?.length) {
      storage.set(keys?.wishlist, wishlist || [])
    }
  }, [wishlist, storage, keys?.wishlist])

  React.useEffect(() => {
    if (savedWishlist?.length) {
      setWishlist(savedWishlist)
      getWishlist(savedWishlist)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedWishlist])

  // TODO: fix potential infinite loop issue if there's an item without options
  React.useEffect(() => {
    if (wishlist?.find(item => !item?.options)) {
      getWishlist(wishlist)
    }
  }, [wishlist, getWishlist])

  const value = React.useMemo(() => {
    return {
      wishlist,
      setWishlist,
      count: wishlist.length,
      savedWishlist,
      getWishlist,
    }
  }, [wishlist, savedWishlist, getWishlist])

  return <WishlistContext.Provider value={value}>{children}</WishlistContext.Provider>
}
