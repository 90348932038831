import tw, { styled } from "twin.macro"

const styles = {
  layout: {
    features: tw`pl-7-2 relative`,
  },
  border: {
    faq: tw`border-t border-black border-opacity-25`,
    features: tw`border-t border-black border-opacity-25`,
    filter: tw`border-t border-black border-opacity-25`,
  },
  borderBottom: {
    features: tw`border-b border-black border-opacity-25`,
    filter: tw`border-b border-black border-opacity-25`,
  },
  title: {
    mobileMenu: tw`font-bold text-20 leading-1.5 tracking-10 py-1-2 uppercase`,
    faq: tw`justify-between py-0-8 md:py-1-6`,
    features: tw`justify-between py-2-8`,
    info: tw`justify-between py-2-8`,
    filter: tw`justify-between py-2-6`,
  },
  activeTitle: {
    mobileMenu: tw`text-red`,
  },
  size: {
    faq: tw`text-14 leading-1.78`,
    features: tw`text-14 leading-1.2 font-medium uppercase tracking-5`,
    info: tw`text-14 leading-1.2 font-medium uppercase tracking-5`,
    filter: tw`text-14 leading-1.2 font-medium uppercase tracking-5`,
  },
  icon: {
    mobileMenu: tw`hidden`,
    faq: tw`w-1-2 h-1-2 md:w-1-4 md:h-1-4`,
    features: tw`w-1-2 h-1-2 md:w-1-4 md:h-1-4`,
    info: tw`w-1-2 h-1-2 md:w-1-4 md:h-1-4`,
    filter: tw`w-1-2 h-1-2`,
  },
  content: {
    info: tw`text-14 leading-1.78 pb-2-4`,
    filter: tw`pt-0-2 pb-0-8`,
  },
}

export const Wrapper = styled.div<LayoutProps>`
  ${tw`w-full`}
  ${({ layout }) => (layout ? styles.border[layout] : null)}
  ${({ layout }) => (layout ? styles.layout[layout] : null)}
  :last-of-type {
    ${({ layout }) => (layout ? styles.borderBottom[layout] : null)}
  }
`
export const TitleWrapper = styled.button<LayoutProps>`
  ${tw`w-full flex items-center focus:outline-none`}
  ${({ layout }) => (layout ? styles.title[layout] : null)}
  span {
    svg {
      ${({ layout }) => (layout ? styles.icon[layout] : null)}
    }
  }
`
export const Title = styled.h3<LayoutProps>`
  ${tw`w-4/5 text-left`}
  ${({ layout, active }) => (layout && active ? styles.activeTitle[layout] : null)}
  ${({ layout }) => (layout ? styles.size[layout] : null)}
`
export const Content = styled.div<LayoutProps>`
  ${({ layout }) => (layout ? styles.content[layout] : null)}
`

export const IconOuter = tw.div`
  relative
`

export const IconInner = styled.div<ActiveProps>`
  ${tw`absolute top-1/2 right-0 transform -translate-y-1/2`}
  ${({ active }) => (active === "true" ? tw`opacity-100 visible pointer-events-auto` : tw`opacity-0 invisible pointer-events-none`)}
`

export const PrefixIcon = tw.div`
  absolute left-0 w-4 h-7-2 flex items-center
`

export const IconWrapper = tw.div`
  w-4 max-h-4
`

type ActiveProps = {
  active: "true" | "false"
}

type LayoutProps = {
  active?: "true" | "false"
  layout: "faq" | "features" | "info"
}
