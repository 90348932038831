import React, { useMemo } from "react"

import { withHeader } from "./withHeader"
import {
  StyledHeader,
  HeaderMastheadOverlay,
  HeaderOffset,
  BrandWrapper,
  AnnouncementOffset,
  HeaderPanel,
  RightSearchWrapper,
  MobileAnnouncement,
  HeaderMastheadOverlayRight,
  FlexGrowLeft,
  FlexGrowRight,
  HeaderMastheadOverlayLeft,
  Desktop,
} from "./HeaderStyles"
import { Announcement } from "../Announcement/Announcement"
import { NavigationHeaderDesktop } from "../Navigation/Header/Desktop/NavigationHeaderDesktop"
import { NavigationHeaderMobile } from "../Navigation/Header/Mobile/NavigationHeaderMobile"
import { CartWidget } from "../Cart/Widget/CartWidget"
import { AccountWidget } from "../Account/Widget/AccountWidget"
import { WishlistWidget } from "../Wishlist/Widget/WishlistWidget"
import { SearchWidget } from "../Search/Widget/SearchWidget"
import { Menu } from "./Menu/Menu"
import { Brand } from "./Brand/Brand"
import { CartDrawer } from "../Cart/Drawer/CartDrawer"
import { StoresWidget } from "../Stores/Widget/StoresWidget"

import { useNavColour } from "../../hooks/useNavColor"

export const Header = withHeader(
  ({
    announcementItemRefs,
    headerShouldOverlap,
    handleToggleMenu,
    announcementHeight,
    isMenuOpen,
    isScrolled,
    isShowTop,
    activeBackgroundColor,
    defaultLinkColor,
    defaultHoverLinkColor,
  }) => {
    return (
      <>
        <StyledHeader
          active={isMenuOpen}
          isScrolled={isScrolled}
          activeBackgroundColor={activeBackgroundColor}
          defaultLinkColor={defaultLinkColor}
          defaultHoverLinkColor={defaultHoverLinkColor}
          headerShouldOverlap={headerShouldOverlap}
        >
          <Announcement itemRefs={announcementItemRefs} scrolled={isScrolled} height={announcementHeight} show={isShowTop} />
          <HeaderPanel className="header-panel">
            <BrandWrapper>
              <div className="hidden md:block">
                <Brand active={!isScrolled || (isScrolled && isMenuOpen) ? "true" : "false"} />
              </div>
              <NavigationHeaderDesktop
                active={!isScrolled || (isScrolled && isMenuOpen)}
                defaultLinkColor={defaultLinkColor}
                isShowTop={isShowTop}
                defaultHoverLinkColor={defaultHoverLinkColor}
              />
              <HeaderMastheadOverlay active={!isScrolled}>
                <FlexGrowLeft>
                  <HeaderMastheadOverlayLeft>
                    <Menu isMenuOpen={isMenuOpen} handleClick={handleToggleMenu} isScrolled={isScrolled} />
                    <SearchWidget />
                  </HeaderMastheadOverlayLeft>
                </FlexGrowLeft>
                <div className="block md:hidden">
                  <Brand active={!isScrolled || (isScrolled && isMenuOpen) ? "true" : "false"} />
                </div>
                <FlexGrowRight>
                  <HeaderMastheadOverlayRight>
                    <RightSearchWrapper>
                      <SearchWidget />
                    </RightSearchWrapper>
                    <div className={"hidden md:block"}>
                      <AccountWidget />
                    </div>
                    <WishlistWidget />
                    <CartWidget />
                  </HeaderMastheadOverlayRight>
                </FlexGrowRight>
              </HeaderMastheadOverlay>
            </BrandWrapper>
            {/* <MobileAnnouncement active={!isScrolled} /> */}
          </HeaderPanel>
          {isMenuOpen && <NavigationHeaderMobile active={isMenuOpen} handleToggleMenu={handleToggleMenu} />}
        </StyledHeader>
        <Desktop>
          <AnnouncementOffset style={{ height: announcementHeight }} />
          {!headerShouldOverlap && <HeaderOffset />}
        </Desktop>
        {/* {headerShouldOverlap && <HeaderOffset />} */}
        <CartDrawer />
      </>
    )
  }
)
