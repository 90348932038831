import { useCallback } from "react"

import { useApp } from "./useApp"
import { useRoutes } from "./useRoutes"
import { useImage } from "./useImage"
import { useLocation } from "./useLocation"

export const useNavigation = () => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const { linkResolver } = useRoutes()
  const { getFluidImage } = useImage()
  const { location } = useLocation()

  const mapUrl = useCallback(
    (item, type) =>
      type === "page"
        ? `${routes[item?._type?.toUpperCase().replace("PAGE", "").replace("ACCOUNT", "")] || "/"}${item?.slug?.current || ""}`
        : type === "product" || type === "collection"
        ? `${routes[type.toUpperCase()] || "/"}/${item?.shopify?.shopifyHandle || ""}`
        : type === "link"
        ? `${item}`
        : undefined,
    [routes]
  )

  const mapItems = useCallback(
    items => {
      return (
        items?.map(item => {
          const hideByMarket = item?.hideByMarket?.map(market => market?.toLowerCase())
          const currentLocation = location?.toLowerCase()
          if (hideByMarket?.includes(currentLocation)) return null

          const type = item?._type?.replace("navigation", "").toLowerCase()
          if (type === `feat`) {
            return {
              type,
              title: linkResolver(item.link).title,
              url: linkResolver(item.link).url,
              colour: item?.colour?.hex,
              hoverColour: item?.hoverColour?.hex,
              items: item?.items?.length > 0 ? mapItems(item.items) : [],
              image: {
                ...(getFluidImage(item?.image, { maxWidth: 700 }) || {}),
                url: linkResolver(item?.imageLink).url,
                alt: item?.image?.alt,
              },
              giftCards: { ...item?.giftCards, link: linkResolver(item?.giftCards?.link) },
              featuredLink: linkResolver(item?.featuredLink),
            }
          } else if (type === `sub`) {
            return {
              type,
              title: linkResolver(item.link).title || undefined,
              url: linkResolver(item.link).url || undefined,
              colour: item?.colour?.hex,
              hoverColour: item?.hoverColour?.hex,
              emphasizeLastItem: item.emphasizeLastItem,
              items: item?.items?.length > 0 ? mapItems(item.items) : [],
            }
          } else if (type === `columns`) {
            return {
              type,
              title: linkResolver(item.link).title || undefined,
              url: linkResolver(item.link).url || undefined,
              colour: item?.colour?.hex,
              hoverColour: item?.hoverColour?.hex,
              separatorLine: item.separatorLine,
              submenuBackgroundColour: item.submenuBackgroundColour,
              submenuTextColour: item.submenuTextColour,
              submenuHoverColour: item.submenuHoverColour,
              items: item?.items?.length > 0 ? mapItems(item.items) : [],
            }
          } else if (type === `columnscolumn`) {
            return {
              type,
              columnRatio: item.columnRatio,
              gridLayout: item.gridLayout,
              colour: item?.colour?.hex,
              hoverColour: item?.hoverColour?.hex,
              items: item?.items?.length > 0 ? mapItems(item.items) : [],
            }
          } else if (type === `columnscolumnlist`) {
            return {
              type,
              title: linkResolver(item.link).title || undefined,
              url: linkResolver(item.link).url || undefined,
              colour: item?.colour?.hex,
              hoverColour: item?.hoverColour?.hex,
              emphasizeLastItem: item.emphasizeLastItem,
              items: item?.items?.length > 0 ? mapItems(item.items) : [],
            }
          } else if (type === `columnscolumnimage`) {
            return {
              type,
              title: linkResolver(item.imageLink).title || undefined,
              aspectRatio: item.aspectRatio,
              description: item.description,
              stackText: item.stackText,
              image: {
                src: getFluidImage(item?.image, { maxWidth: 700 })?.images?.fallback?.src,
                url: linkResolver(item?.imageLink).url,
                alt: item?.image?.alt,
              },
            }
          } else if (type === `megaheroimages`) {
            return {
              type,
              title: linkResolver(item.link).title || undefined,
              bottomLinkTitle: linkResolver(item.bottomLink).title || undefined,
              bottomLinkUrl: linkResolver(item.bottomLink).url || undefined,
              items: item?.items?.length > 0 ? mapItems(item.items) : [],
            }
          } else if (type === `megaheroimagesimage`) {
            return {
              type,
              title: item.title,
              linkTitle: linkResolver(item.imageLink).title || undefined,
              linkUrl: linkResolver(item?.imageLink).url,
              colour: item?.colour?.hex,
              hoverColour: item?.hoverColour?.hex,
              image: {
                ...(getFluidImage(item?.image, { maxWidth: 700 }) || {}),
                alt: item?.image?.alt,
              },
            }
          }

          return {
            type,
            title: item?.title,
            url: mapUrl(item[type], type),
            colour: item?.colour?.hex,
            hoverColour: item?.hoverColour?.hex,
            items: item?.items?.length > 0 ? mapItems(item.items) : [],
          }
        }) ?? []
      )
    },
    [location, mapUrl, linkResolver, getFluidImage]
  )

  return {
    mapItems,
  }
}
