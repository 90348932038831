import { useCallback, useEffect, useState } from "react"
import { useCore } from "./useCore"

export const useKlaviyo = () => {
    const {
        helpers: { decodeShopifyId },
      } = useCore()

    const trackKlaviyoAddToCart = useCallback((variantId, quantity, normalisedData) => {
        const itemsObject:Array<any> = [];
        const itemsTitle:Array<string> = [];

        try {
            normalisedData?.lineItems.map((item) => {
                itemsObject.push({
                    "ProductID": decodeShopifyId(item.variant.product.id, "Product"),
                    "SKU": item.variant.sku ?? "",
                    "ProductName": item.title ?? "",
                    "Quantity": 1,
                    "ItemPrice": Number(item.variant.priceV2.amount?item.variant.priceV2.amount:0),
                    "RowTotal":  Number(item.variant.priceV2.amount?item.variant.priceV2.amount:0) * item.quantity,
                    "ProductURL": `/products/${item.variant.product.handle}`,
                    "ImageURL": item.variant.image.src ?? ""
                })
    
                itemsTitle.push(item.title);
            })
        }
        catch {
            console.log('error')
        }

        const addedItem = normalisedData?.lineItems.filter((item) => {
            return variantId == item.variant.id;
        })[0];


        if(addedItem.length) {
            let itemObject = {};

            try {
                itemObject = {
                    "$value": Number(normalisedData.subtotalPrice.amount),
                    "AddedItemProductName": addedItem.title ?? "",
                    "AddedItemProductID": decodeShopifyId(addedItem.variant.product.id, "Product"),
                    "AddedItemSKU": addedItem.variant.sku ?? "",
                    "AddedItemImageURL": addedItem.variant.image.src ?? "",
                    "AddedItemURL": `/products/${addedItem.variant.product.handle}`,
                    "AddedItemPrice": Number(addedItem.variant.priceV2.amount),
                    "AddedItemQuantity": 1,
                    "ItemNames": itemsTitle ?? "",
                    "CheckoutURL": normalisedData.webUrl ?? "",
                    "Items": itemsObject
                }
                window.klaviyo?.push(["track", "Added to Cart", itemObject]);
            }
            catch{
                console.log('error')
            }
        }
    },[])

    const trackKlaviyoIdentify = useCallback(email => {
        window.klaviyo?.push(['identify', {
            '$email' : `${email}`
          }]);
    },[])

    const trackKlaviyoProductView = useCallback((product) => {  
        let productObject: any = []    
        try {
            productObject = {
                "ProductName":  product.title ?? "",
                "ProductID": decodeShopifyId(product.id, "Product") ?? "",
                "Categories": product.collections.edges[0].node.title ?? "",
                "URL": `/products/${product.handle}`, 
                "Price": product.priceRange.maxVariantPrice.amount ?? ""
            }
            window.klaviyo?.push(["track", "Viewed Product", productObject]);
        }
        catch {
            console.log('error')
        }
    }, [])
    
    return {trackKlaviyoAddToCart, trackKlaviyoIdentify, trackKlaviyoProductView}
}