import React from "react"
import { graphql, useStaticQuery } from "gatsby"

export const withPaymentIcons = Component => ({ name = "PaymentIcons", layout }) => {
  const { footer } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_GLOBAL_FOOTER_PAYMENT {
      footer: sanityTemplateGlobal {
        paymentIcons {
          icon
          title
        }
      }
    }
  `)

  const { paymentIcons } = footer || {}

  Component.displayName = name
  return <Component icons={paymentIcons} layout={layout} />
}
