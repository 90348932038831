import tw, { css, styled } from "twin.macro"
import { SubTitle } from "../Styled/Text"

export const Wrapper = styled.div<WrapperProps>`
  ${({ layout }) => {
    if (layout === "desktop") return tw`hidden w-2/5 order-2 lg:order-none lg:w-auto md:flex items-center mb-0`
    if (layout === "mobile") return tw`flex w-full md:w-1/2 md:hidden items-center mb-4`
  }}
`

export const Title = styled(SubTitle)`
  ${tw`-mr-1 md:mr-2 w-1/2 md:w-auto`}
  ${css`
    width: 133px;
  `}
`

export const List = tw.ul`
  list-none flex flex-row items-center
`

export const ListItem = tw.li`

`

type WrapperProps = {
  layout: "desktop" | "mobile"
}
