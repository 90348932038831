import React, { useEffect, useState } from "react"
import { useCore } from "../../hooks/useCore"
import { useApp } from "../../hooks/useApp"
import { Popup } from "./Popup"
import { RichText } from "../RichText/RichText"
import { useLocation } from "../../hooks/useLocation"

export const PopupTimer = props => {
  const { popup } = props

  const { delay, expiration, region } = popup

  const { config } = useApp()

  const { location, initialized: locationInitialized } = useLocation()

  const {
    helpers: { storage },
  } = useCore()

  const active = popup?.active || false

  const [isOpen, setIsOpen] = useState(false)

  const [storageKey] = useState(config.settings.keys.popups + "_" + popup?.title)

  useEffect(() => {
    if (locationInitialized) {
      let timeout: any = null

      const storageVal = storage.get(storageKey)

      const validRegion = !region || region?.toLowerCase() === location?.toLowerCase() || region?.toLowerCase() === "global"

      if (active && validRegion && !isOpen && !storageVal) {
        timeout = setTimeout(() => {
          setIsOpen(true)
          storage.set(storageKey, true, expiration)
        }, (delay || 0) * 1000)
      }
      return () => {
        if (timeout) {
          clearTimeout(timeout)
        }
      }
    }
  }, [active, storageKey, locationInitialized, storage, isOpen, location, delay, expiration, region])

  return (
    <>
      <Popup active={isOpen} setActive={setIsOpen} outerClose={true} width="md">
        <div className={"flex w-full items-center justify-center p-3 md:p-6"}>
          <RichText className={"w-full"}>{popup?.content}</RichText>
        </div>
      </Popup>
    </>
  )
}
