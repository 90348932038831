import React from "react"

import { withOperationsOffice } from "./withOperationsOffice"
import { Wrapper, Address, Number, Text, Contact } from "./OperationsOfficeStyles"
import { Title } from "../../Navigation/Footer/NavigationFooterStyles"
import { Link } from "gatsby"

export const OperationsOffice = withOperationsOffice(({ address, number, postcode, state, suburb, title, contactTitle, url }) => (
  <Wrapper>
    <Title>{title}</Title>
    <Number href={`tel:${number}`}>
      <Text>{number}</Text>
    </Number>

    <Address>
      <Text>{address}</Text>
      <Text>{suburb}</Text>
      <Text>
        {state && state} {postcode}
      </Text>
    </Address>

    <Contact as={url && Link} to={url}>
      {contactTitle}
    </Contact>
  </Wrapper>
))
