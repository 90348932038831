import tw, { styled } from "twin.macro"

export const Wrapper = tw.span`
  flex flex-row items-center
`

export const PriceTag = styled.p<PriceTagProps>`
  ${({ layout }) => {
    if (layout === "product") return tw`font-bold text-20 md:text-24 tracking-2 leading-1.5`
    if (layout === "card") return tw`font-medium text-16 leading-1.2 tracking-14`
    if (layout === "cart") return tw`text-14 md:text-18 leading-1.78 md:leading-1.56`
    if (layout === "drawer") return tw`text-14 leading-1.78`
  }}
  ${({ sale }) => (sale === "true" ? tw`text-red` : null)}
  ${({ strike }) => (strike === "true" ? tw`line-through mr-0-2` : null)}
`

type PriceTagProps = {
  sale?: "true" | "false"
  strike?: "true" | "false"
  layout: "product" | "card"
}
