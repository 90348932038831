import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import Img from "gatsby-image"
import { Link } from "gatsby"

import { Clickable } from "./ImageStyles"
import { withImage } from "./withImage"

export const Image = withImage(({ image, alt, handleClick, fluid, link, fullHeight, fullWidth, aspectRatio, spacing = {}, ...props }) => (
  <Clickable
    as={link?.url && (link?.external ? `a` : Link)}
    href={link?.external && link?.url}
    onClick={handleClick}
    target={link?.external && `_blank`}
    title={link?.title}
    to={!link?.external && link?.url}
    stretch={fullHeight ? "true" : "false"}
    spacing={spacing}
  >
    {image?.src && (
      <Img
        fluid={{ ...image, aspectRatio: aspectRatio ? aspectRatio : image?.dimensions?.aspectRatio }}
        alt={alt}
        style={{
          height: fullHeight && "100%",
          width: fullWidth && "100%",
        }}
        {...props}
      />
    )}
    {image?.images && (
      <GatsbyImage
        image={{ ...image, aspectRatio: aspectRatio ? aspectRatio : image?.dimensions?.aspectRatio }}
        alt={alt}
        style={fullHeight && { height: "100%" }}
        {...props}
      />
    )}
  </Clickable>
))
