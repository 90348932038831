import React from "react"
import { Link } from "gatsby"

import { Wrapper, InnerWrapper, ImageWrapper, DetailsWrapper, Details, Title } from "../CardStyles"
import { ImageWithHover } from "../../../ImageWithHover/ImageWithHover"
import { withFreeGiftCard } from "./withFreeGiftCard"
import { FreeGiftAddToCart } from "./AddToCart/FreeGiftAddToCart"

export const FreeGiftCard = withFreeGiftCard(({ product, url }) => {
  return (
    <Wrapper>
      <InnerWrapper>
        <ImageWrapper className="group" image={product?.images?.length > 0}>
          <Link to={url} title={product?.title}>
            <ImageWithHover image={product?.images?.[0]} hoverImage={product?.images?.[1]} ratio={"15/19"} fullWidth={true} />
          </Link>
        </ImageWrapper>
        <DetailsWrapper>
          <Details>
            <Title as={Link} to={url} title={product?.title}>
              {product?.title}
            </Title>
            <div></div>
          </Details>
        </DetailsWrapper>
        <FreeGiftAddToCart product={product} />
      </InnerWrapper>
    </Wrapper>
  )
})
