import tw, { styled } from "twin.macro"
import { css } from "@emotion/react"

export const Outer = styled.div`
  ${tw`px-2-4 pt-3-2 pb-4 bg-beige-medium flex-grow`}
  ${css`
    @supports (-webkit-touch-callout: none) {
      ${tw`pb-14`}
    }
  `}
`

export const Inner = tw.div`
  flex flex-row gap-4
`

export const Title = tw.h3`
  font-bold text-16 uppercase mb-0-8
`

export const List = tw.div`
  w-1/2 flex flex-col
`

export const ListItem = tw.div`
  text-13 leading-1.53 font-normal
`
