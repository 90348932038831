import React, { useReducer, createContext } from "react"

export const NavContext: any = createContext({})

export const NavProvider = ({ children }): JSX.Element => {
  const initialState = {
    isNavFlipped: false,
    showTop: true,
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case "TOGGLE_SHOW_TOP":
        return { ...state, showTop: action.payload }
      case "TOGGLE_NAV_FLIPPED":
        return { ...state, isNavFlipped: action.payload }
    }
  }

  return <NavContext.Provider value={{ globalStateReducer: useReducer(reducer, initialState) }}>{children}</NavContext.Provider>
}

export const withNav = Component => (props): JSX.Element => (
  <NavContext.Consumer>{values => <Component {...props} layout={values} />}</NavContext.Consumer>
)
