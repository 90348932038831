import React from "react"
import { Link } from "gatsby"

import { withNavigationFooter } from "./withNavigationFooter"
import { Wrapper, List, Title, ListItem } from "./NavigationFooterStyles"
import { HeadOffice } from "../../Footer/HeadOffice/HeadOffice"
import { OperationsOffice } from "../../Footer/OperationsOffice/OperationsOffice"

export const NavigationFooter = withNavigationFooter(
  ({ items }): JSX.Element => (
    <Wrapper>
      {items?.map((item, index) => (
        <List key={index} index={index}>
          <Title>{item?.url ? <Link to={item?.url}>{item?.title}</Link> : item?.title}</Title>
          {item?.items?.map(listItem => (
            <ListItem key={listItem?.title}>
              <Link to={listItem?.url}>{listItem?.title}</Link>
            </ListItem>
          ))}
        </List>
      ))}
      <HeadOffice />
      <OperationsOffice />
    </Wrapper>
  )
)
