import tw, { styled } from "twin.macro"

import { Body } from "../Styled/Text"
import { StyledButton, UnderlinedButton } from "../Styled/Button"
import { StyledInput } from "../Styled/Form"

export const Content = tw.div`
  w-full flex flex-row
`

export const ImageWrapper = tw.div`
  hidden md:block md:w-1/2
`

export const Wrapper = tw.div`
  flex items-center justify-center w-full md:w-1/2 bg-beige
`

export const Form = tw.form`mx-4 my-4 md:mx-6`

export const Success = tw.div`pt-6 pb-4 px-4 md:px-6`

export const Subtitle = tw.h3`
  text-18 leading-1.56 mb-0-8
`

export const Title = tw.h2`
  text-25 leading-1.4 mb-2 md:mb-2-8 font-bold tracking-2 uppercase
`

export const Description = styled(Body)`
  ${tw`mb-2-4`}
`

export const Options = tw.div`
  w-full flex flex-wrap mb-3-2
`

export const Terms = tw.p`
  text-12 leading-1.5 text-grey
`

export const Email = styled(StyledInput)`
  ${tw`mb-1-6 border-b-2 border-black py-1-2 text-14`}
  ::placeholder {
    ${tw`text-black`}
  }
`

export const Submit = styled(StyledButton)`
  ${tw`mb-0-8`}
`

export const Close = styled(UnderlinedButton)`
  ${tw`mt-4-8`}
`
