import gql from "graphql-tag"

export const PRICE_FRAGMENT = gql`
  fragment PriceFragment on ProductVariant {
    priceV2 {
      amount
      currencyCode
    }
    compareAtPriceV2 {
      amount
      currencyCode
    }
  }
`

export const VARIANT_FRAGMENT_AVAILABILITY = gql`
  fragment VariantFragmentAvailability on ProductVariant {
    id
    sku
    availableForSale
    currentlyNotInStock
    title
    quantityAvailable
    ...PriceFragment
  }
  ${PRICE_FRAGMENT}
`

export const GET_PRODUCT_AVAILABILITY = gql`
  query($countryCode: CountryCode!, $handle: String!) @inContext(country: $countryCode) {
    product: productByHandle(handle: $handle) {
      tags
      variants(first: 100) {
        edges {
          node {
            ...VariantFragmentAvailability
          }
        }
      }
    }
  }
  ${VARIANT_FRAGMENT_AVAILABILITY}
`
