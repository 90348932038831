import React from "react"

import { withMenu } from "./withMenu"
import { MenuButton, Text, StyledIcon } from "./MenuStyles"
import { HeaderIconWrapper } from "../HeaderStyles"

export const Menu = withMenu(({ text, isMenuOpen, handleClick, isScrolled }) => (
  <MenuButton onClick={handleClick} active={isScrolled ? "true" : "false"}>
    <HeaderIconWrapper>
      <StyledIcon screen="md" name={isMenuOpen ? "cross" : "menu"} width={"14"} height={"14"} />
      <StyledIcon screen="sm" name={isMenuOpen ? "cross" : "menu"} width={isMenuOpen ? "14" : "17"} height={isMenuOpen ? "18" : "21"} />
      <Text>{text}</Text>
    </HeaderIconWrapper>
  </MenuButton>
))
