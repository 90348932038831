import tw, { styled } from "twin.macro"

export const Wrapper = styled.div<SpacingProps>`
  ${({ spacing }) => (spacing === "true" ? tw`py-2-4` : null)}
  ${tw`w-full relative`}
  ${({ fullHeight }) => fullHeight && tw`h-full`}
`

export const Desktop = styled.div`
  ${tw`hidden md:block`}
  ${({ fullHeight }) => fullHeight && tw`h-full`}
`

export const Mobile = tw.div`
  block md:hidden
`

type SpacingProps = {
  spacing: "true" | "false"
}
