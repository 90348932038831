import React from "react"

import { withNavigationHeaderDesktop } from "./withNavigationHeaderDesktop"
import { NavigationHeaderDesktopMain } from "./Main/NavigationHeaderDesktopMain"
import { NavigationHeaderDesktopUtils } from "./Utils/NavigationHeaderDesktopUtils"
import { StyledNavigationHeaderDesktop } from "./NavigationHeaderDesktopStyles"

export const NavigationHeaderDesktop = withNavigationHeaderDesktop(
  ({ active, defaultLinkColor, defaultHoverLinkColor }): JSX.Element => {
    return (
      <StyledNavigationHeaderDesktop as={"nav"} active={active}>
        <NavigationHeaderDesktopMain defaultLinkColor={defaultLinkColor} defaultHoverLinkColor={defaultHoverLinkColor} />
        {false && <NavigationHeaderDesktopUtils />}
      </StyledNavigationHeaderDesktop>
    )
  }
)
