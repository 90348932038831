import tw, { styled } from "twin.macro"
import { Dialog as ReachDialog, DialogOverlay as ReachDialogOverlay, DialogContent as ReachDialogContent } from "@reach/dialog"

export const Dialog = styled(ReachDialog)``

export const DialogOverlay = styled(ReachDialogOverlay)`
  ${tw`bg-black bg-opacity-50 z-50`}
  ${({ layout }) => {
    switch (layout) {
      default:
        return null
    }
  }}
`

export const DialogContent = styled(ReachDialogContent)`
  ${tw`mt-0 md:mt-1/10 max-h-full-vh overflow-y-auto relative`}
  ${({ layout }) => {
    switch (layout) {
      case "special":
        return tw`w-full md:max-w-32-5 p-2`
      case "freeGift":
        return tw`w-full md:max-w-85 p-2 md:p-4`
      case "sizeGuide":
        return tw`w-full md:max-w-63 p-2 md:p-4`
      case "newsletter-sm":
        return tw`w-full md:max-w-48-4 p-0`
      case "newsletter-lg":
        return tw`w-full md:max-w-88 p-0`
      case "backInStock":
        return tw`w-full md:max-w-32-5 p-0`
      case "productImages":
        return tw`w-full md:max-w-91 p-0 md:my-0`
      case "quickView":
        return tw`w-full max-w-79 p-4`
      case "career":
        return tw`w-full max-w-32-5 p-2`
      case "privacy":
        return tw`w-full md:max-w-63 p-2 max-h-6/10-vh`
      default:
        return null
    }
  }}
`

export const Close = styled.button`
  ${tw`absolute flex flex-row items-center text-black top-1-2 right-1-2 z-50`}
  ${({ position }) => {
    if (position === "outside") {
      return tw`md:text-white`
    }
  }}
  span:first-of-type {
    ${({ position }) => {
      if (position === "outside") {
        return tw`mr-1-2 hidden md:block`
      }
    }}
  }
`
