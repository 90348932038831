import React, { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useShopify } from "../../../../../hooks/useShopify"
import { useSale } from "../../../../../hooks/useSale"
import { useNavigation } from "../../../../../hooks/useNavigation"

export const withNavigationHeaderMobileMain = Component => ({ name = "NavigationHeaderMobileMain" }) => {
  const { mapItems } = useNavigation()
  const { getOnSale } = useSale()
  const { edgeNormaliser } = useShopify()

  const { menus, promotions: rawPromotions } = useStaticQuery(graphql`
    query SANITY_SETTINGS_MOBILE_MAIN_MENU {
      menus: sanitySettingMenus {
        mobileHeaderMain {
          items: _rawItems(resolveReferences: { maxDepth: 8 })
        }
      }
      promotions: allSanityPromotion {
        edges {
          node {
            title
            slug {
              current
            }
            promotion {
              desktopHeader: _rawDesktopHeader(resolveReferences: { maxDepth: 10 })
              mobileHeader: _rawMobileHeader(resolveReferences: { maxDepth: 10 })
              homepage: _rawHomepage(resolveReferences: { maxDepth: 10 })
            }
          }
        }
      }
    }
  `)

  const { mobileHeaderMain } = menus || {}

  const promotions = rawPromotions
    ? edgeNormaliser(rawPromotions).reduce(
        (result, item) =>
          item?.slug?.current
            ? {
                ...result,
                [item.slug.current]: { items: item?.promotion?.mobileHeader?.items },
              }
            : result,
        {}
      )
    : {}
  const saleName = getOnSale()

  const rawItems = saleName && promotions[saleName]?.items ? promotions[saleName]?.items : mobileHeaderMain?.items
  const items = useMemo(() => {
    const mappedItems = mapItems(rawItems)

    // mappedItems.push(
    //   ...[
    //     {
    //       type: "link",
    //       title: "Login",
    //       url: "/account/login",
    //     },
    //     {
    //       type: "link",
    //       title: "Register",
    //       url: "/account/register",
    //     },
    //   ]
    // )

    return mappedItems
  }, [rawItems, mapItems])

  Component.displayName = name
  return <Component items={items} />
}
