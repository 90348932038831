import tw, { styled } from "twin.macro"

export const List = styled.ul<ListProps>`
  ${tw`flex-row items-center`}
  ${({ layout }) => {
    if (layout === "desktop") return tw`hidden md:flex w-1/3 order-2 lg:order-none lg:w-auto`
    if (layout === "mobile") return tw`flex md:hidden justify-center bg-dark py-1-6 text-beige-light`
  }}
`

export const ListItem = tw.li`block mx-0-8 text-beige-light text-opacity-75 md:text-opacity-100`

type ListProps = {
  layout: "desktop" | "mobile"
}
