import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"

export const withAnnouncement = Component => ({ name = "Announcement", className, active: show }: Props) => {
  const { announcementsData } = useStaticQuery(graphql`
    query SANITY_SETTINGS_HEADER_ANNOUNCEMENTS {
      announcementsData: sanitySettingAnnouncement {
        duration
        headerAnnouncements {
          title
          link {
            link
            external
          }
        }
      }
    }
  `)
  const { duration, headerAnnouncements: items } = announcementsData || {}

  const [active, setActive] = useState(0)

  const length = items?.length - 1

  useEffect(() => {
    if (length) {
      const interval = setInterval(() => {
        setActive(active => (active === length ? 0 : active + 1))
      }, duration * 1000)
      return () => clearInterval(interval)
    }
  }, [duration, length])

  Component.displayName = name
  return <Component items={items} active={active} className={className} show={show} />
}

type Props = {
  name?: string
  className?: string
  active: any
}
