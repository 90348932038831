import gql from "graphql-tag"

import { APPLIED_GIFT_CARD_FRAGMENT } from "./appliedGiftCardFragment"
import { MAILING_ADDRESS_FRAGMENT } from "./mailingAddressFragment"
import { VARIANT_WITH_PRODUCT_FRAGMENT } from "./variantWithProductFragment"

export const CHECKOUT_FRAGMENT = gql`
  fragment CheckoutFragment on Checkout {
    id
    ready
    requiresShipping
    note
    paymentDue {
      amount
      currencyCode
    }
    webUrl
    orderStatusUrl
    taxExempt
    taxesIncluded
    currencyCode
    totalTax {
      amount
      currencyCode
    }
    lineItemsSubtotalPrice {
      amount
      currencyCode
    }
    subtotalPrice {
      amount
      currencyCode
    }
    totalPrice {
      amount
      currencyCode
    }
    completedAt
    createdAt
    updatedAt
    email
    appliedGiftCards {
      ...AppliedGiftCardFragment
    }
    shippingAddress {
      ...MailingAddressFragment
    }
    shippingLine {
      handle
      price {
        amount
        currencyCode
      }
      title
    }
    customAttributes {
      key
      value
    }
    order {
      id
      processedAt
      orderNumber
      subtotalPrice {
        amount
        currencyCode
      }
      totalShippingPrice {
        amount
        currencyCode
      }
      totalTax {
        amount
        currencyCode
      }
      totalPrice {
        amount
        currencyCode
      }
      currencyCode
      totalRefunded {
        amount
        currencyCode
      }
      customerUrl
      shippingAddress {
        ...MailingAddressFragment
      }
      lineItems(first: 250) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            title
            variant {
              ...VariantWithProductFragment
            }
            quantity
            customAttributes {
              key
              value
            }
          }
        }
      }
    }
    lineItems(first: 250) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          title
          variant {
            ...VariantWithProductFragment
          }
          quantity
          customAttributes {
            key
            value
          }
          discountAllocations {
            allocatedAmount {
              amount
              currencyCode
            }
            discountApplication {
              __typename
              allocationMethod
              value {
                __typename
                ... on PricingPercentageValue {
                  percentage
                }
                ... on MoneyV2 {
                  amount
                  currencyCode
                }
              }
              ... on ScriptDiscountApplication {
                title
              }
              ... on ManualDiscountApplication {
                title
              }
              ... on AutomaticDiscountApplication {
                title
              }
              ... on DiscountCodeApplication {
                code
              }
            }
          }
        }
      }
    }
    discountApplications(first: 10) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ... on DiscountCodeApplication {
            code
          }
          ... on ScriptDiscountApplication {
            title
          }
          allocationMethod
          targetSelection
          targetType
          value {
            ... on PricingPercentageValue {
              percentage
            }
            ... on MoneyV2 {
              amount
              currencyCode
            }
          }
        }
      }
    }
  }
  ${APPLIED_GIFT_CARD_FRAGMENT}
  ${MAILING_ADDRESS_FRAGMENT}
  ${VARIANT_WITH_PRODUCT_FRAGMENT}
`
