import React, { useState } from "react"

export const withVideo = Component => ({ name = "Video", autoplay = false, ...rest }) => {
  const [playing, setPlaying] = useState(autoplay)
  const handleVideoPlaying = () => {
    setPlaying(prev => !prev)
  }

  Component.displayName = name
  return <Component playing={playing} handleVideoPlaying={handleVideoPlaying} autoplay={autoplay} {...rest} />
}
