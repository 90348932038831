import tw, { styled, css } from "twin.macro"

export const Wrapper = styled.div<Props>`
  ${tw`text-14 leading-1.2 uppercase font-medium tracking-5`}
  ${({ layout }) => {
    if (layout === "card") return tw`absolute z-10 top-0 left-0 py-0-8 px-1-6 rounded-2 max-w-full`
    if (layout === "product") return tw`inline-block py-0-2 px-0-4 rounded mr-1-6 mb-2-4`
  }}
  ${({ colour }) => {
    if (colour === "red") {
      return tw`text-red`
    } else if (colour === "black") {
      return tw`text-black`
    } else {
      return css`
        color: ${colour};
      `
    }
  }}
`

type Props = {
  layout: "card" | "product"
  colour: string
}
