import React from "react"
import { Link } from "gatsby"

import { withCard } from "./withCard"
import { Wrapper, ImageWrapper, DetailsWrapper, Divider, Details, Wishlist, Title, PriceWrapper } from "./CardStyles"
import { ImageWithHover } from "../../ImageWithHover/ImageWithHover"
import { Price } from "../Price/Price"
import { Badge } from "../Badge/Badge"
import { Message } from "../Message/Message"
import { Wishlist as WishlistIcon } from "../Wishlist/Wishlist"
import { StarRating } from "../../OkendoReviews/StarRating/StarRating"

export const Card = withCard(({ item, images, url, title, tags, defaultVariant, layout, stackPrice, isGiftCard, productId }) => {
  return (
    <Wrapper layout={layout}>
      <ImageWrapper as={Link} to={url} title={title} image={images.length > 0 ? "true" : "false"}>
        <Badge tags={tags} layout={"card"} />
        <ImageWithHover image={images?.[0]} fullWidth={true} hoverImage={images?.[1]} ratio={"1/1"} />
      </ImageWrapper>
      <DetailsWrapper>
        <Details>
          <div></div>
          {(layout !== "suggestion" || !stackPrice) && !isGiftCard ? (
            <Wishlist>
              <WishlistIcon product={item} />
            </Wishlist>
          ) : null}
        </Details>
        <Divider />
        <Details stackPrice={stackPrice}>
          <Title as={Link} to={url} title={title} layout={layout} stackPrice={stackPrice}>
            {title}
          </Title>
          <PriceWrapper stackPrice={stackPrice}>
            {(!isGiftCard && defaultVariant) || (!isGiftCard && item?.variants?.[0]) ? (
              <Price variant={defaultVariant || item?.variants?.[0]} layout={"card"} />
            ) : null}
            {layout === "suggestion" && stackPrice ? (
              <Wishlist stackPrice={stackPrice}>
                <WishlistIcon product={item} />
              </Wishlist>
            ) : null}
          </PriceWrapper>
        </Details>
        <Message tags={tags} layout={"card"} />
        {layout !== "suggestion" && <StarRating productId={productId} />}
      </DetailsWrapper>
    </Wrapper>
  )
})
