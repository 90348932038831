import React from "react"

import { Icon } from "../Icon/Icon"
import { withSocialMedia } from "./withSocialMedia"
import { Wrapper, Title, List, ListItem } from "./SocialMediaStyles"

export const SocialMedia = withSocialMedia(({ title, items, layout }) => (
  <Wrapper layout={layout || 'desktop'}>
    <Title>{title}</Title>
    <List>
      {items?.map(item => (
        <ListItem key={item?.title}>
          <a className="test" href={item?.url} rel="noreferrer" target="_blank" title={item?.title}>
            <Icon name={item?.title?.toLowerCase()} height={"36"} width={"36"} title={item?.title} />
          </a>
        </ListItem>      
      ))}
    </List>
  </Wrapper>
))
