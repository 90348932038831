import tw, { styled } from "twin.macro"
import { css } from "@emotion/react"

export const Outer = styled.div`
  ${tw`absolute left-0 w-full flex-1 text-black`}
  top: 100%;
  height: calc(100vh - 100%);
  ${css`
    z-index: 1;
  `}
`

export const Inner = tw.div`
  flex flex-col max-w-335px overflow-y-scroll overflow-x-hidden h-full bg-white
`

export const StyledNavigationHeaderMobile = styled.nav<ActiveProps>`
  ${tw`absolute inset-0 w-full h-full flex flex-col overflow-y-scroll overflow-x-hidden mlg:hidden transform transition-all duration-300`}
  ${({ active }) => (active === "true" ? tw`pointer-events-auto visible` : tw`pointer-events-none invisible `)}
  ${css`
    background: rgba(0, 0, 0, 0.6);
  `}

`

type ActiveProps = {
  active: "true" | "false"
}
