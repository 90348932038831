import tw, { styled } from "twin.macro"

export const Wrapper = tw.div`mb-3-2`

export const Details = tw.div``

export const Detail = tw.div`
  flex flex-row justify-between mb-1-6
`

export const Text = styled.p<TextProps>`
  ${({ total }) => (total === "true" ? tw`text-20 leading-1.25 inline` : tw`text-14 leading-1.78`)}
  ${({ discount }) => (discount === "true" ? tw`text-red` : null)}
  ${({ grey }) => (grey === "true" ? tw`text-grey inline` : null)} 
`

export const Divider = tw.hr`
  mb-1-6 border-t border-black border-opacity-10
`

type TextProps = {
  total?: "true"
  discount?: "true"
  grey?: "true"
}

export const DiscountCode = styled.div`
  ${tw`inline-block relative px-0-8 bg-beige border-beige rounded-5 uppercase text-14 leading-1.78 font-medium tracking-5`}
`

export const DiscountCodeClose = styled.button`
  ${tw`flex md:hidden absolute -top-0-6 -right-0-6 w-1-6 h-1-6 md:group-hover:flex items-center justify-center bg-beige border-beige rounded-full text-black`}
`
