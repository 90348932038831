import { useApp } from "./useApp"

export const useMessage = (tags) => {
  const {
    config: {
      settings: {
        tags: { message: messageTag }
      }
    }
  } = useApp()

  const message = tags?.find(tag => tag.includes(messageTag))?.split(":")[2]
  const colour = tags?.find(tag => tag.includes(messageTag))?.split(":")[1]

  return {
    message,
    colour
  }
}