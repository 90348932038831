import React from "react"

import { useAnnouncement } from "../../hooks/useAnnouncement"
import { useApp } from "../../hooks/useApp"
import { useCore } from "../../hooks/useCore"

export const withCookies = Component => ({ name = "Cookies" }) => {
  const { cookies, hideCookies } = useAnnouncement()

  const {
    helpers: { storage },
  } = useCore()

  const {
    config: {
      settings: { keys },
    },
  } = useApp()

  const handleHide = () => hideCookies()

  const isCookiesHidden = storage.get(keys?.cookies) === "hidden"

  Component.displayName = name
  return <Component cookies={cookies} handleHide={handleHide} isCookiesHidden={isCookiesHidden} />
}
