import React from "react"
import { withFreeGiftAddToCart } from "./withFreeGiftAddToCart"

import { StyledSelectWrapper, StyledSelect, StyledSelectOption, StyledInputIconWrapper } from "../../../../Styled/Form"
import { Icon } from "../../../../Icon/Icon"
import { Variants } from "./FreeGiftAddToCartStyles"
import { StyledButton } from "../../../../Styled/Button"

export const FreeGiftAddToCart = withFreeGiftAddToCart(
  ({ addFreeGiftToCart, options, selectedOptions, activeVariant, handleVariant, SIZE, isOutOfStock, buttonText, loading }) => {
    const optionsDict = React.useMemo(() => {
      return options?.reduce((acc, option) => {
        // handle if option only has 1 option, should be pre-selected
        acc[option?.name] = option?.values?.length === 1 ? true : false
        return acc
      }, {})
    }, [options])

    const [optionsSelected, setOptionsSelected] = React.useState(optionsDict)

    const handleSelect = React.useCallback(
      (name, value) => {
        setOptionsSelected({ ...optionsSelected, [name]: value })
        handleVariant({ name, value })
      },
      [optionsSelected, handleVariant]
    )

    const allOptionsSelected = React.useMemo(() => {
      return !Object.values(optionsSelected || {})?.length || Object.values(optionsSelected || {}).every(value => value)
    }, [optionsSelected])

    return (
      <>
        <Variants>
          {options?.map((option, index) =>
            option?.values.length > 1 || option?.name === SIZE ? (
              <StyledSelectWrapper key={option?.name?.toString() + index}>
                <StyledSelect
                  layout={"cardVariant"}
                  value={option?.values?.length === 1 ? option?.values[0] : undefined}
                  onChange={({ target: { value } }) => handleSelect(option?.name, value)}
                >
                  <StyledSelectOption disabled value="Size" selected>
                    {option?.name}
                  </StyledSelectOption>
                  {option?.values?.map((value, index) => (
                    <StyledSelectOption key={value?.toString() + index} value={value}>
                      {value}
                    </StyledSelectOption>
                  ))}
                </StyledSelect>
                <StyledInputIconWrapper>
                  <Icon name={"chevron"} className={`mb-0-2 mr-0-8`} width={"12"} height={"12"} />
                </StyledInputIconWrapper>
              </StyledSelectWrapper>
            ) : null
          )}
        </Variants>
        {!allOptionsSelected ? (
          <StyledButton buttonMargin={0} wide size="primary" disabled>
            Select a Size
          </StyledButton>
        ) : (
          <StyledButton
            buttonMargin={0}
            wide
            size="primary"
            onClick={() => addFreeGiftToCart(activeVariant)}
            disabled={!activeVariant || isOutOfStock || loading}
          >
            {loading ? "Adding" : buttonText}
          </StyledButton>
        )}
      </>
    )
  }
)
