import tw, { styled } from "twin.macro"

const styles = {
  default: {
    headings: tw`font-bold uppercase`,
    text: tw`font-normal`,
  },
  align: {
    left: tw`text-left`,
    center: tw`text-center`,
    right: tw`text-right`,
    "md:right": tw`text-left md:text-right`,
  },
  colour: {
    white: tw`text-white`,
    black: tw`text-black`,
    red: tw`text-red`,
  },
  spacing: {
    default: tw`mb-1-6`,
  },
}

export const H1 = styled.h1<TextProps>`
  ${styles.default.headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-35 tracking-3 leading-1.28 md:text-70 md:tracking-2 md:leading-none`}
`
export const H2 = styled.h2<TextProps>`
  ${styles.default.headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-28 tracking-2 leading-1.2 md:text-60 md:leading-none`}
`
export const H3 = styled.h3<TextProps>`
  ${styles.default.headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-25 tracking-2 leading-1.5 md:text-50 md:tracking-3 md:leading-none`}
`
export const H4 = styled.h4<TextProps>`
  ${styles.default.headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-20 tracking-10 leading-1.5 tracking-10 md:text-35`}
`
export const H5 = styled.h5<TextProps>`
  ${styles.default.headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-16 md:text-28 tracking-10 leading-1.28`}
`
export const H6 = styled.h6<TextProps>`
  ${styles.default.headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-16 tracking-10 leading-1.25 md:text-25 md:leading-1.4`}
`
export const SubTitle = styled.h5<TextProps>`
  ${styles.default.headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-14 tracking-10 leading-1.42`}
`

export const XXLargeBody = styled.p<TextProps>`
  ${styles.default.text}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-20 leading-1.7 md:text-25 md:leading-1.4`}
`

export const XLargeBody = styled.p<TextProps>`
  ${styles.default.text}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-18 leading-1.62 md:text-20 md:leading-1.7`}
`
export const LargeBody = styled.p<TextProps>`
  ${styles.default.text}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-16 leading-1.62 md:text-18 md:leading-1.44`}
`
export const Body = styled.p<TextProps>`
  ${styles.default.text}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-16 leading-1.62`}
`
export const SmallBody = styled.p<TextProps>`
  ${styles.default.text}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-14 leading-1.71`}
`
export const TinyBody = styled.p<TextProps>`
  ${styles.default.text}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-12 leading-1.33 tracking-5`}
`
export const RichTextContent = styled.div<TextProps>`
  ${styles.default.text}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-14 leading-none`}
  > *, > div > * {
    ${tw`pb-1-6 last:pb-0`}
  }
`

type TextProps = {
  align?: "left" | "center" | "right" | "md:right"
  colour?: "white" | "black" | "red"
  spacing?: "default"
}
