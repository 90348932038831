import tw, { styled } from "twin.macro"
import { css } from "@emotion/react"

export const Label = styled.div<LabelProps>`
  ${tw`flex items-center relative cursor-pointer select-none`}
  ${({ halfWidth }) => (halfWidth ? tw`w-1/2` : null)}
`

export const StyledCheckbox = tw.input`absolute cursor-pointer opacity-0 h-0 w-0`

export const Marker = styled.span<MarkerProps>`
  ${tw`relative inset-0 h-1-6 w-1-6 mr-1 bg-transparent border border-black rounded-2 transition-all duration-200`}
  &:after {
    content: "";
    ${tw`absolute top-0-2 left-0-5 w-0-4 h-0-8 border border-white transition-all duration-200`}
    border-width: 0 2px 2px 0;
    transform: rotate(45deg) scale(0);
  }
  ${({ active }) =>
    active &&
    css`
      ${tw`bg-black`}
      &:after {
        transform: rotate(45deg) scale(1);
      }
    `}
`

type LabelProps = {
  halfWidth?: boolean
}

type MarkerProps = {
  active: boolean
}
