import tw, { styled } from "twin.macro"
import { css } from "@emotion/react"

export const Outer = tw.div`
  bg-beige  px-2-4 md:px-3-2 pt-2-8 pb-2-4 rounded-5 mb-1-6 md:mb-3-2
`

export const Inner = tw.div`
  flex flex-row
`

export const Input = styled.input`
  ${tw`bg-transparent flex-1 border-b pb-0-8`}
  ${css`
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      ${tw`m-0`}
      -webkit-appearance: none;
    }
    &[type="number"] {
      -moz-appearance: textfield;
    }
    &::placeholder {
      ${tw`text-brown opacity-100 uppercase text-16 md:text-12 tracking-10`}
    }
  `}
`

export const Apply = tw.button`
  border px-1-2 md:w-11 rounded ml-0-8 md:ml-1-2 text-16 md:text-14 leading-1.2 py-0-6 md:hover:bg-black md:hover:text-white uppercase transition-all duration-200
`

export const Error = tw.div`
  text-12 uppercase mt-1-6 text-red
`
