import React from "react"
import { graphql, useStaticQuery } from "gatsby"

export const withMenu = Component => ({ name = "Menu", isMenuOpen, handleClick, isScrolled }) => {
  const { global } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_GLOBAL_MENU {
      global: sanityTemplateGlobal {
        additionalMenuText
      }
    }
  `)

  const { additionalMenuText } = global || {}

  Component.displayName = name
  return <Component text={additionalMenuText} isMenuOpen={isMenuOpen} handleClick={handleClick} isScrolled={isScrolled} />
}
