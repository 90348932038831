import React from "react"

import { withPrice } from "./withPrice"
import { Wrapper, PriceTag } from "./PriceStyles"

export const Price = withPrice(({ isOnSale, price, compareAtPrice, layout, isFreeGift, staticPrice }) => {
  return (
    <Wrapper>
      {isOnSale && !isFreeGift ? (
        <>
          <PriceTag strike={"true"} layout={layout}>
            ${compareAtPrice}
          </PriceTag>
          &nbsp;
        </>
      ) : null}

      {isFreeGift ? (
        <PriceTag sale={"false"} layout={layout}>
          $0.00
        </PriceTag>
      ) : price ? (
        <PriceTag sale={isOnSale ? "true" : "false"} layout={layout}>
          ${price}
        </PriceTag>
      ) : staticPrice ? (
        <PriceTag layout={layout}>$0.00</PriceTag>
      ) : null}
    </Wrapper>
  )
})
