import React from "react"
import { withImageVideo } from "./withImageVideo"
import { Wrapper, Desktop, Mobile } from "./ImageVideoStyles"
import { Image } from "../Image/Image"
import { Video } from "../Video/Video"
import { RichText } from "../RichText/RichText"

export const ImageVideo = withImageVideo(
  ({
    link,
    desktop,
    desktopAlt,
    desktopVideo,
    desktopVideoFallback,
    mobile,
    mobileAlt,
    mobileVideo,
    mobileVideoFallback,
    ratio,
    spacing,
    fullHeight,
    autoplay = false,
    controls = false,
    responsiveImage,
    imageVideo,
  }) => {
    return (
      <Wrapper spacing={spacing} fullHeight={fullHeight}>
        <Desktop fullHeight={fullHeight}>
          {imageVideo?.imageContent?.length ? (
            <div className="visually-hidden">
              <RichText>{imageVideo?.imageContent}</RichText>
            </div>
          ) : null}
          {desktopVideo ? (
            <Video
              url={[desktopVideo, desktopVideoFallback]}
              image={desktop}
              ratio={ratio ? ratio : desktop?.aspectRatio ? desktop?.aspectRatio : null}
              alt={desktopAlt}
              autoplay={autoplay}
              controls={controls}
            />
          ) : (
            <Image image={desktop} ratio={ratio} alt={desktopAlt} link={link} fullHeight={fullHeight} spacing={spacing} />
          )}
        </Desktop>
        <Mobile>
          {mobileVideo ? (
            <Video
              url={[mobileVideo, mobileVideoFallback]}
              image={mobile}
              ratio={ratio ? ratio : mobile?.aspectRatio ? mobile?.aspectRatio : null}
              alt={mobileAlt}
              autoplay={autoplay}
              controls={controls}
            />
          ) : (
            <Image image={mobile} ratio={ratio} alt={mobileAlt} link={link} spacing={spacing} />
          )}
        </Mobile>
      </Wrapper>
    )
  }
)
