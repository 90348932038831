import tw from "twin.macro"

export const Wrapper = tw.div`px-2-4 md:px-0`

export const List = tw.div`
  grid grid-cols-2 md:grid-cols-3 gap-y-3-2
`

export const SubList = tw.ul`
  
`

export const ListItem = tw.li`
  text-16 leading-1.87
`

export const Title = tw.h2`
  font-medium text-16 leading-1.2 tracking-14 uppercase mb-3-2
`
