import React from "react"

import { withNavigationHeaderMobile } from "./withNavigationHeaderMobile"
import { Outer, Inner, StyledNavigationHeaderMobile } from "./NavigationHeaderMobileStyles"
import { NavigationHeaderMobileMain } from "./Main/NavigationHeaderMobileMain"
import { NavigationHeaderMobileUtils } from "./Utils/NavigationHeaderMobileUtils"
import { AccountButtons } from "./Main/NavigationHeaderMobileMainStyles"
import { StyledButton } from "../../../Styled/Button"
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock"

export const NavigationHeaderMobile = withNavigationHeaderMobile(({ active, handleToggleMenu }) => {
  const innerRef = React.useRef(null)
  const styledNavRef = React.useRef(null)

  React.useEffect(() => {
    if (active) {
      disableBodyScroll(innerRef.current)
      // set right: 0px on body
      document.body.style.right = "0px"
    } else {
      enableBodyScroll(innerRef.current)
      clearAllBodyScrollLocks()
      // delete body style right
      document.body.style.right = ""
    }

    return () => {
      clearAllBodyScrollLocks()
      document.body.style.right = ""
    }
  }, [active, innerRef])

  const closeMobileNavOnClick = e => {
    if (e.target === styledNavRef.current) {
      handleToggleMenu()
    }
  }

  return (
    <Outer>
      <StyledNavigationHeaderMobile ref={styledNavRef} active={active ? "true" : "false"} onClick={closeMobileNavOnClick}>
        <Inner ref={innerRef}>
          <NavigationHeaderMobileMain />
          <AccountButtons>
            <StyledButton style={{ marginRight: "16px" }} as="a" buttonMargin={0} href="/account/login" size={"account"} colour={"black-reverse"}>
              Log In
            </StyledButton>
            <StyledButton as="a" buttonMargin={0} href="/account/register" size={"account"}>
              Sign Up
            </StyledButton>
          </AccountButtons>
          <NavigationHeaderMobileUtils />
        </Inner>
      </StyledNavigationHeaderMobile>
    </Outer>
  )
})
