import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useFunctions } from "../../hooks/useFunctions"
import { useRoutes } from "../../hooks/useRoutes"

export const withNewsletterForm = Component => ({ name = "NewsletterForm", setActiveSubscribe }) => {
  const { newsletterData } = useStaticQuery(graphql`
    query SANITY_SETTINGS_NEWSLETTER {
      newsletterData: sanitySettingNewsletter {
        enabledCustomerTags
        customerTags: _rawCustomerTags(resolveReferences: { maxDepth: 2 })
        additionalTitle
        additionalSubtitle
        additionalDescription
        additionalPlaceholder
        additionalSubmitButtonText
        additionalTermsText
        additionalTerms: _rawAdditionalTerms(resolveReferences: { maxDepth: 2 })
        additionalPrivacy: _rawAdditionalPrivacy(resolveReferences: { maxDepth: 2 })
        additionalCloseButtonText
        additionalSuccessTitle
        additionalSuccessSubtitle
        additionalSuccessDescription
        additionalSuccessCloseButtonText
      }
    }
  `)

  const {
    enabledCustomerTags,
    customerTags,
    additionalTitle: title,
    additionalSubtitle: subtitle,
    additionalDescription: description,
    additionalPlaceholder: placeholder,
    additionalSubmitButtonText: submitButtonText,
    additionalTermsText: termsText,
    additionalTerms,
    additionalPrivacy,
    additionalCloseButtonText: closeButtonText,
    additionalSuccessTitle: successTitle,
    additionalSuccessSubtitle: successSubtitle,
    additionalSuccessDescription: successDescription,
    additionalSuccessCloseButtonText: successCloseButtonText,
  } = newsletterData || {}

  const { customerSubscribe, errors } = useFunctions()

  const { linkResolver } = useRoutes()

  const [success, setSuccess] = useState(false)
  const [email, setEmail] = useState("")
  const [tags, setTags] = useState(customerTags.map(tag => tag.tag).slice(0, 1))
  const [warning, setWarning] = useState("")

  useEffect(() => {
    if (enabledCustomerTags && tags.length < 1) {
      setWarning("Please select at least one option")
    } else if (warning.length > 0) {
      setWarning("")
    }
  }, [tags, setWarning, warning, enabledCustomerTags])

  const handleSubmit = async event => {
    event.preventDefault()
    const { customer } = await customerSubscribe(email, tags)
    if (customer?.id) {
      setSuccess(true)
    }
  }

  const handleChange = ({ target: { value } }) => {
    setEmail(value)
  }

  const handleToggleTag = tag => {
    const index = tags.indexOf(tag)
    index === -1 ? setTags([...tags, tag]) : setTags(tags.filter(a => a !== tag))
  }

  const handleClose = () => {
    setActiveSubscribe()
  }

  const terms = linkResolver(additionalTerms)
  const privacy = linkResolver(additionalPrivacy)

  Component.displayName = name
  return (
    <Component
      success={success}
      email={email}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      handleToggleTag={handleToggleTag}
      handleClose={handleClose}
      errors={errors}
      enabledCustomerTags={enabledCustomerTags}
      customerTags={customerTags}
      tags={tags}
      title={title}
      subtitle={subtitle}
      description={description}
      placeholder={placeholder}
      submitButtonText={submitButtonText}
      termsText={termsText}
      terms={terms}
      privacy={privacy}
      closeButtonText={closeButtonText}
      successTitle={successTitle}
      successSubtitle={successSubtitle}
      successDescription={successDescription}
      successCloseButtonText={successCloseButtonText}
      warning={warning}
    />
  )
}
