import React from "react"
import { createPortal } from "react-dom"

import { Icon } from "../Icon/Icon"
import { Outer, Overlay, Inner, Close, Content } from "./PopupStyles"

export const Popup = (props: PopupProps) => {
  const [isClient, setIsClient] = React.useState(false)

  const { active, setActive, onClose, children, width, mobileAttachTop, outerClose, attachTop, transparent } = props

  React.useEffect(() => {
    setIsClient(true)
  }, [])

  const handleClick = () => {
    setActive && setActive(false)
    if (onClose) {
      onClose()
    }
  }

  // lock the scroll when active, unlock when inactive
  React.useEffect(() => {
    if (active) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }

    return () => {
      document.body.style.overflow = "auto"
    }
  }, [active])

  return isClient ? (
    createPortal(
      <Outer active={active} mobileAttachTop={mobileAttachTop} attachTop={attachTop}>
        <Overlay onClick={handleClick} />
        {outerClose ? (
          <Close outerClose={outerClose ? "true" : "false"} onClick={handleClick} title={`close`}>
            <Icon name={`cross`} width={19} height={19} />
          </Close>
        ) : null}
        <Inner active={active} width={width}>
          {!outerClose ? (
            <Close outerClose={outerClose ? "true" : "false"} onClick={handleClick} title={`close`}>
              <Icon name={`cross`} />
            </Close>
          ) : null}
          <Content transparent={transparent ? "true" : "false"}>{children}</Content>
        </Inner>
      </Outer>,
      document.body
    )
  ) : (
    <></>
  )
}

type PopupProps = {
  name?: string
  active: boolean
  setActive?: any
  onClose?: any
  children: JSX.Element
  width: "sm" | "md" | "lg" | "xl" | "full" | "product"
  mobileAttachTop?: boolean
  outerClose?: boolean
  attachTop?: boolean
  transparent?: boolean
}
