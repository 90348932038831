import React from "react"

import { useImage } from "../../hooks/useImage"
import { useRoutes } from "../../hooks/useRoutes"

export const withImageVideo = Component => ({ name = "ImageVideo", imageVideo, ratio = undefined, spacing = "false", fullHeight = false }) => {
  const { linkResolver } = useRoutes()
  const { getResponsiveImage } = useImage()
  const responsiveImage = getResponsiveImage(imageVideo)

  const { desktop, desktopAlt, desktopVideo, desktopVideoFallback, mobile, mobileAlt, mobileVideo, mobileVideoFallback } = responsiveImage

  const { link: originalLink } = imageVideo ? imageVideo : {}
  const link = linkResolver(originalLink)

  Component.displayName = name
  return (
    <Component
      imageVideo={imageVideo}
      responsiveImage={responsiveImage}
      link={link}
      desktop={desktop}
      desktopAlt={desktopAlt}
      desktopVideo={desktopVideo}
      desktopVideoFallback={desktopVideoFallback}
      mobile={mobile}
      mobileAlt={mobileAlt}
      mobileVideo={mobileVideo}
      mobileVideoFallback={mobileVideoFallback}
      ratio={ratio}
      spacing={spacing}
      fullHeight={fullHeight}
      autoplay={imageVideo?.autoplay}
      controls={imageVideo?.controls}
    />
  )
}
