import * as React from "react"
export const SKidmanLogo = props => (
  <svg className="skidman_logo" width={100} height={80} id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384.38 157.12" {...props}>
    <defs>
      <style>{"\n      .cls-1{fill:#ed1c24;}.cls-1,.cls-2{stroke-width:0px;}.cls-2{fill:#00934d;}\n    "}</style>
    </defs>
    <circle className="cls-1" cx={76.65} cy={92.4} r={9.23} transform="translate(-19.18 20.08) rotate(-13.28)" />
    <path
      className="cls-1"
      d="M56.78,66.42c-4.06-3.89-12.96-9.55-16.6-11.81l-.2-.12c-4.09-2.55-7.31-4.57-7.31-9.61,0-4.18,2.2-6.12,6.92-6.12,2.25,0,3.91.58,5.08,1.77,2.26,2.29,2.38,6.55,2.32,10.35v.56s14.23,0,14.23,0l-.02-1.3c-.08-7.56-.24-23.28-21.72-23.28-14.33,0-21.91,6.72-21.91,19.44,0,3.92,1.28,7.93,3.61,11.31,3.76,4.87,9.06,8.27,14.18,11.57,2.48,1.6,5.05,3.25,7.4,5.05,3.59,2.46,5.2,5.23,5.2,8.99,0,5.28-2.58,7.84-7.9,7.84-2.4,0-4.27-.69-5.58-2.05-1.78-1.86-2.59-5.02-2.41-9.42v-4.75h-14.7v5.8c0,14.8,7.64,22.31,22.69,22.31s22.99-7.91,22.99-21.16c0-6.71-1.76-11.02-6.29-15.38Z"
    />
    <path
      className="cls-1"
      d="M141.74,28.58h-16.64l-8.12,14.88c-2.5,4.5-5.53,9.97-7.7,15.06v-29.94h-14.7v72.66h14.7v-20.91l4.13-6.7,12.54,27.61h16.57l-19.08-41.35,18.31-31.31Z"
    />
    <rect className="cls-1" x={145.25} y={46.32} width={13.74} height={54.92} />
    <rect className="cls-1" x={145.25} y={28.58} width={13.74} height={12.37} />
    <path
      className="cls-1"
      d="M190.59,47.54c0,.88.03,1.7.09,2.49-2.42-3.67-5.69-5.24-10.62-5.14-9.5.22-13.48,5.91-13.75,19.64v19.48c.43,12.56,4.93,18.66,13.79,18.66h0c4.41-.25,7.93-1.39,10.48-5.78v4.35h13.74V28.58h-13.74v18.97ZM190.59,66.89v16.15c0,5.96-1.87,8.99-5.56,8.99-3.02,0-4.65-2.86-4.98-8.7v-17.89c.05-.67.08-1.33.11-1.97.25-5.05.61-7.91,5.06-7.91,1.36,0,2.43.43,3.28,1.31,1.62,1.69,2.32,5.07,2.09,10.04Z"
    />
    <path
      className="cls-1"
      d="M262.12,44.89c-5.93,0-9.95,2-12.55,6.26-1.58-4.71-7.8-6.36-11.94-6.26-3.91.18-8.65,1.03-11.66,5.72v-4.29h-13.73v54.92h13.73v-35.95c0-6.56.56-9.67,5.67-9.75,1.87-.02,3.2.37,4.07,1.23,1.46,1.43,1.58,4.08,1.58,6.89v37.57h13.73v-35.95c0-6.26.91-9.49,5.88-9.75,1.59-.08,2.77.27,3.61,1.07,1.68,1.6,1.82,4.83,1.82,7.73v36.9h13.74v-44.35c0-5.97-4.31-12-13.96-12Z"
    />
    <path
      className="cls-1"
      d="M320.24,93.14v-31.75c.11-4.02-.21-8.58-3.43-11.9-3.01-3.1-7.98-4.6-15.18-4.6s-12.5,1.54-15.64,4.72c-2.82,2.84-4.1,7.04-4.04,13.21v.55h13.16v-.57c-.07-3.05.35-4.8,1.38-5.86.92-.94,2.45-1.4,4.66-1.4,3.73,0,4.94.52,5.38,2.34.97,3.96-.57,6.14-5.88,8.32l-1.36.55c-9.51,3.82-17.73,7.12-17.73,19.24,0,11.38,4.84,16.68,15.22,16.68,2.16,0,4.29-.64,6.3-1.89,1.53-.9,2.95-2.23,4.32-4.04,0,1.63.3,2.99.92,4.2l.15.3h13.01l-.19-.7c-.71-2.58-1.05-5-1.05-7.39ZM306.51,78.14c-.05.83-.05,1.75-.06,2.73-.03,6.32-.48,11.15-5.8,11.15-3.81,0-5.37-1.83-5.37-6.32,0-2.08.22-4.64,1.93-6.05,2.19-1.77,4.58-3.28,6.9-4.74.82-.52,1.62-1.02,2.39-1.52v4.75Z"
    />
    <path
      className="cls-1"
      d="M362.61,48.58c-2.32-2.41-5.71-3.69-9.82-3.69-2.9,0-7.94.78-11,5.67v-4.24h-13.73v54.92h13.73v-35.4c-.13-3.82.59-6.62,2.08-8.1.89-.89,2.1-1.3,3.56-1.25,4.09.17,4.81,2.81,4.81,8.32v36.42h13.73v-42.03c.18-4.54-.95-8.11-3.36-10.62Z"
    />
    <path
      className="cls-1"
      d="M123.47,132.6c2.24,1.95,4.45,3.99,6.62,6.09l14.07-7.5c-3.45-3.5-7.03-6.87-10.66-10.05-7.61-6.66-11.1-8.66-13.74-9.84-4.87-2.17-10.25-3.39-15.56-3.53-7.78-.2-13.54,1.91-16.3,2.93-3.09,1.14-6.54,3.11-12.79,6.7-3.15,1.81-5,2.93-6.36,3.75-1.79,1.08-2.34,1.41-3.83,2.1l-.12.06c-3.76,1.73-6.24,2.88-9.75,3.17-1.24.1-4.53.21-8.42-.94-2.65-.79-4.32-1.76-7.55-3.72-3.29-2-5.18-3.51-8.02-5.8-1.76-1.41-3.76-3.02-6.51-5.08-.44-.33-.89-.66-1.33-.98l-6.96,13.8c2.14,1.62,3.79,2.95,5.27,4.14,2.96,2.37,5.51,4.42,9.65,6.94,3.32,2.02,6.46,3.92,11.12,5.3,6.12,1.82,11.39,1.73,13.99,1.52,6.21-.51,10.62-2.55,14.9-4.52l.12-.06c2.26-1.04,3.34-1.7,5.32-2.89,1.3-.78,3.07-1.86,6.07-3.58,5.16-2.96,8.57-4.92,10.45-5.61,2.16-.79,5.79-2.13,10.67-2,2.32.06,5.89.49,9.75,2.21.82.36,2.98,1.33,9.92,7.4Z"
    />
    <path className="cls-2" d="M0,0v133.67h12.42v-5.16h-7.25V5.16h374.04v123.34h-39.64c-.83,1.87-1.96,3.57-3.26,5.16h48.07V0H0Z" />
    <polygon className="cls-2" points="151.88 133.67 202.71 133.67 203.33 128.51 151.88 128.51 151.88 133.67" />
    <path
      className="cls-2"
      d="M219.92,130.76c4.06-.38,3.53-3.34,4.65-3.45.39-.04.42.26.42.58,0,.49-.65,6.03-.75,7.02-.03.33-.26.38-.43.4-1.11.1.13-3.58-4.22-3.17l-2.52.24c-.59.06-1.05.33-1.28.48-.66.45-1.02,6.22-1.02,6.91,0,2.33.66,2.66,2.69,2.47,8.05-.75,9.17-5.25,10.11-5.34.2-.02.39.16.39.72,0,1.21-1.67,5.3-4.12,5.53-3.63.34-7.07.53-9.89.79-3.01.28-4.16.52-5.63.66-.46.04-.62-.07-.62-.27,0-1.51,2.46-.43,2.81-3.47l1.7-14.18c.2-1.49.3-2.26.3-2.78,0-2.42-3.18-1.01-3.18-2.16,0-.43.65-.49.75-.5,2.09-.2,4.16-.26,6.25-.45,7.4-.69,10.74-1.14,11.46-1.2.4-.04.69-.03.69.3v4.09c0,.59-.1.86-.42.89-.95.09-.66-1.94-1.41-2.55-.52-.41-.92-1.09-7.43-.48-.56.05-1.73.1-2.16.53-.79.8-1.21,6.47-1.21,7.29,0,.59-.03,1.51.62,1.45l3.44-.32Z"
    />
    <path
      className="cls-2"
      d="M231.71,132.09c0-2.72,2.88-4.14,5.17-4.35,1.77-.17,3.73.47,3.73,1.88,0,1.05-.59,1.43-1.51,1.52-1.54.14-1.14-2.48-3.11-2.3-.16.02-1.47.17-1.47,1.68,0,2.1,5.43,2.8,5.43,6.86,0,3.01-2.78,4.75-5.5,5-3.53.33-4.61-1.34-4.61-2.32,0-1.15,1.01-1.57,1.67-1.63,2.13-.2.62,3.25,3.37,2.99,1.34-.13,2.23-1.09,2.23-2.44,0-2.95-5.4-3.26-5.4-6.9Z"
    />
    <path
      className="cls-2"
      d="M248.7,128.79c-.36,2.56-1.08,6.55-1.08,7.7,0,.82.36,1.48,1.28,1.39,1.21-.11,1.83-2.1,2.55-2.17.33-.03.42.12.42.42,0,.75-1.96,4.8-5.24,5.11-1.18.11-2.65-.31-2.65-3.13,0-2.1,1.11-8.43,1.11-8.82,0-.16-.2-.15-1.61-.01-.26.02-.46-.15-.39-.49,0-.39,2.62-2.6,3.37-3.49,1.01-1.18,1.9-2.64,2.52-2.69.26-.02.59.04.59.34,0,.23-.1.56-.62,4.15l2.98-.28c.66-.06.66.2.66.79,0,.49,0,.82-.66.88l-3.24.3Z"
    />
    <path
      className="cls-2"
      d="M255.19,138.14c-.23-.21-.49-.38-.49-.58,0-.29.69-.75,2.49-2.72.26-.29.56-.51.78-.53.4-.04.53.31,2.46,2.06.23.21.49.38.49.57,0,.3-.69.75-2.49,2.72-.26.29-.56.51-.79.53-.39.04-.52-.31-2.46-2.06Z"
    />
    <path
      className="cls-2"
      d="M270.71,123.39c.1-.8.13-1.29.13-1.78,0-1.21-.82-1.3-1.44-1.24-.88.08-1.34.32-1.71.35-.26.02-.56-.14-.56-.44,0-.59.59-.87,1.05-.98,5.47-1.33,5.21-3.11,6.74-3.25.2-.02.39.13.39.49,0,.29-.13,1.19-.39,3.44l-1.28,11.19c-.13,1.26-.36,3.18-.36,3.57,0,3.74,3.89,1.24,3.89,2.75,0,.36-.16.67-.82.73-1.05.1-3.63.21-4.81.32-2.03.19-3.96.5-4.84.58-.76.07-.79-.09-.79-.45,0-1.64,3.01.08,3.4-3.17l1.37-12.12Z"
    />
    <path
      className="cls-2"
      d="M279.94,132.52c0-3.21,1.67-4.88,4.49-6.28-1.6-1.03-2.46-2.59-2.46-4.62,0-4.16,3.76-6.58,7.43-6.92,2.78-.26,6.25.69,6.25,4.07,0,2.85-2.09,4.29-4.45,5.36,2.52,1.14,4.12,2.56,4.12,5.71,0,2.36-1.51,6.99-8.12,7.61-6.42.6-7.27-3.48-7.27-4.92ZM292.51,132.06c0-4.13-6.68-5.17-6.94-5.15-2,.19-2.65,3.49-2.65,4.93,0,2.88,2.03,4.63,4.78,4.37,2.23-.21,4.81-1.53,4.81-4.15ZM289.83,123.47c1.77-.17,3.14-2.19,3.14-3.87,0-2.62-1.54-4.02-4.06-3.78-2,.19-4.06,1.59-4.06,3.85s3.01,3.98,4.97,3.79Z"
    />
    <path
      className="cls-2"
      d="M297.85,138.01c0-.29.2-.51.59-.58,8.35-1.73,10.41-7.53,10.71-9.1-1.7,1.11-2.75,1.63-4.72,1.82-3.44.32-5.92-2.16-5.92-5.64,0-4.81,3.3-8.76,8.12-9.21,3.31-.31,8.12,1.17,8.12,7.82,0,9.11-7.82,14.69-16,15.45-.56.05-.89-.25-.89-.57ZM310.75,122.81c0-2.65-1.21-6.7-4.45-6.4-2.98.28-3.8,3.37-3.8,5.83,0,2.62.92,6.07,4.09,5.78,2.75-.26,4.16-2.65,4.16-5.2Z"
    />
    <path
      className="cls-2"
      d="M316.06,134.08c0-.29.19-.51.59-.58,8.35-1.73,10.41-7.53,10.7-9.09-1.7,1.11-2.75,1.63-4.72,1.82-3.44.32-5.92-2.16-5.92-5.64,0-4.81,3.3-8.76,8.12-9.21,3.31-.31,8.12,1.17,8.12,7.82,0,9.11-7.82,14.69-16.01,15.45-.56.05-.88-.25-.88-.57ZM328.96,118.89c0-2.65-1.21-6.7-4.45-6.4-2.98.28-3.8,3.37-3.8,5.83,0,2.62.92,6.07,4.09,5.78,2.75-.26,4.16-2.65,4.16-5.2Z"
    />
    <path className="cls-2" d="M327.92,146.82s-51.64-6.13-82.5,10.3c0,0,34.46-11.06,82.5-10.3Z" />
  </svg>
)
