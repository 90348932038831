import React from "react"
import { withSearchWidget } from "./withSearchWidget"

import { SearchButton, Text, StyledIcon } from "./SearchWidgetStyles"
import { HeaderIconWrapper } from "../../Header/HeaderStyles"

export const SearchWidget = withSearchWidget(({ handleClick, text }) => {
  const desktopWidth = 21
  const desktopHeight = 19
  const widthHeightRatio = desktopHeight / desktopWidth

  const mobileWidth = 20

  return (
    <SearchButton title={"search"} onClick={handleClick}>
      <HeaderIconWrapper>
        <StyledIcon screen="md" name={"search"} width={desktopWidth} height={desktopHeight} />
        <StyledIcon screen="sm" width={mobileWidth} height={mobileWidth * widthHeightRatio} name={"search"} />
        {text && <Text>{text}</Text>}
      </HeaderIconWrapper>
    </SearchButton>
  )
})
