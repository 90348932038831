import React from "react"

import { withFreeGiftPicker } from "./withFreeGiftPicker"
import { Wrapper, CardList, CardWrapper } from "./FreeGiftPickerStyles"
import { StyledButton } from "../Styled/Button"
import { Modal, ModalOpenButton, ModalContentsWithLayout } from "../Modal/Modal"
import { FreeGiftCard } from "../Product/Card/FreeGift/FreeGiftCard"
import { RichText } from "../RichText/RichText"

export const FreeGiftPicker = withFreeGiftPicker(({ items, description }) => {
  return (
    <Modal>
      {items?.length > 0 && (
        <Wrapper>
          <ModalOpenButton>
            <StyledButton wide="true" buttonMargin={0} size="primaryExtendedText">
              <span>Select your gift here</span>
            </StyledButton>
          </ModalOpenButton>
        </Wrapper>
      )}
      <ModalContentsWithLayout closeOnLinkClick={true} layout={"freeGift"}>
        <RichText>{description}</RichText>
        <CardList>
          {typeof items?.map === "function" &&
            items.map(product => {
              return (
                <CardWrapper key={product.id}>
                  <FreeGiftCard product={product} />
                </CardWrapper>
              )
            })}
        </CardList>
      </ModalContentsWithLayout>
    </Modal>
  )
})
