import React, { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useNavigation } from "../../../../../hooks/useNavigation"
import { useApp } from "../../../../../hooks/useApp"
import { useCustomerContext } from "../../../../../hooks/useCustomer"

export const withNavigationHeaderMobileUtils = Component => ({ name = "NavigationHeaderMobileUtils" }) => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const { customer } = useCustomerContext()

  const { mapItems } = useNavigation()

  const { global, menus } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_GLOBAL_MOBILE_UTILS {
      global: sanityTemplateGlobal {
        additionalMobileUtilsMenuText
        additionalMobileUtilsAccountText
        additionalMobileUtilsCartText
      }
      menus: sanitySettingMenus {
        mobileHeaderUtils {
          items: _rawItems(resolveReferences: { maxDepth: 8 })
        }
      }
    }
  `)

  const { mobileHeaderUtils } = menus || {}

  const items = useMemo(() => mapItems(mobileHeaderUtils?.items), [mobileHeaderUtils?.items, mapItems])

  const { additionalMobileUtilsMenuText, additionalMobileUtilsAccountText, additionalMobileUtilsCartText } = global || {}

  Component.displayName = name
  return (
    <Component
      items={items}
      accountUrl={customer ? routes.DASHBOARD : routes.LOGIN}
      carUrl={routes.CART}
      additionalMobileUtilsMenuText={additionalMobileUtilsMenuText}
      additionalMobileUtilsAccountText={additionalMobileUtilsAccountText}
      additionalMobileUtilsCartText={additionalMobileUtilsCartText}
    />
  )
}
