import tw, { styled } from "twin.macro"
import { css } from "@emotion/react"

export const List = tw.ul`
  pt-1-2 pb-2-2 flex flex-col
`

export const ItemWrapper = styled.li`
  ${tw`w-full`}
  ${css`
    font-size: 16px !important;
    * {
      font-size: 16px !important;
    }
    button {
      padding-left: 24px !important;
    }
  `}
`

export const Item = styled.div`
  ${tw`font-bold text-16 w-full leading-1.5 tracking-10 uppercase`}
  ${({ emphasis }) => (emphasis ? tw`text-red` : null)}
  ${css`
    a {
      width: 100%;
      display: block;
      padding-top: 12px;
      padding-bottom: 12px;
    }
  `}
`

export const SubList = tw.ul`
  mb-0-8 w-full
`

export const SubListDivider = styled.ul`
  ${tw`border-t mt-1`}
`

export const SubSubList = styled.ul`
  ${tw`w-full`}
`

export const SubTitle = tw.li`
  font-bold text-16 tracking-10 mt-1-6 mb-2-4 uppercase
  w-full
`

export const SubItem = styled.li`
  ${tw`font-normal text-16 w-full`}
  ${({ emphasis }) => (emphasis ? tw`text-red` : null)}
`

export const AccountButtons = styled.div`
  ${tw`flex w-full py-3-2 px-2-4 flex-row bg-beige-nav`}
`
