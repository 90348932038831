import React, { useState, useContext, createContext, cloneElement } from "react"

import { Dialog, DialogOverlay, DialogContent, Close } from "./ModalStyles"
import { Icon } from "../Icon/Icon"

const callAll = (...fns) => (...args) => fns.forEach(fn => fn && fn(...args))

const ModalContext = createContext(null)

function Modal(props) {
  const [isOpen, setIsOpen] = useState(false)

  return <ModalContext.Provider value={[isOpen, setIsOpen]} {...props} />
}

function ModalExternal({ isOpen, setIsOpen, ...props }) {
  return <ModalContext.Provider value={[isOpen, setIsOpen]} {...props} />
}

function ModalDismissButton({ children: child }) {
  const [, setIsOpen] = useContext(ModalContext)
  return cloneElement(child, {
    onClick: callAll(() => setIsOpen(false), child.props.onClick),
  })
}

function ModalOpenButton({ disabled = false, children: child }) {
  const [, setIsOpen] = useContext(ModalContext)
  return cloneElement(child, {
    onClick: callAll(() => setIsOpen(!disabled), child.props.onClick),
  })
}

function ModalContents({ layout, ...props }) {
  const [isOpen, setIsOpen] = useContext(ModalContext)
  return <Dialog layout={layout} isOpen={isOpen} onDismiss={() => setIsOpen(false)} {...props} />
}

function ModalContentsWithLayout({ children, layout, closeOnLinkClick, closeButtonPosition = "inside", ...props }) {
  const [isOpen, setIsOpen] = useContext(ModalContext)

  React.useEffect(() => {
    // listen for link clicks, and if link click within modal, and is an internal link, close modal
    // if link click outside modal, do nothing

    const clickHandler = e => {
      // if a element
      // if not _blank
      // if not external
      // if starts with / and not _blank

      // Start with the target element
      let element = e.target

      // Search up the DOM tree for the closest `a` tag
      while (element && element.nodeName.toLowerCase() !== "a") {
        element = element.parentElement
      }

      const href = element?.getAttribute("href")

      if (
        element?.tagName === "A" &&
        ((element?.target !== "_blank" && !href?.includes("http") && !href?.includes("https")) ||
          (href?.startsWith("/") && element?.target !== "_blank"))
      ) {
        setIsOpen(false)
      }
    }

    if (closeOnLinkClick) {
      document.addEventListener("click", clickHandler)
      return () => {
        document.removeEventListener("click", clickHandler)
      }
    }
  }, [closeOnLinkClick, setIsOpen])

  return (
    <DialogOverlay layout={layout} isOpen={isOpen} onDismiss={() => setIsOpen(false)} {...props}>
      {closeButtonPosition === "outside" && (
        <ModalDismissButton>
          <Close position={closeButtonPosition}>
            <span>Close</span>
            <Icon name={"cross"} />
          </Close>
        </ModalDismissButton>
      )}
      <DialogContent layout={layout}>
        {closeButtonPosition === "inside" && (
          <ModalDismissButton>
            <Close position={closeButtonPosition}>
              <Icon name={"cross"} />
            </Close>
          </ModalDismissButton>
        )}
        {children}
      </DialogContent>
    </DialogOverlay>
  )
}

export { Modal, ModalExternal, ModalDismissButton, ModalOpenButton, ModalContents, ModalContentsWithLayout }
