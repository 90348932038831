import React from "react"

import { withMessage } from "./withMessage"
import { Wrapper } from "./MessageStyles"

export const Message = withMessage(({
  message,
  colour,
  layout,
}) => (
    message ? <Wrapper colour={colour} layout={layout}>
      {message}
    </Wrapper> : null
  ))