import React, { useCallback } from "react"
import { useCore } from "../../../hooks/useCore"
import { useResults, useSearch } from "@usereactify/search"
import { graphql, useStaticQuery } from "gatsby"
import { useApp } from "../../../hooks/useApp"

export const withInstantInner = Component => ({ name = "Instant" }: any) => {
  const { searchTerm } = useSearch()
  const { resultStats } = useResults()
  const {
    helpers: { isDomReady },
  } = useCore()
  const { globalStateReducer } = useApp()
  const [_, dispatch] = globalStateReducer
  const { searchForm } = useStaticQuery(graphql`
    query SANITY_PAGE_SEARCH_NO_RESULTS {
      searchForm: sanityPageSearch {
        additionalViewAllResultsText
        noResults: _rawNoResults(resolveReferences: { maxDepth: 2 })
      }
    }
  `)

  const handleClose = useCallback(() => {
    dispatch({
      type: "HIDE_SEARCH",
    })
  }, [dispatch])

  const { additionalViewAllResultsText, noResults } = searchForm || {}

  Component.displayName = name
  return isDomReady ? (
    <Component
      additionalViewAllResultsText={additionalViewAllResultsText}
      numberOfResults={resultStats?.numberOfResults}
      searchTerm={searchTerm}
      handleClose={handleClose}
    />
  ) : null
}
