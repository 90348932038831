exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-account-activate-tsx": () => import("./../../../src/pages/account/activate.tsx" /* webpackChunkName: "component---src-pages-account-activate-tsx" */),
  "component---src-pages-account-addresses-tsx": () => import("./../../../src/pages/account/addresses.tsx" /* webpackChunkName: "component---src-pages-account-addresses-tsx" */),
  "component---src-pages-account-forgot-tsx": () => import("./../../../src/pages/account/forgot.tsx" /* webpackChunkName: "component---src-pages-account-forgot-tsx" */),
  "component---src-pages-account-invalid-token-tsx": () => import("./../../../src/pages/account/invalid_token.tsx" /* webpackChunkName: "component---src-pages-account-invalid-token-tsx" */),
  "component---src-pages-account-login-auth-tsx": () => import("./../../../src/pages/account/login/auth.tsx" /* webpackChunkName: "component---src-pages-account-login-auth-tsx" */),
  "component---src-pages-account-login-tsx": () => import("./../../../src/pages/account/login.tsx" /* webpackChunkName: "component---src-pages-account-login-tsx" */),
  "component---src-pages-account-orders-tsx": () => import("./../../../src/pages/account/orders.tsx" /* webpackChunkName: "component---src-pages-account-orders-tsx" */),
  "component---src-pages-account-register-tsx": () => import("./../../../src/pages/account/register.tsx" /* webpackChunkName: "component---src-pages-account-register-tsx" */),
  "component---src-pages-account-reset-tsx": () => import("./../../../src/pages/account/reset.tsx" /* webpackChunkName: "component---src-pages-account-reset-tsx" */),
  "component---src-pages-account-returns-tsx": () => import("./../../../src/pages/account/returns.tsx" /* webpackChunkName: "component---src-pages-account-returns-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-business-tsx": () => import("./../../../src/pages/business.tsx" /* webpackChunkName: "component---src-pages-business-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-contact-b-2-b-tsx": () => import("./../../../src/pages/contact-b2b.tsx" /* webpackChunkName: "component---src-pages-contact-b-2-b-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-giftcard-tsx": () => import("./../../../src/pages/giftcard.tsx" /* webpackChunkName: "component---src-pages-giftcard-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-password-tsx": () => import("./../../../src/pages/password.tsx" /* webpackChunkName: "component---src-pages-password-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-sizeguide-tsx": () => import("./../../../src/pages/sizeguide.tsx" /* webpackChunkName: "component---src-pages-sizeguide-tsx" */),
  "component---src-pages-stockist-locator-tsx": () => import("./../../../src/pages/stockist-locator.tsx" /* webpackChunkName: "component---src-pages-stockist-locator-tsx" */),
  "component---src-pages-styleguide-tsx": () => import("./../../../src/pages/styleguide.tsx" /* webpackChunkName: "component---src-pages-styleguide-tsx" */),
  "component---src-pages-wishlist-tsx": () => import("./../../../src/pages/wishlist.tsx" /* webpackChunkName: "component---src-pages-wishlist-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-collection-tsx": () => import("./../../../src/templates/collection.tsx" /* webpackChunkName: "component---src-templates-collection-tsx" */),
  "component---src-templates-flexible-tsx": () => import("./../../../src/templates/flexible.tsx" /* webpackChunkName: "component---src-templates-flexible-tsx" */),
  "component---src-templates-generic-tsx": () => import("./../../../src/templates/generic.tsx" /* webpackChunkName: "component---src-templates-generic-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-store-tsx": () => import("./../../../src/templates/store.tsx" /* webpackChunkName: "component---src-templates-store-tsx" */)
}

