import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useCheckout, useCheckoutContext } from "../../../../hooks/useCheckout"
import { useShopify } from "../../../../hooks/useShopify"

export const withSummary = Component => ({ name = "Summary" }) => {
  const { checkout } = useCheckoutContext()

  const { removeDiscountCode } = useCheckout()

  const { formatMoney } = useShopify()

  const { cart } = useStaticQuery(graphql`
    query SANITY_PAGE_CART_SUMMARY {
      cart: sanityPageCart {
        #freeShippingThreshold,
        additionalSubtotalText
        additionalDiscountText
        additionalShippingText
        additionalShippingPlaceholderText
        additionalTotalText
      }
    }
  `)

  const {
    // freeShippingThreshold,
    additionalSubtotalText,
    additionalDiscountText,
    additionalShippingText,
    additionalShippingPlaceholderText,
    additionalTotalText,
  } = cart || {}

  const amountDue = formatMoney(checkout?.paymentDue?.amount)

  const subTotal = formatMoney(checkout?.lineItemsSubtotalPrice?.amount)

  // const isFreeShipping = freeShippingThreshold - checkout?.paymentDueV2?.amount <= 0

  const shippingText = checkout?.shippingLine?.price?.amount
    ? checkout?.shippingLine?.price?.amount !== "0.0"
      ? formatMoney(checkout?.shippingLine?.price?.amount)
      : additionalShippingPlaceholderText
    : additionalShippingPlaceholderText

  // const discountApplied = parseFloat(checkout?.lineItemsSubtotalPrice?.amount) - parseFloat(checkout?.subtotalPriceV2?.amount)

  // const formattedDiscountApplied = formatMoney(parseFloat(checkout?.lineItemsSubtotalPrice?.amount) - parseFloat(checkout?.subtotalPriceV2?.amount))

  const discountApplied = React.useMemo(() => {
    const lineItemsSubtotalPrice = parseFloat(checkout?.lineItemsSubtotalPriceV2?.amount || checkout?.lineItemsSubtotalPrice?.amount)
    const subtotalPrice = parseFloat(checkout?.subtotalPriceV2?.amount || checkout?.subtotalPrice?.amount)
    return lineItemsSubtotalPrice - subtotalPrice
  }, [checkout])

  const formattedDiscountApplied = React.useMemo(() => {
    return formatMoney(discountApplied)
  }, [discountApplied, formatMoney])

  const appliedDiscountCode = checkout?.discountApplications?.[0]?.code

  const handleDiscountCodeRemove = () => removeDiscountCode()

  const currencyCode = checkout?.currencyCode

  Component.displayName = name
  return (
    <Component
      amountDue={amountDue}
      subTotal={subTotal}
      shippingText={shippingText}
      discountApplied={discountApplied}
      formattedDiscountApplied={formattedDiscountApplied}
      appliedDiscountCode={appliedDiscountCode}
      handleDiscountCodeRemove={handleDiscountCodeRemove}
      currencyCode={currencyCode}
      additionalSubtotalText={additionalSubtotalText}
      additionalDiscountText={additionalDiscountText}
      additionalShippingText={additionalShippingText}
      additionalShippingPlaceholderText={additionalShippingPlaceholderText}
      additionalTotalText={additionalTotalText}
    />
  )
}
