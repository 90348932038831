import React from "react"

import { withCartWidget } from "./withCartWidget"
import { Wrapper, Count, StyledIcon } from "./CartWidgetStyles"
import { HeaderIconInner, HeaderIconWrapper } from "../../Header/HeaderStyles"

export const CartWidget = withCartWidget(
  ({ handleActiveCart, count }): JSX.Element => {
    const desktopWidth = 22
    const desktopHeight = 20
    const widthHeightRatio = desktopHeight / desktopWidth

    const mobileWidth = 20

    return (
      <Wrapper title={"cart"} onClick={handleActiveCart}>
        <HeaderIconWrapper>
          <HeaderIconInner>
            <StyledIcon name={"cart"} screen="md" width={desktopWidth} height={desktopHeight} />
            <StyledIcon name={"cart"} screen="sm" width={mobileWidth} height={mobileWidth * widthHeightRatio} />
            {count > 0 ? <Count>{count}</Count> : null}
          </HeaderIconInner>
        </HeaderIconWrapper>
      </Wrapper>
    )
  }
)
