import React from "react"
import { Link } from "gatsby"

import { withAnnouncement } from "./withAnnouncement"
import { Outer, Inner, Item, Close, Content } from "./AnnnoucementStyles"
import { Icon } from "../Icon/Icon"

export const Announcement = withAnnouncement(
  ({ itemRefs, active, items, handleHide, height, isAnnouncementHidden, nextItem, prevItem }): JSX.Element => {
    const arrowSize = 15

    if (items?.length) {
      return (
        <Outer style={{ height }}>
          <div className="block md:hidden ml-auto mr-auto" onClick={prevItem}>
            <Icon className="text-white" name={"chevronLeft"} width={arrowSize} height={arrowSize} title={"Previous Announcement"} />
          </div>
          <Inner>
            {items?.map(({ link, title }, index) => (
              <Item key={title} active={active === index ? "true" : undefined}>
                {link?.link ? (
                  <Link
                    ref={ref => (itemRefs.current[index] = ref)}
                    as={link?.external ? `a` : null}
                    href={link?.external ? link?.link : null}
                    target={link?.external ? "_blank" : null}
                    title={title}
                    to={!link?.external ? link?.link || "" : null}
                  >
                    {title}
                  </Link>
                ) : (
                  <Content ref={ref => (itemRefs.current[index] = ref)}>{title}</Content>
                )}
              </Item>
            ))}
            <Close onClick={() => handleHide()}>
              <Icon name={"cross"} width={"10"} height={"10"} title={"close announcement"} />
            </Close>
          </Inner>

          <div className="block md:hidden ml-auto mr-auto" onClick={nextItem}>
            <Icon className="text-white" name={"chevronRight"} width={arrowSize} height={arrowSize} title={"Next Announcement"} />
          </div>
        </Outer>
      )
    }
    return <></>
  }
)
