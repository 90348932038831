import React from "react"

import { withCookies } from "./withCookies"
import { Outer, Inner, Item, Close, Content } from "./CookiesStyles"
import { Icon } from "../Icon/Icon"
import { RichText } from "../RichText/RichText"

export const Cookies = withCookies(
  ({ cookies, handleHide, isCookiesHidden }): JSX.Element =>
    isCookiesHidden ? null : (
      <Outer active={"true"}>
        <Inner>
          <Item active={"true"}>
            <Content>
              <RichText>{cookies}</RichText>
            </Content>
          </Item>
          <Close onClick={() => handleHide()}>
            <Icon name={"cross"} width={"10"} height={"10"} title={"close announcement"} />
          </Close>
        </Inner>
      </Outer>
    )
)
