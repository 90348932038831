import tw, { styled } from "twin.macro"

const styles = {
  width: {
    sm: tw`max-w-32-5 w-full mx-auto`,
    md: tw`md:max-w-76 w-full`,
    lg: tw`md:max-w-85 w-full md:px-2-4`,
    xl: tw`md:max-w-70 lg:max-w-90 xl:max-w-98 w-full md:px-2-4 md:py-4`,
    full: tw`w-full`,
    product: tw`w-9/10-vh md:max-w-70 lg:max-w-90 xl:max-w-98`,
  },
}

export const Outer = styled.div<OuterProps>`
  ${tw`fixed z-50 inset-0 flex justify-center transition-all duration-300 overflow-y-auto overflow-x-hidden flex-1`}
  ${({ mobileAttachTop }) => (mobileAttachTop ? tw`items-start md:items-center` : tw`items-center`)}
  ${({ attachTop }) => (attachTop ? tw`items-start` : null)}
  ${({ active }) => (active ? tw`opacity-100 visible` : tw`opacity-0 invisible`)}
`

export const Overlay = styled.div`
  ${tw`fixed bg-black inset-0 cursor-pointer duration-300 opacity-50 transition-all`}
`

export const Inner = styled.div<InnerProps>`
  ${tw`transition-all duration-300 absolute z-10 md:max-h-9/10-vh overflow-x-hidden overflow-y-auto`}
  ${({ active }) =>
    active ? tw` translate-y-0 opacity-100 visible pointer-events-auto` : tw`translate-y-1/2 opacity-0 invisible pointer-events-none`}
  ${({ width }) => styles.width[width]}
`

export const Close = styled.button<CloseProps>`
  ${tw`absolute cursor-pointer hover:opacity-75 focus:outline-none z-50`}
  ${({ outerClose }) => (outerClose === "true" ? tw`top-3-6 right-3-6 text-white` : tw`top-1-6 right-1-6 md:right-4 `)}
`

export const Content = styled.div<ContentProps>`
  ${tw`flex`}
  ${({ transparent }) => (transparent === "true" ? tw`bg-transparent` : tw`bg-white`)}
`

type InnerProps = {
  active: boolean
  width: "md" | "lg" | "xl"
}

type OuterProps = {
  active: boolean
  mobileAttachTop?: boolean
  attachTop?: boolean
}

type CloseProps = {
  outerClose: "true" | "false"
}

type ContentProps = {
  transparent: "true" | "false"
}
