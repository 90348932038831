import React from "react"

import { withSearchForm } from "./withSearchForm"
import { InputWrapper, Input } from "./SearchFormStyles"

export const SearchForm = withSearchForm(
  ({ layout, additionalSearchPlaceholderText, searchTerm, setSearchTerm }): JSX.Element => (
    <InputWrapper layout={layout}>
      <Input>
        <input aria-label="q" placeholder={additionalSearchPlaceholderText} value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />
      </Input>
    </InputWrapper>
  )
)
